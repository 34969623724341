import axios from "axios";
import * as actionType from "../constants/actionTypes";

/*** SignIn Action ***/
export const SignInStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.SignIn_START,
    };
};
export const SignInSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: actionType.SignIn_SUCCESS,
    };
};
export const SignInFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.SignIn_FAIL,
    };
};
export const SignIn = (form_data) => {
    return (dispatch) => {
        dispatch(SignInStart());

        localStorage.setItem("remember_email", "");
        localStorage.setItem("remember_password", "");
        if (form_data.remember) {
            localStorage.setItem("remember_email", form_data.Email);
            localStorage.setItem("remember_password", form_data.Password);
        }

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            url: `${actionType.environment_url}/api/SignIn`,
            headers: { "Content-Type": "multipart/form-data" },
            data: formdata,
        })
            .then(function (response) {
                localStorage.setItem("verfication_user_email", form_data.Email);
                dispatch(SignInSuccess("User login successfully !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(SignInFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** Resend Token Action ***/
export const ResendTokenStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.ResendToken_START,
    };
};
export const ResendTokenSuccess = (idToken) => {
    return {
        token: false,
        loader: idToken,
        type: actionType.ResendToken_SUCCESS,
    };
};
export const ResendTokenFail = (error) => {
    console.log(error);
    return {
        error: error,
        loader: false,
        type: actionType.ResendToken_FAIL,
    };
};
export const ResendToken = (form_data) => {
    return (dispatch) => {
        dispatch(ResendTokenStart());

        var formdata = new FormData();
        formdata.append("Email", form_data.Email);

        return axios({
            method: "post",
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },
            url: `${actionType.environment_url}/api/ResendToken`,
        })
            .then(function (response) {
                dispatch(ResendTokenSuccess("ResendToken successfully !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ResendTokenFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** SignIn Verify Action ***/
export const SignInVerifyStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.SignInVerify_START,
    };
};
export const SignInVerifySuccess = (idToken) => {
    return {
        token: false,
        loader: idToken,
        type: actionType.SignInVerify_SUCCESS,
    };
};
export const SignInVerifyFail = (error) => {
    console.log(error);
    return {
        error: error,
        loader: false,
        type: actionType.SignInVerify_FAIL,
    };
};
export const SignInVerify = (form_data) => {
    return (dispatch) => {
        dispatch(SignInVerifyStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            url: `${actionType.environment_url}/api/SignInVerify`,
            headers: { "Content-Type": "multipart/form-data" },
            data: formdata,
        })
            .then(function (response) {
                console.log();
                dispatch(SignInVerifySuccess(response.data.token));
                localStorage.setItem("bidhq_authtoken", response.data.token);
                return response.data;
            })
            .catch(function (error) {
                dispatch(SignInVerifyFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** SocialSignIn Action ***/
export const SocialSignInStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.SocialSignIn_START,
    };
};
export const SocialSignInSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: actionType.SocialSignIn_SUCCESS,
    };
};
export const SocialSignInFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.SocialSignIn_FAIL,
    };
};
export const SocialSignIn = (form_data) => {
    return (dispatch) => {
        dispatch(SocialSignInStart());
        return axios({
            method: "post",
            data: form_data,
            headers: { "Content-Type": "application/json" },
            url: `${actionType.environment_url}/api/SocialSignIn`,
        })
            .then(function (response) {
                dispatch(SignInVerifySuccess(response.data.token));
                localStorage.setItem("bidhq_authtoken", response.data.token);
                return response.data;
            })
            .catch(function (error) {
                dispatch(SocialSignInFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** Get Auth Userdata Action ***/
export const GetAuthUserStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.getAuthUser_START,
    };
};
export const GetAuthUserSuccess = (idToken) => {
    return {
        token: false,
        loader: idToken,
        type: actionType.getAuthUser_SUCCESS,
    };
};
export const GetAuthUserFail = (error) => {
    console.log(error);
    return {
        error: error,
        loader: false,
        type: actionType.getAuthUser_FAIL,
    };
};
export const GetAuthUser = () => {
    return (dispatch) => {
        dispatch(GetAuthUserStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetAuthUser`,
        })
            .then(function (response) {
                localStorage.setItem("bidhq_authuser", JSON.stringify(response.data.user_info));
                dispatch(GetAuthUserSuccess("getAuthUser successfully !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAuthUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** SignUp Action ***/
export const SignUpStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.SignUp_START,
    };
};
export const SignUpSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: actionType.SignUp_SUCCESS,
    };
};
export const SignUpFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.SignUp_FAIL,
    };
};
export const SignUp = (form_data) => {
    return (dispatch) => {
        dispatch(SignUpStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/SignUp`,
        })
            .then(function (response) {
                localStorage.setItem("bidhq_authtoken", response.data.token);
                dispatch(SignUpSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(SignUpFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** SignUpCompany Action ***/
export const SignUpCompanyStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.SignUpCompany_START,
    };
};
export const SignUpCompanySuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: actionType.SignUpCompany_SUCCESS,
    };
};
export const SignUpCompanyFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.SignUpCompany_FAIL,
    };
};
export const SignUpCompany = (form_data) => {
    return (dispatch) => {
        dispatch(SignUpCompanyStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/SignUpCompany`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(SignUpCompanySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(SignUpCompanyFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** SocialSignUp Action ***/
export const SocialSignUpStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.SocialSignUp_START,
    };
};
export const SocialSignUpSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: actionType.SocialSignUp_SUCCESS,
    };
};
export const SocialSignUpFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.SocialSignUp_FAIL,
    };
};
export const SocialSignUp = (form_data) => {
    return (dispatch) => {
        dispatch(SocialSignUpStart());
        return axios({
            method: "post",
            data: form_data,
            headers: { "Content-Type": "application/json" },
            url: `${actionType.environment_url}/api/SocialSignUp`,
        })
            .then(function (response) {
                dispatch(SignInVerifySuccess(response.data.token));
                localStorage.setItem("bidhq_authtoken", response.data.token);
                return response.data;
            })
            .catch(function (error) {
                dispatch(SocialSignUpFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** ForgotPassword Action ***/
export const ForgotPasswordStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.ForgotPassword_START,
    };
};
export const ForgotPasswordSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: actionType.ForgotPassword_SUCCESS,
    };
};
export const ForgotPasswordFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.ForgotPassword_FAIL,
    };
};
export const ForgotPassword = (form_data) => {
    return (dispatch) => {
        dispatch(ForgotPasswordStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
		
        return axios({
            method: "post",
            data: formdata,
            headers: { "Content-Type": "application/json" },
            url: `${actionType.environment_url}/api/ForgotPassword`,
        })
            .then(function (response) {
                dispatch(SignInVerifySuccess(response.data.token));
                localStorage.setItem("bidhq_forgotemail", form_data.email);
                return response.data;
            })
            .catch(function (error) {
                dispatch(ForgotPasswordFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** ResetPassword Action ***/
export const ResetPasswordStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.ResetPassword_START,
    };
};
export const ResetPasswordSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: actionType.ResetPassword_SUCCESS,
    };
};
export const ResetPasswordFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.ResetPassword_FAIL,
    };
};
export const ResetPassword = (form_data) => {
    return (dispatch) => {
        dispatch(ResetPasswordStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: { "Content-Type": "application/json" },
            url: `${actionType.environment_url}/api/ResetPassword`,
        })
            .then(function (response) {
                dispatch(SignInVerifySuccess(response.data));
                localStorage.setItem("bidhq_authtoken", response.data.token);
                return response.data;
            })
            .catch(function (error) {
                dispatch(ResetPasswordFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** Resend Token Action ***/
export const ResentForgotOTPStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.ResentForgotOTP_START,
    };
};
export const ResentForgotOTPSuccess = (idToken) => {
    return {
        token: false,
        loader: idToken,
        type: actionType.ResentForgotOTP_SUCCESS,
    };
};
export const ResentForgotOTPFail = (error) => {
    console.log(error);
    return {
        error: error,
        loader: false,
        type: actionType.ResentForgotOTP_FAIL,
    };
};
export const ResentForgotOTP = (form_data) => {
    return (dispatch) => {
        dispatch(ResentForgotOTPStart());

        var formdata = new FormData();
        formdata.append("email", form_data.email);

        return axios({
            method: "post",
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },
            url: `${actionType.environment_url}/api/ResentForgotOTP`,
        })
            .then(function (response) {
                dispatch(ResentForgotOTPSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ResentForgotOTPFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const isAuthorize = () => {
    const bidhq_authuser = localStorage.getItem("bidhq_authuser");
    const bidhq_authtoken = localStorage.getItem("bidhq_authtoken");
    return bidhq_authtoken && bidhq_authuser ? true : false;
};

/*** Logout Action ***/
export const logout = () => {
    return axios({
        method: "post",
        url: `${actionType.environment_url}/api/Logout`,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
        },
    })
        .then(function (response) {
            if (response.data.Success === true) {
                localStorage.removeItem("bidhq_authuser");
                localStorage.removeItem("bidhq_authtoken");
            }
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
};