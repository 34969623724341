import React from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
// import { notify } from "react-notify-toast";

import { render } from "@testing-library/react";

import Loader from "../../components/Utility/Loader";
import * as userActions from "../../actions/index";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

import RenameContent from "./RenameContent";
import AddNewContent from "./AddNewContent";

import LibraryRightSideFilePreview from "./View/RightSideBar/LibraryRightSideFilePreview";

import ConfirmDialog from "../Utility/ConfirmDialog";
class CaseStudy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            contentrename_modal: false,
            addcontent_modal: false,
            library_rightsidebar_filepreview: false,
            bid_rightsidebar: false,
            confirm_modal: false,
        };
        this.ConfirmDialogEvent = React.createRef();
    }

    AddRenameContent = (event) => {
        // event.preventDefault();
        this.setState({ contentrename_modal: true });
    };
    LibraryRemoveFile() {
        //  this.setState({ confirm_modal: true });
        render(
            <ConfirmDialog
                title={"Delete content"}
                body="Are you sure you want to delete this document? It'll be gone permanently"
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmLibraryRemoveFile(event)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    }
    AddNewContent = (event) => {
        event.preventDefault();
        this.setState({ addcontent_modal: true });
    };
    BidCommentReply(event) {
        event.preventDefault();
        this.setState({
            library_rightsidebar_filepreview: true,
            bid_rightsidebar: true,
        });
    }

    RenameContentData = () => {
        this.AddRenameContent();
        setTimeout(() => {
            if (this.state.contentrename_modal === true) {
                this.setState({ library_rightsidebar_filepreview: false });
            }
        }, 500);
    };
    DeleteContentdata = (data) => {
        this.setState({ confirm_modal: true });
        setTimeout(() => {
            this.LibraryRemoveFile();
            if (data === true) {
                this.setState({ library_rightsidebar_filepreview: false });
            }
        }, 500);
    };

    render() {
        console.log("confirm_modal==", this.state.confirm_modal);
        const { bid_rightsidebar, library_rightsidebar_filepreview } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                {this.state.contentrename_modal && (
                    <RenameContent
                        contentrename_modal={this.state.contentrename_modal}
                        ref={(ref) => (this.AddUsersSuccessEvent = ref)}
                        CreateClientSubmit={(event) => this.CreateClientSubmit(event)}
                        contentrename_modal_action={(modal_action) => {
                            this.setState({
                                contentrename_modal: modal_action,
                            });
                        }}
                    />
                )}
                {this.state.addcontent_modal && (
                    <AddNewContent
                        addcontent_modal={this.state.addcontent_modal}
                        ref={(ref) => (this.AddUsersSuccessEvent = ref)}
                        CreateClientSubmit={(event) => this.CreateClientSubmit(event)}
                        addcontent_modal_action={(modal_action) => {
                            this.setState({
                                addcontent_modal: modal_action,
                            });
                        }}
                    />
                )}
                <div className={"d-flex flex-column flex-root" + (bid_rightsidebar ? " right-aside-wrap" : "") + (library_rightsidebar_filepreview ? " bid_rightsidebar_comment" : "")}>
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Library" />

                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/library" className="nav-link text-active-primary me-6">
                                                        Content
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/case-study" className="nav-link text-active-primary me-6 active">
                                                        Case studies
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <form className="form w-100">
                                                <div className="card mb-5 mb-xl-8">
                                                    <div className="card-header border-0 pt-5 mb-10">
                                                        <div className="col-md-6">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <h3 className="card-title align-items-start flex-column ms-4">
                                                                    <span className="card-label fw-bolder fs-2x mb-1">Folder name</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="d-flex align-items-center my-2 justify-content-end">
                                                                <span className="svg-icon svg-icon-3 me-2">
                                                                    <img src="assets/images/icons/search.svg" title="all_content" alt="all_content" />
                                                                </span>
                                                                <span className="me-2" onClick={(event) => this.BidCommentReply(event)}>
                                                                    <i className="fa fa-exclamation-circle"></i>
                                                                </span>
                                                                <button type="button" className="rounded-50 btn btn-primary btn-sm" onClick={(event) => this.AddNewContent(event)}>
                                                                    Create new content
                                                                    <span className="svg-icon svg-icon-2">
                                                                        <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-0">
                                                        <div className="row mb-4">
                                                            <div className="col fv-row">
                                                                <div className="row main_returnable_documents_div">
                                                                    <div className="col-sm-12 col-lg-12 align_font">
                                                                        <i className="fa fa-file-pdf" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="add_dropdowan_returnable_documents">
                                                                        <div className="dropdown" key="1">
                                                                            <div className="" aria-expanded="false" id="1" data-bs-toggle="dropdown">
                                                                                <img src="assets/images/icons/more.svg" title="more" alt="more" />
                                                                            </div>
                                                                            <div className="dropdown-menu" aria-labelledby="1">
                                                                                <span className="dropdown-item" onClick={(event) => this.AddRenameContent(event)}>
                                                                                    <i className="fa fa-pen" aria-hidden="true"></i> Rename
                                                                                </span>

                                                                                <Link className="dropdown-item" to="#">
                                                                                    <i className="fa fa-copy" aria-hidden="true"></i> Make as copy
                                                                                </Link>
                                                                                <span
                                                                                    className="dropdown-item"
                                                                                    onClick={() =>
                                                                                        this.setState({ confirm_modal: true }, function () {
                                                                                            this.LibraryRemoveFile();
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-trash" aria-hidden="true"></i> Delete
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-lg-12 mt-2">
                                                                        <div className="folder_name">Lorem ipsum dolor sit amet, consectetur.</div>
                                                                        <div className="folder_foles">Last modified DD/MM/YY</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col fv-row">
                                                                <div className="row main_returnable_documents_div">
                                                                    <div className="col-sm-12 col-lg-12 align_font">
                                                                        <i className="fa fa-file-pdf" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="add_dropdowan_returnable_documents">
                                                                        <div className="dropdown" key="1">
                                                                            <div className="" aria-expanded="false" id="1" data-bs-toggle="dropdown">
                                                                                <img src="assets/images/icons/more.svg" title="more" alt="more" />
                                                                            </div>
                                                                            <div className="dropdown-menu" aria-labelledby="1">
                                                                                <span className="dropdown-item">
                                                                                    <i className="fa fa-pen" aria-hidden="true"></i> Rename
                                                                                </span>

                                                                                <Link className="dropdown-item" to="#">
                                                                                    <i className="fa fa-copy" aria-hidden="true"></i> Make as copy
                                                                                </Link>
                                                                                <span className="dropdown-item">
                                                                                    <i className="fa fa-trash" aria-hidden="true"></i> Delete
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-lg-12 mt-2">
                                                                        <div className="folder_name">Lorem ipsum dolor sit amet, consectetur.</div>
                                                                        <div className="folder_foles">Last modified DD/MM/YY</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col fv-row">
                                                                <div className="row main_returnable_documents_div">
                                                                    <div className="col-sm-12 col-lg-12 align_font">
                                                                        <i className="fa fa-file-pdf" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="add_dropdowan_returnable_documents">
                                                                        <div className="dropdown" key="1">
                                                                            <div className="" aria-expanded="false" id="1" data-bs-toggle="dropdown">
                                                                                <img src="assets/images/icons/more.svg" title="more" alt="more" />
                                                                            </div>
                                                                            <div className="dropdown-menu" aria-labelledby="1">
                                                                                <span className="dropdown-item">
                                                                                    <i className="fa fa-pen" aria-hidden="true"></i> Rename
                                                                                </span>

                                                                                <Link className="dropdown-item" to="#">
                                                                                    <i className="fa fa-copy" aria-hidden="true"></i> Make as copy
                                                                                </Link>
                                                                                <span className="dropdown-item">
                                                                                    <i className="fa fa-trash" aria-hidden="true"></i> Delete
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-lg-12 mt-2">
                                                                        <div className="folder_name">Lorem ipsum dolor sit amet, consectetur.</div>
                                                                        <div className="folder_foles">Last modified DD/MM/YY</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col fv-row">
                                                                <div className="row main_returnable_documents_div">
                                                                    <div className="col-sm-12 col-lg-12 align_font">
                                                                        <i className="fa fa-file-pdf" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="add_dropdowan_returnable_documents">
                                                                        <div className="dropdown" key="1">
                                                                            <div className="" aria-expanded="false" id="1" data-bs-toggle="dropdown">
                                                                                <img src="assets/images/icons/more.svg" title="more" alt="more" />
                                                                            </div>
                                                                            <div className="dropdown-menu" aria-labelledby="1">
                                                                                <span className="dropdown-item">
                                                                                    <i className="fa fa-pen" aria-hidden="true"></i> Rename
                                                                                </span>

                                                                                <Link className="dropdown-item" to="#">
                                                                                    <i className="fa fa-copy" aria-hidden="true"></i> Make as copy
                                                                                </Link>
                                                                                <span className="dropdown-item">
                                                                                    <i className="fa fa-trash" aria-hidden="true"></i> Delete
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-lg-12 mt-2">
                                                                        <div className="folder_name">Lorem ipsum dolor sit amet, consectetur.</div>
                                                                        <div className="folder_foles">Last modified DD/MM/YY</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col fv-row">
                                                                <div className="row main_returnable_documents_div">
                                                                    <div className="col-sm-12 col-lg-12 align_font">
                                                                        <i className="fa fa-file-pdf" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="add_dropdowan_returnable_documents">
                                                                        <div className="dropdown" key="1">
                                                                            <div className="" aria-expanded="false" id="1" data-bs-toggle="dropdown">
                                                                                <img src="assets/images/icons/more.svg" title="more" alt="more" />
                                                                            </div>
                                                                            <div className="dropdown-menu" aria-labelledby="1">
                                                                                <span className="dropdown-item">
                                                                                    <i className="fa fa-pen" aria-hidden="true"></i> Rename
                                                                                </span>

                                                                                <Link className="dropdown-item" to="#">
                                                                                    <i className="fa fa-copy" aria-hidden="true"></i> Make as copy
                                                                                </Link>
                                                                                <span className="dropdown-item">
                                                                                    <i className="fa fa-trash" aria-hidden="true"></i> Delete
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-lg-12 mt-2">
                                                                        <div className="folder_name">Lorem ipsum dolor sit amet, consectetur.</div>
                                                                        <div className="folder_foles">Last modified DD/MM/YY</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="comment-overlay">
                                <div className="right_sidebar_comment">
                                    {this.state.library_rightsidebar_filepreview && (
                                        <LibraryRightSideFilePreview
                                            // library_rightsidebar_filepreview={this.state.library_rightsidebar_filepreview}
                                            AddRenameContent={this.RenameContentData}
                                            LibraryRemoveFile={this.DeleteContentdata}
                                            ref={(ref) => (this.AddUsersSuccessEvent = ref)}
                                            CreateClientSubmit={(event) => this.CreateClientSubmit(event)}
                                            library_rightsidebar_filepreview={(modal_action) => {
                                                this.setState({
                                                    library_rightsidebar_filepreview: modal_action,
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    OnCreateClient: userActions.CreateClient,
};
export default connect(mapStateToProps, mapDispatchToProps)(CaseStudy);
