import React from "react";
import Notifications from "react-notify-toast";

import TaskList from "../../components/Tasks/TaskList.js";
class Tasks extends React.Component {
    render() {
        const renderview = () => {
            if (this.props.match.path === "/tasks") {
                return <TaskList />;
            }
        };
        return (
            <React.Fragment>
                <Notifications />
                {renderview()}
            </React.Fragment>
        );
    }
}
export default Tasks;