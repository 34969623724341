import axios from "axios";
import * as actionType from "../constants/actionTypes";

const qs = require("qs");
export const GetCountries = () => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetCountries`,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetRoles = () => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetRoles`,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetInvitationById = (invite_id) => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetInvitationById`,
            params: { invite_id: invite_id },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const SubmitInvitation = (form_data) => {
    return new Promise((resolve) => {
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/SubmitInvitation`,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const TimeAgo = (date) => {
    let minute = 60;
    let hour = minute * 60;
    let day = hour * 24;
    let month = day * 30;
    let year = day * 365;
    let suffix = " ago";
    let elapsed = Math.floor((Date.now() - date) / 1000);

    if (elapsed < minute) {
        return "just now";
    }

    let time_ago = [];
    if (elapsed < hour) {
        time_ago = [Math.floor(elapsed / minute), "minute"];
    } else if (elapsed < day) {
        time_ago = [Math.floor(elapsed / hour), "hour"];
    } else if (elapsed < month) {
        time_ago = [Math.floor(elapsed / day), "day"];
    } else if (elapsed < year) {
        time_ago = [Math.floor(elapsed / month), "month"];
    } else {
        time_ago = [Math.floor(elapsed / year), "year"];
    }

    return time_ago[0] + " " + time_ago[1] + (time_ago[0] === 1 ? "" : "s") + suffix;
};

export const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const CheckPermissions = () => {
    const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));

    if (user_info && user_info.user && user_info.user.role && user_info.user.role.user_role) {
        localStorage.setItem("user_role", user_info.user.role.user_role);

        localStorage.setItem("bidlist_createbitbutton", "yes");
        localStorage.setItem("bidlist_tablefilter", "yes");

        localStorage.setItem("bidresponse_designpreviewTab", "yes");
        localStorage.setItem("bidresponse_reviewandlockTab", "yes");
        localStorage.setItem("bidresponse_exportandshareTab", "yes");
        localStorage.setItem("bidresponse_bidcreate_continuebutton", "yes");
        localStorage.setItem("bidresponse_insert_section", "yes");

        localStorage.setItem("bidresponse_changebidstatus", "yes");
        localStorage.setItem("bidresponse_bidcreate_sectionDraggable", "yes");
        localStorage.setItem("bidresponse_bidcreatesidebar", "yes");
        localStorage.setItem("bidresponse_pastproposals", "yes");
        localStorage.setItem("bidresponse_addteammodal", "yes");
        localStorage.setItem("bidresponse_addownermodal", "yes");

        if (user_info.user.role.user_role === "Contributor") {
            localStorage.setItem("bidlist_tablefilter", "no");
            localStorage.setItem("bidlist_createbitbutton", "no");

            localStorage.setItem("bidresponse_designpreviewTab", "no");
            localStorage.setItem("bidresponse_reviewandlockTab", "no");
            localStorage.setItem("bidresponse_exportandshareTab", "no");
            localStorage.setItem("bidresponse_bidcreate_continuebutton", "no");
            localStorage.setItem("bidresponse_insert_section", "no");

            localStorage.setItem("bidresponse_changebidstatus", "no");

            localStorage.setItem("bidresponse_bidcreate_sectionDraggable", "no");
            localStorage.setItem("bidresponse_pastproposals", "no");
            localStorage.setItem("bidresponse_addteammodal", "no");
            localStorage.setItem("bidresponse_addownermodal", "no");
        }

        if (user_info.user.role.user_role === "Guest") {
            localStorage.setItem("bidlist_tablefilter", "no");
            localStorage.setItem("bidlist_createbitbutton", "no");

            localStorage.setItem("bidresponse_designpreviewTab", "no");
            localStorage.setItem("bidresponse_reviewandlockTab", "no");
            localStorage.setItem("bidresponse_exportandshareTab", "no");
            localStorage.setItem("bidresponse_bidcreate_continuebutton", "no");
            localStorage.setItem("bidresponse_insert_section", "no");

            localStorage.setItem("bidresponse_changebidstatus", "no");

            localStorage.setItem("bidresponse_bidcreate_sectionDraggable", "no");
            localStorage.setItem("bidresponse_pastproposals", "no");
            localStorage.setItem("bidresponse_addteammodal", "no");
            localStorage.setItem("bidresponse_addownermodal", "no");
        }
    }

    return {
        type: "CheckPermissions",
    };
};