import React from "react";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";

import * as userActions from "../../../../actions/index";
class BidRightSideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bid_detail: [],
            library_conents: [],
            library_proposals: [],

            bid_response_form: "",
            bid_section_selected: "",

            bidcreate_rightsidetab_activetab: "global",
            bidcreate_global_activetab: "global_team",
            bidcreate_section_activetab: "section_team",
        };
    }

    componentDidMount() {
        this.setState({
            library_conents: [],
            bid_detail: this.props.bid_detail,
            bidcreate_rightsidetab_activetab: "global",
            bid_response_form: this.props.bid_response_form,
            bid_section_selected: this.props.bid_section_selected,
        });

        if (this.props.bid_section_selected) {
            this.setState({
                bidcreate_rightsidetab_activetab: "section",
            });
        }
        this.GetLibraryWithContents();
        this.LibraryProposalFilter();
    }

    BidRightSideBarReload() {
        this.setState({
            bid_detail: this.props.bid_detail,
            bidcreate_rightsidetab_activetab: "global",
            bid_response_form: this.props.bid_response_form,
            bid_section_selected: this.props.bid_section_selected,
        });

        if (this.props.bid_section_selected) {
            this.setState({
                bidcreate_rightsidetab_activetab: "section",
            });
        }
    }

    GetLibraryWithContents(search) {
        this.props.onGetLibraryWithContents(search).then((response) => {
            if (response.success === true) {
                this.setState({
                    library_conents: response.data,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    LibraryProposalFilter(search) {
        this.props.onGetBidProposal(search).then((response) => {
            if (response.success === true) {
                this.setState({
                    library_proposals: response.data,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    AddNewBidproposal(event, proposal) {
        event.preventDefault();
        this.props.AddNewBidproposal(event, proposal);
    }

    render() {
        const { bid_detail, bid_response_form, bidcreate_rightsidetab_activetab, bidcreate_global_activetab, bidcreate_section_activetab, bid_section_selected, library_conents, library_proposals } = this.state;
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        return (
            <React.Fragment>
                <div className="wrap-right-info">
                    <div className="card-toolbar m-0">
                        <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bolder" role="tablist">
                            <li className="nav-item" role="presentation">
                                <span
                                    className={"nav-link justify-content-center text-gray-400 curser_pointer " + (bidcreate_rightsidetab_activetab === "global" ? "active" : "")}
                                    onClick={(event) => {
                                        this.setState({ bidcreate_rightsidetab_activetab: "global" });
                                        this.props.BidSectionUnselected(event);
                                    }}
                                >
                                    Global
                                </span>
                            </li>
                            <li className="nav-item" role="presentation">
                                <span
                                    className={"nav-link justify-content-center text-gray-400 curser_pointer section-active " + (bidcreate_rightsidetab_activetab === "section" ? "active" : "")}
                                    onClick={(event) => {
                                        if (this.state.bid_section_selected === undefined) {
                                            notify.show("Please select section first", "error");
                                        } else {
                                            this.setState({ bidcreate_rightsidetab_activetab: "section" });
                                        }
                                    }}
                                >
                                    Section
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content">
                        {bidcreate_rightsidetab_activetab === "global" && (
                            <div className={"card-body p-0 tab-pane fade" + (bidcreate_rightsidetab_activetab === "global" ? " show active" : "")}>
                                <ul className="right-sidebar-icons nav nav-tabs border-bottom-0 inner-nav-tabs nav-line-tabs nav-stretch" role="tablist">
                                    <li className="nav-item curser_pointer">
                                        <span
                                            id="kt_activity_team_tab"
                                            className={"nav-link justify-content-center text-gray-400" + (bidcreate_global_activetab === "global_team" ? " active" : "")}
                                            onClick={(event) => {
                                                this.setState({ bidcreate_global_activetab: "global_team" });
                                            }}
                                        >
                                            <i className="icon-team"></i>Team
                                        </span>
                                    </li>
                                    <li className="nav-item curser_pointer">
                                        <span
                                            id="kt_activity_bidinfo_tab"
                                            className={"nav-link justify-content-center text-active-gray-800" + (bidcreate_global_activetab === "bid_info" ? " active" : "")}
                                            onClick={(event) => {
                                                this.setState({ bidcreate_global_activetab: "bid_info" });
                                            }}
                                        >
                                            <i className="icon-info1"></i>Bid Info
                                        </span>
                                    </li>
                                    <li className="nav-item curser_pointer">
                                        <span
                                            id="kt_activity_tips_tab"
                                            className={"nav-link justify-content-center text-active-gray-800" + (bidcreate_global_activetab === "tips" ? " active" : "")}
                                            onClick={(event) => {
                                                this.setState({ bidcreate_global_activetab: "tips" });
                                            }}
                                        >
                                            <i className="icon-tips"></i> Tips
                                        </span>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    {bidcreate_global_activetab === "global_team" && (
                                        <div id="kt_activity_team" className="card-body p-0 tab-pane fade active show">
                                            <div className="scroll-y right-scroll-height">
                                                <div className="py-5 px-3 border-bottom">
                                                    <h6 className="text-pink fs-8 mb-3">Bid Status</h6>
                                                    {bid_detail.bid_status && (
                                                        <div className="dropdown">
                                                            <div
                                                                className={"w-102px border progress-text fs-8 p-1 ps-2 rounded-50 d-inline-block curser_pointer title_vector_" + bid_detail.bid_status.replace(" ", "_").toLowerCase()}
                                                                id="bid_response_sidebar_bid_status"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <span className="svg-icon-5">
                                                                    <i className={"icon_vector_" + bid_detail.bid_status.replace(" ", "_").toLowerCase()}></i>
                                                                </span>
                                                                {bid_detail.bid_status}
                                                            </div>
                                                            {localStorage.getItem("bidresponse_changebidstatus") === "yes" && (
                                                                <div className="dropdown-menu status-response" aria-labelledby="bid_response_sidebar_bid_status">
                                                                    <span className="fs-8 dropdown-item bit_status_in_progress" onClick={(event) => this.props.BidStatusUpdate(event, "In progress")}>
                                                                        In progress
                                                                    </span>
                                                                    <span className="fs-8 dropdown-item bit_status_submitted" onClick={(event) => this.props.BidStatusUpdate(event, "Submitted")}>
                                                                        Submitted
                                                                    </span>
                                                                    <span className="fs-8 dropdown-item bit_status_won" onClick={(event) => this.props.BidStatusUpdate(event, "Won")}>
                                                                        Won
                                                                    </span>
                                                                    <span className="fs-8 dropdown-item bit_status_lost" onClick={(event) => this.props.BidStatusUpdate(event, "Lost")}>
                                                                        Lost
                                                                    </span>
                                                                    <span className="fs-8 dropdown-item bit_status_withdrawn" onClick={(event) => this.props.BidStatusUpdate(event, "Withdrawn")}>
                                                                        Withdrawn
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="py-5 px-3 border-bottom">
                                                    <h6 className="text-pink fs-8 mb-2">Section Assignees</h6>
                                                    <span className="fs-9 pb-2 d-block text-gray-400 ">Team ({bid_detail.bid_teams ? bid_detail.bid_teams.length : 0}):</span>
                                                    <div className="symbol-group symbol-hover mb-4">
                                                        {bid_detail.bid_teams &&
                                                            bid_detail.bid_teams.map((bid_team) => {
                                                                return (
                                                                    <div className="custom-tooltip" key={"bid_team_" + bid_team.user_id}>
                                                                        <div className="symbol symbol-25px symbol-circle">
                                                                            {bid_team && bid_team.avatar ? (
                                                                                <span className="symbol_img">
                                                                                    <img src={bid_team.avatar} title={bid_team.first_name} alt={bid_team.first_name} />
                                                                                    <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span className="symbol-label bg-primary text-inverse-primary">
                                                                                    {bid_team.first_name.slice(0, 1) + bid_team.last_name.slice(0, 1)}
                                                                                    <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        <div className="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Susan Redwood">
                                                            <span
                                                                className="symbol-label bg-secondary-dark text-inverse-primary fw-bolder"
                                                                onClick={() => {
                                                                    if (localStorage.getItem("bidresponse_addteammodal") === "yes") {
                                                                        this.props.bid_team_modal_action(true);
                                                                    } else {
                                                                        notify.show("You does not have this permission", "error");
                                                                    }
                                                                }}
                                                            >
                                                                <img src="assets/images/icons/plus.svg" title="" alt="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <span className="fs-9 pb-2 d-block text-gray-400 ">Managers ({bid_detail.bid_owners ? bid_detail.bid_owners.length : 0}):</span>
                                                    <div className="symbol-group symbol-hover mb-4">
                                                        {bid_detail.bid_owners &&
                                                            bid_detail.bid_owners.map((bid_owner) => {
                                                                return (
                                                                    <div className="custom-tooltip" key={"bid_owner_" + bid_owner.user_id}>
                                                                        <div className="symbol symbol-25px symbol-circle">
                                                                            {bid_owner && bid_owner.avatar ? (
                                                                                <span className="symbol_img">
                                                                                    <img src={bid_owner.avatar} title={bid_owner.first_name} alt={bid_owner.first_name} />
                                                                                    <span className="custom-tooltip-text">{bid_owner.first_name + " " + bid_owner.last_name}</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span className="symbol-label bg-primary text-inverse-primary">
                                                                                    {bid_owner.first_name.slice(0, 1) + bid_owner.last_name.slice(0, 1)}
                                                                                    <span className="custom-tooltip-text">{bid_owner.first_name + " " + bid_owner.last_name}</span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        <div className="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Susan Redwood">
                                                            <span
                                                                className="symbol-label bg-secondary-dark text-inverse-primary fw-bolder"
                                                                onClick={() => {
                                                                    if (localStorage.getItem("bidresponse_addownermodal") === "yes") {
                                                                        this.props.bid_owner_modal_action(true);
                                                                    } else {
                                                                        notify.show("You does not have this permission", "error");
                                                                    }
                                                                }}
                                                            >
                                                                <img src="assets/images/icons/plus.svg" title="" alt="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-5 px-3">
                                                    <div className="d-flex mb-3 justify-content-between align-items-center">
                                                        <h6 className="text-pink fs-8 m-0">Comments</h6>
                                                        <span
                                                            className="curser_pointer"
                                                            onClick={() => {
                                                                this.props.comment_sidebar_action(true);
                                                            }}
                                                        >
                                                            <i className="icon-message1 text-pink"></i>
                                                        </span>
                                                    </div>
                                                    {bid_detail.bid_response_comments &&
                                                        bid_detail.bid_response_comments.map((bid_comment, comment_key) => {
                                                            return (
                                                                <div className="d-flex mb-5" key={"comment_" + bid_comment.comment.comment_id}>
                                                                    <div className="symbol symbol-30px me-3">
                                                                        <img title="user" alt="user" src={bid_comment.comment.user.avatar ? bid_comment.comment.user.avatar : "assets/images/profile_dummy.jpg"} />
                                                                    </div>
                                                                    <div className="d-flex flex-column flex-row-fluid">
                                                                        <div className="d-flex align-items-center flex-wrap mb-1">
                                                                            <span className="text-grey-dark text-hover-primary fw-bold-700 me-2 fs-9">
                                                                                {bid_comment.comment.user.first_name} {bid_comment.comment.user.last_name}
                                                                            </span>
                                                                            <span className="text-grey-dark time-date-wrap fs-9">8 hrs ago</span>
                                                                        </div>
                                                                        <span className="text-grey-dark fs-9 fw-normal pb-2">{renderHTML(bid_comment.comment.comment_text)}</span>
                                                                        <span
                                                                            className="text-pink fw-bold fs-9 curser_pointer"
                                                                            onClick={(event) => {
                                                                                this.props.BidCommentReply(event, bid_comment);
                                                                            }}
                                                                        >
                                                                            <i className="text-pink icon-Reply"></i> {bid_comment.comment.reply_count} replies
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}

                                                    <div className="pt-0">
                                                        <h6 className="text-pink fs-8 mb-3">Activity</h6>
                                                        <div>
                                                            <div className="mb-2">
                                                                <ul className="activity-list fs-9 p-0 m-0 text-gray-700">
                                                                    <li>
                                                                        Activity name lorem ipsum
                                                                        <span className="fs-9 d-block text-gray-400 ">DD Month YYYY</span>{" "}
                                                                    </li>
                                                                </ul>
                                                                <div className="fs-9 text-gray-700">
                                                                    Show all <span className="icon-right-arrow fs-10 ps-1"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {bidcreate_global_activetab === "bid_info" && (
                                        <div id="kt_activity_bidinfo" className="card-body p-0 tab-pane fade active show">
                                            <div className="scroll-y right-scroll-height">
                                                <div className="py-5 px-3 border-new">
                                                    <h6 className="text-pink fs-8 mb-3">Bid Sections Summary</h6>
                                                    <ol className="bidinfo-list fs-9 p-0 m-0 text-gray-400 fw-bold-600">
                                                        {bid_response_form &&
                                                            bid_response_form.response_content.map((bid_content, bid_content_key) => {
                                                                return <li key={"bid_content_key_" + bid_content_key}>{bid_content.title}</li>;
                                                            })}
                                                    </ol>
                                                    {localStorage.getItem("bidresponse_insert_section") === "yes" && (
                                                        <div
                                                            className="text-gray-400 fs-9 text-hover-danger curser_pointer fw-bold-600"
                                                            onClick={() => {
                                                                this.props.bid_insert_section_action(true);
                                                            }}
                                                        >
                                                            <span className="d-flex align-items-center">
                                                                <i className="fa fa-plus fs-9 me-2 text-gray-400" aria-hidden="true"></i> Insert a new section
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="py-5 px-3 border-new">
                                                    <h6 className="text-pink fs-8 mb-3">Type of Bid</h6>
                                                    <span className="fs-9 pb-2 d-block text-gray-400 ">{bid_detail.bidtype_meta ? bid_detail.bidtype_meta.title : "-"}</span>
                                                </div>
                                                <div className="py-5 px-3">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h6 className="text-pink fs-8 mb-3">Bid Info</h6>
                                                    </div>
                                                    <span className="fs-9 pb-2 d-block text-gray-400 ">Lorem ipsum</span>
                                                    <div className="mb-2">
                                                        <span className="fs-9 d-block text-gray-400 fw-bold-600 ">Bid name:</span>
                                                        <span className="fs-9 d-block text-gray-400 ">{bid_detail.bid_title}</span>
                                                    </div>
                                                    <div className="mb-2">
                                                        <span className="fs-9 d-block text-gray-400 fw-bold-600 ">Client name:</span>
                                                        <span className="fs-9 d-block text-gray-400 ">{bid_detail.client !== undefined ? bid_detail.client.client_name : "-"}</span>
                                                    </div>
                                                    <div className="mb-0">
                                                        <span className="fs-9 d-block text-gray-400 fw-bold-600 ">Bid reference ID:</span>
                                                        <span className="fs-9 d-block text-gray-400 ">{bid_detail.reference_number}</span>
                                                    </div>
                                                </div>
                                                <div className="py-5 px-3 pt-0">
                                                    <h6 className="text-pink fs-8 mb-3">Date</h6>
                                                    <div className="mb-2">
                                                        <span className="fs-9 d-block text-gray-400 fw-bold-600">Submission due date:</span>
                                                        <span className="fs-9 d-block text-gray-400 ">{bid_detail.submission_date}</span>
                                                    </div>
                                                    <div className="mb-0">
                                                        <span className="fs-9 d-block text-gray-400 fw-bold-600">Submission timezone:</span>
                                                        <span className="fs-9 d-block text-gray-400 ">{bid_detail.submission_timezone}</span>
                                                    </div>
                                                </div>
                                                <div className="py-5 px-3 pt-0">
                                                    <h6 className="text-pink fs-8 mb-3">Tags</h6>
                                                    <div className="button-group tags-btn d-flex flex-wrap justify-content-between">
                                                        {bid_detail.bidtag_ids &&
                                                            bid_detail.bidtag_ids.map((bidtag) => (
                                                                <span className="badge badge-primary rounded-50" key={"tag_key_" + bidtag.bidtag_id}>
                                                                    {bidtag ? bidtag.tag_name : "-"}
                                                                </span>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {bidcreate_global_activetab === "tips" && (
                                        <div id="kt_activity_tips" className="card-body p-0 tab-pane fade active show">
                                            <div className="scroll-y right-scroll-height">
                                                <div className="py-5 px-3">
                                                    <form data-kt-search-element="form" className="w-100 position-relative mb-5">
                                                        <input type="text" className="form-control form-control-flush" onChange={(event) => this.inputChangeHandler(event, "bid_title")} />
                                                    </form>
                                                    <div className="tips-wrap">
                                                        <div className="info-wrap">
                                                            <h6 className="text-dark fw-bolder fs-8">How to collaborate on a bid remotely..</h6>
                                                            <span className="text-gray-400 fs-9 fw-normal pb-1 d-block">Subheading ipsum dolor sit amet, consectetur...</span>
                                                            <span className="fs-9">Read article</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {bidcreate_rightsidetab_activetab === "section" && (
                            <div className={"card-body p-0 tab-pane fade section-content" + (bidcreate_rightsidetab_activetab === "section" ? " show active" : "")}>
                                <ul className="right-sidebar-icons nav nav-tabs border-bottom-0 inner-nav-tabs nav-line-tabs nav-stretch" role="tablist">
                                    <li className="nav-item curser_pointer">
                                        <span
                                            id="kt_section_team_tab"
                                            className={"nav-link justify-content-center text-gray-400" + (bidcreate_section_activetab === "section_team" ? " active" : "")}
                                            onClick={(event) => {
                                                this.setState({ bidcreate_section_activetab: "section_team" });
                                            }}
                                        >
                                            <i className="icon-team"></i> Team
                                        </span>
                                    </li>
                                    <li className="nav-item curser_pointer">
                                        <span
                                            id="kt_section_library_tab"
                                            className={"nav-link justify-content-center text-active-gray-800" + (bidcreate_section_activetab === "section_library" ? " active" : "")}
                                            onClick={(event) => {
                                                this.setState({ bidcreate_section_activetab: "section_library" });
                                            }}
                                        >
                                            <i className="icon-book"></i> Library
                                        </span>
                                    </li>
                                    <li className="nav-item curser_pointer">
                                        <span
                                            id="kt_section_training_tab"
                                            className={"nav-link justify-content-center text-active-gray-800 training-btn" + (bidcreate_section_activetab === "section_training" ? " active" : "")}
                                            onClick={(event) => {
                                                this.setState({ bidcreate_section_activetab: "section_training" });
                                            }}
                                        >
                                            <i className="icon-training"></i> Training
                                        </span>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    {bidcreate_section_activetab === "section_team" && (
                                        <div id="kt_section_team" className="card-body p-0 tab-pane fade active show">
                                            <div className="scroll-y right-scroll-height right-section-outer">
                                                <div className="py-5 px-3 section-title fw-bold-700 text-white fs-8">{bid_section_selected.title}</div>
                                                <div className="py-5 px-3 border-bottom">
                                                    <h6 className="text-blue fs-8 mb-3">Section Status</h6>
                                                    {bid_section_selected && bid_section_selected.section_status && (
                                                        <div className="dropdown">
                                                            <div
                                                                className={"w-102px fs-8 border progress-text p-2 rounded-50 d-inline-block curser_pointer title_vector_" + bid_section_selected.section_status.replace(" ", "_").toLowerCase()}
                                                                id="bid_response_sidebar_section_status"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <span className="svg-icon-5 me-1">
                                                                    <i className={"icon_vector_" + bid_section_selected.section_status.replace(" ", "_").toLowerCase()}></i>
                                                                </span>
                                                                {bid_section_selected.section_status}
                                                            </div>
                                                            <div className="dropdown-menu p-0" aria-labelledby="bid_response_sidebar_section_status">
                                                                <span className="fs-8 dropdown-item section_status_unassigned" onClick={(event) => this.props.BidSelectedStatusUpdate(event, bid_section_selected, "Unassigned")}>
                                                                    Unassigned
                                                                </span>
                                                                <span className="fs-8 dropdown-item section_status_assigned" onClick={(event) => this.props.BidSelectedStatusUpdate(event, bid_section_selected, "Assigned")}>
                                                                    Assigned
                                                                </span>
                                                                <span className="fs-8 dropdown-item section_status_in_progress" onClick={(event) => this.props.BidSelectedStatusUpdate(event, bid_section_selected, "In progress")}>
                                                                    In progress
                                                                </span>
                                                                <span className="fs-8 dropdown-item section_status_for_review" onClick={(event) => this.props.BidSelectedStatusUpdate(event, bid_section_selected, "For review")}>
                                                                    For review
                                                                </span>
                                                                <span className="fs-8 dropdown-item section_status_completed" onClick={(event) => this.props.BidSelectedStatusUpdate(event, bid_section_selected, "Completed")}>
                                                                    Completed
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="py-5 px-3 border-bottom">
                                                    <h6 className="text-blue fs-8 mb-2">Section Assignees</h6>
                                                    <span className="fs-9 pb-2 d-block text-gray-400 ">
                                                        Team ({bid_section_selected.response_user && bid_section_selected.response_user.section_teams !== null ? bid_section_selected.response_user.section_teams.length : 0}):
                                                    </span>
                                                    <div className="symbol-group symbol-hover mb-4">
                                                        {bid_section_selected.response_user &&
                                                            bid_section_selected.response_user.section_teams &&
                                                            bid_section_selected.response_user.section_teams.map((section_team) => {
                                                                return (
                                                                    <div className="custom-tooltip" key={"section_team_" + section_team.user_id}>
                                                                        <div className="symbol symbol-25px symbol-circle">
                                                                            {section_team && section_team.avatar ? (
                                                                                <span className="symbol_img">
                                                                                    <img src={section_team.avatar} title={section_team.first_name} alt={section_team.first_name} />
                                                                                    <span className="custom-tooltip-text ">{section_team.first_name + " " + section_team.last_name}</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span className="symbol-label bg-primary text-inverse-primary fw-bolder">
                                                                                    {section_team.first_name.slice(0, 1) + section_team.last_name.slice(0, 1)}
                                                                                    <span className="custom-tooltip-text ">{section_team.first_name + " " + section_team.last_name}</span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        <div className="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Susan Redwood">
                                                            <span
                                                                className="symbol-label bg-secondary-dark text-inverse-primary fw-bolder"
                                                                onClick={() => {
                                                                    this.props.section_team_modal_action(true);
                                                                }}
                                                            >
                                                                <img src="assets/images/icons/plus.svg" title="" alt="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <span className="fs-9 pb-2 d-block text-gray-400 ">
                                                        Managers ({bid_section_selected.response_user && bid_section_selected.response_user.section_owners !== null ? bid_section_selected.response_user.section_owners.length : 0}):
                                                    </span>
                                                    <div className="symbol-group symbol-hover mb-4">
                                                        {bid_section_selected.response_user &&
                                                            bid_section_selected.response_user.section_owners &&
                                                            bid_section_selected.response_user.section_owners.map((section_owner) => {
                                                                return (
                                                                    <div className="custom-tooltip" key={"section_owner_" + section_owner.user_id}>
                                                                        <div className="symbol symbol-25px symbol-circle">
                                                                            {section_owner && section_owner.avatar ? (
                                                                                <span className="symbol_img">
                                                                                    <img src={section_owner.avatar} title={section_owner.first_name} alt={section_owner.first_name} />
                                                                                    <span className="custom-tooltip-text ">{section_owner.first_name + " " + section_owner.last_name}</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span className="symbol-label bg-primary text-inverse-primary fw-bolder">
                                                                                    {section_owner.first_name.slice(0, 1) + section_owner.last_name.slice(0, 1)}
                                                                                    <span className="custom-tooltip-text ">{section_owner.first_name + " " + section_owner.last_name}</span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        <div className="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Susan Redwood">
                                                            <span
                                                                className="symbol-label bg-secondary-dark text-inverse-primary fw-bolder"
                                                                onClick={() => {
                                                                    this.props.section_owner_modal_action(true);
                                                                }}
                                                            >
                                                                <img src="assets/images/icons/plus.svg" title="" alt="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="py-5 px-3">
                                                    <div className="d-flex mb-3 justify-content-between align-items-center">
                                                        <h6 className="text-blue fs-8 m-0">Comments</h6>
                                                        <span
                                                            className="curser_pointer"
                                                            onClick={() => {
                                                                this.props.sectioncomment_sidebar_action(true);
                                                            }}
                                                        >
                                                            <span className="icon-message1 text-blue"></span>
                                                        </span>
                                                    </div>
                                                    {bid_detail.bid_section_comment[bid_section_selected.section_id] &&
                                                        bid_detail.bid_section_comment[bid_section_selected.section_id].map((section_comment, comment_key) => {
                                                            return (
                                                                <div className="d-flex mb-5" key={"comment_" + section_comment.comment.comment_id}>
                                                                    <div className="symbol symbol-30px me-3">
                                                                        <img title="user" alt="user" src={section_comment.comment.user.avatar ? section_comment.comment.user.avatar : "assets/images/profile_dummy.jpg"} />
                                                                    </div>
                                                                    <div className="d-flex flex-column flex-row-fluid">
                                                                        <div className="d-flex align-items-center flex-wrap mb-1">
                                                                            <span className="text-grey-dark text-hover-primary fw-bold-700 me-2 fs-9">
                                                                                {section_comment.comment.user.first_name} {section_comment.comment.user.last_name}
                                                                            </span>
                                                                            <span className="text-grey-dark time-date-wrap fs-9">8 hrs ago</span>
                                                                        </div>
                                                                        <span className="text-grey-dark fs-9 fw-normal pb-2">{renderHTML(section_comment.comment.comment_text)}</span>
                                                                        <span
                                                                            className="text-pink fw-bold fs-9 curser_pointer"
                                                                            onClick={(event) => {
                                                                                this.props.BidSectionCommentReply(event, section_comment);
                                                                            }}
                                                                        >
                                                                            {section_comment.comment.reply_count} replies
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {bidcreate_section_activetab === "section_library" && (
                                        <div id="kt_section_library" className="card-body p-0 tab-pane fade right-side-comment active show">
                                            <div className="scroll-y right-scroll-height">
                                                <div className="p-5 section-title fw-bolder text-white">{bid_section_selected.title}</div>
                                            </div>
                                            <div className="p-5 border-bottom content-search">
                                                <h6 className="text-blue fs-8 mb-2">Content</h6>
                                                <div className="d-flex align-items-center position-relative search-bids">
                                                    <span className="svg-icon svg-icon-3 position-absolute me-2">
                                                        <img src="assets/images/icons/search.svg" title="all_bids" alt="all_bids" />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        placeholder="Search content library"
                                                        className="form-control form-control-sm ps-9 search-box"
                                                        onChange={(event) => {
                                                            this.GetLibraryWithContents(event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="aside-menu flex-column-fluid  my-8  right-sidebar-menu">
                                                    <div className="w-100 hover-scroll-overlay-y d-flex pe-2">
                                                        <div className="menu menu-column menu-rounded fw-bold p-0">
                                                            {library_conents &&
                                                                library_conents.map((library, library_key) => {
                                                                    return (
                                                                        <div className="menu-item menu-accordion" key={"library_key_" + library_key}>
                                                                            <span className="menu-link p-0 ">
                                                                                <span className="menu-arrow"></span>
                                                                                <span className="menu-title ps-2 hide_show_action" parent_id={"library_" + library.library_id}>
                                                                                    {library.name}
                                                                                </span>
                                                                            </span>
                                                                            {library.library_contents && (
                                                                                <div className="menu-sub menu-sub-accordion" id={"library_" + library.library_id}>
                                                                                    {library.library_contents.map((content, content_key) => {
                                                                                        return (
                                                                                            <div className="menu-item" key={"content_key_" + content_key}>
                                                                                                <span className="menu-link py-1">
                                                                                                    <span className="menu-bullet">
                                                                                                        <span className="bullet bullet-dot"></span>
                                                                                                    </span>
                                                                                                    <span className="menu-title ps-2 py-1" onClick={(event) => this.props.UpdateSectionWithLibraryContent(event, content)}>
                                                                                                        {content.content_name}
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {localStorage.getItem("bidresponse_pastproposals") === "yes" && (
                                                <div className="p-5 border-bottom content-search">
                                                    <h6 className="text-blue fs-8 mb-2">Past proposals</h6>
                                                    <div className="d-flex align-items-center position-relative search-bids">
                                                        <span className="svg-icon svg-icon-3 position-absolute me-2">
                                                            <img src="assets/images/icons/search.svg" title="all_bids" alt="all_bids" />
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search from past proposals"
                                                            className="form-control form-control-sm ps-9 search-box"
                                                            onChange={(event) => {
                                                                this.LibraryProposalFilter(event.target.value);
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="aside-menu flex-column-fluid  my-8  right-sidebar-menu">
                                                        <div className="w-100 hover-scroll-overlay-y d-flex pe-2">
                                                            <div className="menu menu-column menu-rounded fw-bold p-0">
                                                                {library_proposals &&
                                                                    library_proposals.map((proposal, proposal_key) => {
                                                                        return (
                                                                            <div className="menu-item menu-accordion" key={"proposal_key_" + proposal_key}>
                                                                                <span className="menu-link p-0 ">
                                                                                    <span className="menu-arrow"></span>
                                                                                    <span className="menu-title ps-2 hide_show_action" parent_id={"proposal_key_" + proposal.bid_id}>
                                                                                        {proposal.bid_title}
                                                                                    </span>
                                                                                </span>
                                                                                {proposal.responses && (
                                                                                    <div className="menu-sub menu-sub-accordion" id={"proposal_key_" + proposal.bid_id}>
                                                                                        {proposal.responses.map((response, response_key) => {
                                                                                            return (
                                                                                                <div className="menu-item" key={"bid_proposal_key_" + response_key}>
                                                                                                    <span className="menu-link py-1">
                                                                                                        <span className="menu-bullet">
                                                                                                            <span className="bullet bullet-dot"></span>
                                                                                                        </span>
                                                                                                        <span className="menu-title ps-2 py-1" onClick={(event) => this.props.AddNewBidproposal(event, response)}>
                                                                                                            {response.title}
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {bidcreate_section_activetab === "section_training" && (
                                        <div id="kt_section_training" className="card-body p-0 tab-pane fade active show">
                                            <div className="scroll-y right-scroll-height">
                                                <div className="p-5 section-title fw-bolder text-white">{bid_section_selected.title}</div>
                                                <div className="p-5">
                                                    <figure>
                                                        <img src="assets/images/video/Videothumbnail.jpg" alt="#" className="rounded-1" />
                                                        <figcaption>
                                                            <h6 className="text-dark fw-bolder fs-8 pt-3 mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                                                            <span className="text-gray-400 fs-9 fw-normal pb-1 d-block">
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit tortor sit libero sollicitudin. Pulvinar ultricies bibendum semper tortor ultrices. Etiam at in consequat malesuada
                                                                tellus nunc sit enim. Blandit elementum ipsum eget aliquam, ut mattis mauris et.
                                                            </span>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetBidProposal: userActions.GetBidProposal,
    onGetLibraryWithContents: userActions.GetLibraryWithContents,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BidRightSideBar);