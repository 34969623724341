import React from "react";
class BidExportAndShareTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bid_detail: "",
            response_content: [],
        };
    }

    componentDidMount() {
        this.setState({
            bid_detail: this.props.bid_detail,
            response_content: this.props.bid_response_form.response_content,
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-11 col-xl-10">
                        <div className="card mb-6">
                            <div className="p-7">
                                <h2 className="card-title text-dark fw-bold-700">Export</h2>
                                <p className="m-0">All your files will be zipped up and downloaded to your computer.</p>
                                <button type="button" className="btn btn-danger btn-lg rounded-50 mt-4">
                                    Download
                                </button>
                            </div>
                        </div>

                        <div className="card mb-6">
                            <div className="share-header p-7 pb-0">
                                <h2 className="card-title text-dark fw-bold-700 m-0">Share</h2>
                                <div className="card-toolbar">
                                    <span className="btn-icon">
                                        <i className="icon-share fs-2"></i>
                                    </span>{" "}
                                </div>
                            </div>

                            <div className="p-7 share-bottom">
                                <div className="row mb-7">
                                    <div className="col-lg-10 ">
                                        <label className="form-label fs-6 fw-bold-700">Link to share</label>
                                        <input type="text" className="form-control form-control-lg form-control-solid" placeholder="" value="" />
                                    </div>
                                    <div className="col-lg-2 mt-7">
                                        <button type="button" className="btn copy-link btn-lg rounded-50">
                                            Copy link
                                        </button>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-lg-12">
                                        <div className="border-top pt-4">
                                            <label className="form-label fs-6 fw-bold-700">Message</label>

                                            <textarea className="form-control form-control-lg form-control-solid" placeholder="Type a message.."></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className="row pb-4">
                                    <div className="col-lg-12">
                                        <div className="border-bottom pb-6 text-end">
                                            <button type="button" className="btn btn-lg rounded-50 w-auto">
                                                Cancel
                                            </button>
                                            <button type="button" className="btn copy-link btn-lg rounded-50 w-150px">
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-lg-12">
                                        <div className="pt-4">
                                            <label className="form-label fs-6 fw-bolder">Shared with</label>
                                            <p>These are the people you’ve already shared the link with.</p>
                                            <input type="text" className="form-control form-control-lg form-control-solid" placeholder="" value="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default BidExportAndShareTab;