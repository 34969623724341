import React from "react";
import { Route, Switch } from "react-router-dom";

import Test from "./containers/Page/Test";
import Invitation from "./containers/Page/Invitation";
import Authentication from "./containers/Page/Authentication";
import PrivacyPolicy from "./containers/Page/PrivacyPolicy";
import TermsConditions from "./containers/Page/TermsConditions";

import signin from "./containers/Auth/signin";
import verification from "./containers/Auth/verification";
import signup from "./containers/Auth/signup";
import signupcompany from "./containers/Auth/signupcompany";
import logout from "./containers/Auth/logout";
import ForgotPassword from "./containers/Auth/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword";

import Dashboard from "./containers/Application/Dashboard";
import Settings from "./containers/Application/Settings";
import Bid from "./containers/Application/Bid";
import Tasks from "./containers/Application/Tasks";
import Clients from "./containers/Application/Clients";
import Library from "./containers/Application/Library";

import AuthRouter from "./AuthRouter";
import ProtectedRouter from "./ProtectedRouters";

const Router = (props) => (
    <Switch>
        <Route path="/invitation/:id" exact component={Invitation} />
        <Route path="/authentication" exact component={Authentication} />
        <Route path="/privacy" exact component={PrivacyPolicy} />
        <Route path="/terms" exact component={TermsConditions} />
        <Route path="/test" exact component={Test} />

        {/*** Auth Route ***/}
        <Route path="/logout" exact component={logout} />
        <AuthRouter path="/" exact component={signin} />
        <AuthRouter path="/signin" exact component={signin} />
        <AuthRouter path="/verification" exact component={verification} />
        <AuthRouter path="/signup" exact component={signup} />
        <Route path="/signup-company" exact component={signupcompany} />
        <AuthRouter path="/forgot-password" component={ForgotPassword} />
        <AuthRouter path="/reset-password" component={ResetPassword} />

        {/*** Dashboard Route ***/}
        <ProtectedRouter path="/dashboard" exact component={Dashboard} />

        {/*** Settings Route ***/}
        <ProtectedRouter path="/settings-account" exact component={Settings} />
        <ProtectedRouter path="/settings-company" exact component={Settings} />
        <ProtectedRouter path="/settings-billing" exact component={Settings} />
        <ProtectedRouter path="/settings-users" exact component={Settings} />
        <ProtectedRouter path="/account-settings" exact component={Settings} />

        {/*** Bid Route ***/}
        <ProtectedRouter path="/bid-create" exact component={Bid} />
        <ProtectedRouter path="/bid-overview" exact component={Bid} />
        <ProtectedRouter path="/bid-update/:id" exact component={Bid} />
        <ProtectedRouter path="/bid-response/:id" exact component={Bid} />

        {/*** My tasks Route ***/}
        <ProtectedRouter path="/tasks" exact component={Tasks} />

        {/*** Clients Route ***/}
        <ProtectedRouter path="/clients" exact component={Clients} />
        <ProtectedRouter path="/client-create" exact component={Clients} />
        <ProtectedRouter path="/client-update/:id" exact component={Clients} />

        {/*** Library Route ***/}
        <ProtectedRouter path="/library" exact component={Library} />
        <ProtectedRouter path="/returnable-documents" exact component={Library} />
        <ProtectedRouter path="/case-study" exact component={Library} />
    </Switch>
);
export default Router;