import React from "react";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Redirect } from "react-router-dom";
import { render } from "@testing-library/react";

import BidCreateTab from "./BidCreateTab";
import BidDesignPreviewTab from "./BidDesignPreviewTab";
import BidReviewAndLockTab from "./BidReviewAndLockTab";
import BidExportAndShareTab from "./BidExportAndShareTab";

import BidRightSideBar from "./RightSideBar/BidRightSideBar";
import BidDesignPreviewRightSideBar from "./RightSideBar/BidDesignPreviewRightSideBar";
import BidRightSideComment from "./RightSideBar/BidRightSideComment";
import BidRightSideSectionComment from "./RightSideBar/BidRightSideSectionComment";

import InsertSectionModal from "./Modal/InsertSectionModal";
import AddBidTeamModal from "./Modal/AddBidTeamModal";
import AddBidOwnersModal from "./Modal/AddBidOwnersModal";

import AddSectionTeamModal from "./Modal/AddSectionTeamModal";
import AddSectionOwnerModal from "./Modal/AddSectionOwnerModal";

import ConfirmDialog from "../../../components/Utility/ConfirmDialog";

import * as userActions from "../../../actions/index";
import Loader from "../../../components/Utility/Loader";
import LoginHeader from "../../../components/LoginHeader";
import LoginSidebar from "../../../components/LoginSidebar";
import LoginFooter from "../../../components/LoginFooter";

class BidResponse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bid_response_form: {
                bid_id: {
                    label: "Bid ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                response_content: [],
            },

            bid_detail: [],
            loader: false,
            company_users: [],
            manage_content: [],

            confirm_modal: false,

            bid_create_rightsidebar: true,
            bid_response_tabs: "bid_create",
            bid_designpreview_rightsidebar: false,

            // bid_create_rightsidebar: false,
            // bid_response_tabs: "bid_designpreview",
            // bid_designpreview_rightsidebar: true,

            // bid_create_rightsidebar: false,
            // bid_response_tabs: "bid_reviewandlock",
            // bid_designpreview_rightsidebar: false,

            bid_section_selected_handle: true,
            bid_create_rightsidebar_comment: false,
            bid_create_rightsidebar_sectioncomment: false,

            bid_team_modal: false,
            bid_owner_modal: false,
            bid_insert_section_modal: false,

            section_team_modal: false,
            section_owner_modal: false,

            response_pdf: "",
            response_record: "",
        };

        this.ConfirmDialogEvent = React.createRef();
        this.BidSelectedClickOutside = this.BidSelectedClickOutside.bind(this);
        this.setBidCreateWrapperRef = this.setBidCreateWrapperRef.bind(this);
        this.setBidCreateSideBarWrapperRef = this.setBidCreateSideBarWrapperRef.bind(this);
    }

    key_to_array_multidimensional(array_objects, key) {
        let custom_array = {};
        for (let map_key in array_objects) {
            let array_object = array_objects[map_key];
            let new_key = array_object[key];
            if (typeof custom_array[array_object[key]] === "undefined") {
                custom_array[new_key] = array_object;
            }
        }
        return custom_array;
    }

    async componentDidMount() {
        document.addEventListener("click", this.BidSelectedClickOutside);

        if (localStorage.getItem("bidresponse_bidcreatesidebar") !== "yes") {
            this.setState({ bid_create_rightsidebar: false });
        }

        await this.props.onGetCompanyUsers().then((response) => {
            if (response.success === true) {
                this.setState({ company_users: response.data });
            } else {
                notify.show(response.message, "error");
            }
        });

        await this.GetBidById();
        await this.GetBidtypeContent();
    }

    GetBidById = async () => {
        await this.props.onGetBidById(this.props.bid_id).then((response) => {
            if (response.success === true) {
                let bid_detail = response.data;
                let bid_section_comment = [];
                let bid_response_comments = [];
                if (bid_detail.response_comments) {
                    let response_comments = bid_detail.response_comments;
                    for (let key in response_comments) {
                        let comment = response_comments[key];
                        //Comment: Separate section comments and bid comments
                        if (comment.section_id) {
                            if (typeof bid_section_comment[comment.section_id] === "undefined") {
                                bid_section_comment[comment.section_id] = [];
                            }
                            if (comment.parent_id === null) {
                                if (typeof bid_section_comment[comment.section_id][comment.comment_id] === "undefined") {
                                    bid_section_comment[comment.section_id][comment.comment_id] = {};
                                }
                                bid_section_comment[comment.section_id][comment.comment_id]["comment"] = comment;
                            } else {
                                if (typeof bid_section_comment[comment.section_id][comment.parent_id] === "undefined") {
                                    bid_section_comment[comment.section_id][comment.parent_id] = {};
                                }
                                if (typeof bid_section_comment[comment.section_id][comment.parent_id]["child"] === "undefined") {
                                    bid_section_comment[comment.section_id][comment.parent_id]["child"] = [];
                                }
                                bid_section_comment[comment.section_id][comment.parent_id]["child"].push(comment);
                            }
                        } else {
                            if (comment.parent_id === null) {
                                if (typeof bid_response_comments[comment.comment_id] === "undefined") {
                                    bid_response_comments[comment.comment_id] = {};
                                }
                                bid_response_comments[comment.comment_id]["comment"] = comment;
                            } else {
                                if (typeof bid_response_comments[comment.parent_id] === "undefined") {
                                    bid_response_comments[comment.parent_id] = {};
                                }
                                if (typeof bid_response_comments[comment.parent_id]["child"] === "undefined") {
                                    bid_response_comments[comment.parent_id]["child"] = [];
                                }
                                bid_response_comments[comment.parent_id]["child"].push(comment);
                            }
                        }
                    }
                }

                bid_detail.bid_section_comment = bid_section_comment;
                bid_detail.bid_response_comments = bid_response_comments;

                this.setState({ bid_detail: bid_detail }, function () {
                    this.GetBidSectionByBidId();
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetBidSectionByBidId = async () => {
        await this.props.onGetBidSectionByBidId(this.props.bid_id).then((response) => {
            if (response.success === true) {
                const update_bid_response_form = { ...this.state.bid_response_form };
                let response_content = update_bid_response_form.response_content;
                response_content = response.data;
                update_bid_response_form.response_content = response_content;
                this.setState({
                    bid_response_form: update_bid_response_form,
                });
                if (this.BidCreateEvent !== undefined) {
                    this.BidCreateEvent.BidCreateTabReload();
                }
                if (this.BidRightSideBarEvent !== undefined) {
                    this.BidRightSideBarEvent.BidRightSideBarReload();
                }
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetBidSectionById = async (section_id) => {
        await this.props.onGetBidSectionById(section_id).then((response) => {
            if (response.success === true) {
                this.setState({ bid_section_selected: response.data }, function () {
                    if (this.BidCreateEvent !== undefined) {
                        this.BidCreateEvent.BidCreateTabReload();
                    }
                    if (this.BidRightSideBarEvent !== undefined) {
                        this.BidRightSideBarEvent.BidRightSideBarReload();
                    }
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetBidtypeContent = async () => {
        let bidtype_id = this.state.bid_detail.bidtype_id;
        this.props.onGetBidtypeContent(bidtype_id).then((response) => {
            if (response.success === true) {
                this.setState({
                    manage_content: this.key_to_array_multidimensional(response.data, "content_id"),
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    load_UpdateBidTeam = async () => {
        await this.GetBidById();
        this.AddBidTeamEvent.AddBidTeamReload();
        if (this.BidRightSideBarEvent !== undefined) {
            this.BidRightSideBarEvent.BidRightSideBarReload();
        }
    };

    RemoveBidTeam = async (bid_detail, bid_team) => {
        render(
            <ConfirmDialog
                title={"Remove " + bid_team.first_name + " " + bid_team.last_name + " ?"}
                body="Are you sure you want to delete this team member?"
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmRemoveBidTeam(bid_detail, bid_team)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    };

    ConfirmRemoveBidTeam(bid_detail, bid_team) {
        const form_data = {};
        form_data["bid_id"] = bid_detail.bid_id;
        form_data["bid_team"] = bid_team.user_id;
        this.props.onRemoveBidTeam(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateBidTeam();
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    UpdateBidTeam(form_data) {
        this.props.onUpdateBidTeam(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateBidTeam();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    load_UpdateBidOwner = async () => {
        await this.GetBidById();
        this.AddBidOwnersEvent.AddBidOwnersReload();
        if (this.BidRightSideBarEvent !== undefined) {
            this.BidRightSideBarEvent.BidRightSideBarReload();
        }
    };

    UpdateBidOwner(form_data) {
        this.props.onUpdateBidOwner(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateBidOwner();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    RemoveBidOwner = async (bid_detail, bid_owner) => {
        render(
            <ConfirmDialog
                title={"Remove " + bid_owner.first_name + " " + bid_owner.last_name + " ?"}
                body="Are you sure you want to delete this owner ?"
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmRemoveBidOwner(bid_detail, bid_owner)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    };

    ConfirmRemoveBidOwner(bid_detail, bid_owner) {
        const form_data = {};
        form_data["bid_id"] = bid_detail.bid_id;
        form_data["bid_owner"] = bid_owner.user_id;
        this.props.onRemoveBidOwner(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateBidOwner();
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    InsertSectionSubmit(form_data) {
        const formdata = {};
        let manage_content = this.state.manage_content;

        formdata["response_content"] = JSON.stringify({
            bid_id: this.props.bid_id,
            title: manage_content[form_data.bid_content].title,
            description: manage_content[form_data.bid_content].content,
            content_id: manage_content[form_data.bid_content].content_id,
            sort_order: parseInt(this.state.bid_response_form.response_content.length) + 1,
        });

        this.props.onBidSectionInsert(formdata).then((response) => {
            if (response.success === true) {
                const update_bid_response_form = { ...this.state.bid_response_form };
                let response_content = update_bid_response_form.response_content;
                response_content.push(response.data);
                update_bid_response_form.response_content = response_content;

                this.setState(
                    {
                        bid_insert_section_modal: false,
                        bid_response_form: update_bid_response_form,
                    },
                    function () {
                        this.BidCreateEvent.BidCreateTabReload();
                        if (this.BidRightSideBarEvent !== undefined) {
                            this.BidRightSideBarEvent.BidRightSideBarReload();
                        }
                    }
                );
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidSectionRemove(bid_content) {
        render(
            <ConfirmDialog
                title={"Remove " + bid_content.title + "?"}
                body="Are you sure you want to delete this section? It’ll be gone permanently."
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmBidSectionRemove(bid_content)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    }

    load_BidSection = async () => {
        await this.GetBidSectionByBidId();
        this.BidCreateEvent.BidCreateTabReload();
        if (this.BidRightSideBarEvent !== undefined) {
            this.BidRightSideBarEvent.BidRightSideBarReload();
        }
    };

    ConfirmBidSectionRemove(bid_content) {
        const form_data = {};
        form_data["bid_id"] = this.state.bid_detail.bid_id;
        form_data["section_id"] = bid_content.section_id;
        this.props.onBidSectionRemove(form_data).then((response) => {
            if (response.success === true) {
                this.load_BidSection();
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidSectionUpdate(form_data) {
        const formdata = {};
        formdata["response_content"] = JSON.stringify({
            bid_id: form_data.bid_id,
            title: form_data.title,
            description: form_data.description,
            section_id: form_data.section_id,
        });
        this.props.onBidSectionUpdate(formdata).then((response) => {
            if (response.success === true) {
                // this.GetBidSectionByBidId();
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidStatusUpdate(event, bid_status) {
        event.preventDefault();
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["bid_status"] = bid_status;
        this.props.onBidStatusUpdate(formdata).then((response) => {
            if (response.success === true) {
                this.GetBidById();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidCommentReply(event, bid_comment) {
        event.preventDefault();
        this.setState({
            bid_comment: bid_comment,
            bid_create_rightsidebar_comment: true,
        });
    }

    load_BidCommentEvent = async (new_comment) => {
        if (new_comment) {
            await this.GetBidById();
            let bid_detail = this.state.bid_detail;
            let comment_id = new_comment.parent_id === null ? new_comment.comment_id : new_comment.parent_id;
            this.setState({
                bid_create_rightsidebar_comment: true,
                bid_comment: bid_detail.bid_response_comments[comment_id],
            });
        }
        if (this.BidRightSideCommentEvent !== undefined) {
            this.BidRightSideCommentEvent.BidRightSideCommentReload();
        }
    };

    BidCreateComment(comment_text, parent_id) {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["comment_text"] = comment_text;
        if (parent_id) {
            formdata["parent_id"] = parent_id;
        }
        this.props.onCreateComment(formdata).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                this.load_BidCommentEvent(response.data);
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidUploadDocument(file) {
        const formdata = {};
        formdata["file"] = file;
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        this.props.onBidUploadDocument(formdata).then((response) => {
            if (response.success === true) {
                this.GetBidById();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidRemoveDocument(event, media) {
        render(
            <ConfirmDialog
                title={"Remove " + media.media_meta.title + "?"}
                body="Are you sure you want to delete this section? It’ll be gone permanently."
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmBidRemoveDocument(event, media)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    }

    ConfirmBidRemoveDocument(event, media) {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["response_media_id"] = media.response_media_id;
        this.props.onBidRemoveDocument(formdata).then((response) => {
            if (response.success === true) {
                this.GetBidById();
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidSectionSelected(bid_content_key, bid_content) {
        let response_content = this.state.bid_response_form.response_content;
        response_content = this.key_to_array_multidimensional(response_content, "section_id");
        this.setState({ bid_section_selected: response_content[bid_content.section_id] }, function () {
            if (this.BidCreateEvent !== undefined) {
                this.BidCreateEvent.BidCreateTabReload();
            }
            if (this.BidRightSideBarEvent !== undefined) {
                this.BidRightSideBarEvent.BidRightSideBarReload();
            }
        });
    }

    BidSectionShortingUpdate = (response_contents) => {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["response_contents"] = JSON.stringify(response_contents);
        this.props.onBidSectionShortingUpdate(formdata).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    load_BidSelectedStatusUpdate = async (section_id) => {
        await this.GetBidSectionByBidId();
        let response_content = this.state.bid_response_form.response_content;
        response_content = this.key_to_array_multidimensional(response_content, "section_id");
        this.setState({ bid_section_selected: response_content[section_id] }, function () {
            if (this.BidCreateEvent !== undefined) {
                this.BidCreateEvent.BidCreateTabReload();
            }
            if (this.BidRightSideBarEvent !== undefined) {
                this.BidRightSideBarEvent.BidRightSideBarReload();
            }
        });
    };

    BidSelectedStatusUpdate = (event, section_selected, section_status) => {
        event.preventDefault();
        const formdata = {};
        formdata["bid_id"] = section_selected.bid_id;
        formdata["section_id"] = section_selected.section_id;
        formdata["section_status"] = section_status;
        this.props.onBidSelectedStatusUpdate(formdata).then((response) => {
            if (response.success === true) {
                this.load_BidSelectedStatusUpdate(response.data[1][0].section_id);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    load_UpdateSectionTeam = async (section_id) => {
        await this.GetBidSectionByBidId();
        let response_content = this.state.bid_response_form.response_content;
        response_content = this.key_to_array_multidimensional(response_content, "section_id");
        this.setState({ bid_section_selected: response_content[section_id] }, function () {
            if (this.BidCreateEvent !== undefined) {
                this.BidCreateEvent.BidCreateTabReload();
            }
            if (this.BidRightSideBarEvent !== undefined) {
                this.BidRightSideBarEvent.BidRightSideBarReload();
            }
            if (this.AddSectionTeamEvent !== undefined) {
                this.AddSectionTeamEvent.AddSectionTeamReload();
            }
        });
    };

    UpdateSectionTeam(form_data) {
        this.props.onUpdateSectionTeam(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateSectionTeam(form_data["section_id"]);
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    RemoveSectionTeam = async (bid_section_selected, section_team) => {
        render(
            <ConfirmDialog
                title={"Remove " + section_team.first_name + " " + section_team.last_name + " ?"}
                body="Are you sure you want to delete this team member?"
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmRemoveSectionTeam(bid_section_selected, section_team)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    };

    ConfirmRemoveSectionTeam(bid_section_selected, section_team) {
        const form_data = {};
        form_data["bid_id"] = bid_section_selected.bid_id;
        form_data["section_id"] = bid_section_selected.section_id;
        form_data["section_team"] = section_team.user_id;
        this.props.onRemoveSectionTeam(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateSectionTeam(form_data["section_id"]);
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    load_UpdateSectionOwner = async (section_id) => {
        await this.GetBidSectionByBidId();
        let response_content = this.state.bid_response_form.response_content;
        response_content = this.key_to_array_multidimensional(response_content, "section_id");
        this.setState({ bid_section_selected: response_content[section_id] }, function () {
            if (this.BidCreateEvent !== undefined) {
                this.BidCreateEvent.BidCreateTabReload();
            }
            if (this.BidRightSideBarEvent !== undefined) {
                this.BidRightSideBarEvent.BidRightSideBarReload();
            }
            if (this.AddSectionOwnerEvent !== undefined) {
                this.AddSectionOwnerEvent.AddSectionOwnerReload();
            }
        });
    };

    UpdateSectionOwner(form_data) {
        this.props.onUpdateSectionOwner(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateSectionOwner(form_data["section_id"]);
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    RemoveSectionOwner = async (bid_section_selected, section_owner) => {
        render(
            <ConfirmDialog
                title={"Remove " + section_owner.first_name + " " + section_owner.last_name + " ?"}
                body="Are you sure you want to delete this owner?"
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmRemoveSectionOwner(bid_section_selected, section_owner)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    };

    ConfirmRemoveSectionOwner(bid_section_selected, section_owner) {
        const form_data = {};
        form_data["bid_id"] = bid_section_selected.bid_id;
        form_data["section_id"] = bid_section_selected.section_id;
        form_data["section_owner"] = section_owner.user_id;
        this.props.onRemoveSectionOwner(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateSectionOwner(form_data["section_id"]);
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    load_BidSectionCommentEvent = async (new_comment) => {
        if (new_comment) {
            await this.GetBidById();
            let bid_detail = this.state.bid_detail;
            let section_id = new_comment.section_id;
            let comment_id = new_comment.parent_id === null ? new_comment.comment_id : new_comment.parent_id;
            this.setState({
                bid_create_rightsidebar_sectioncomment: true,
                section_comment: bid_detail.bid_section_comment[section_id][comment_id],
            });
        }
        if (this.BidRightSideBarEvent !== undefined) {
            this.BidRightSideBarEvent.BidRightSideBarReload();
        }
        if (this.BidRightSideSectionCommentEvent !== undefined) {
            this.BidRightSideSectionCommentEvent.BidRightSideSectionCommentReload();
        }
    };

    BidSectionCommentReply(event, section_comment) {
        event.preventDefault();

        this.setState({
            section_comment: section_comment,
            bid_create_rightsidebar_sectioncomment: true,
        });
    }

    BidSectionComment(comment_text, parent_id, section_id) {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["section_id"] = section_id;
        formdata["comment_text"] = comment_text;
        if (parent_id) {
            formdata["parent_id"] = parent_id;
        }
        this.props.onCreateComment(formdata).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                this.load_BidSectionCommentEvent(response.data);
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    UpdateSectionWithLibraryContent(event, content) {
        event.preventDefault();

        const formdata = {};
        let bid_section_selected = this.state.bid_section_selected;
        bid_section_selected.description += content.description;
        formdata["response_content"] = JSON.stringify({
            bid_id: this.props.bid_id,
            title: bid_section_selected.title,
            description: bid_section_selected.description,
            section_id: bid_section_selected.section_id,
        });
        this.props.onBidSectionUpdate(formdata).then((response) => {
            if (response.success === true) {
                this.load_BidSelectedStatusUpdate(response.data[1][0].section_id);
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    AddNewBidproposal(event, proposal) {
        event.preventDefault();
        console.log("proposal-----------", proposal);
    }

    /*** Set the wrapper ref ***/
    setBidCreateWrapperRef(node) {
        this.BidCreateWrapperRef = node;
    }
    setBidCreateSideBarWrapperRef(node) {
        this.BidCreateSideBarWrapperRef = node;
    }
    /*** Alert if clicked on outside of element ***/
    BidSelectedClickOutside(event) {
        if (this.state.bid_section_selected_handle && event.target.className.includes("bid_section_selected_handle_false") === false) {
            if (this.BidCreateWrapperRef && !this.BidCreateWrapperRef.contains(event.target) && this.BidCreateSideBarWrapperRef && !this.BidCreateSideBarWrapperRef.contains(event.target)) {
                this.setState({ bid_section_selected: undefined }, function () {
                    if (this.BidCreateEvent !== undefined) {
                        this.BidCreateEvent.BidCreateTabReload();
                    }
                    if (this.BidRightSideBarEvent !== undefined) {
                        this.BidRightSideBarEvent.BidRightSideBarReload();
                    }
                });
            }
        }
    }

    /***
     ** Design Preview Tab functions
     ***/
    GetResponseRecord() {
        this.props.onGetResponseRecord(this.props.bid_id).then((response) => {
            if (response.success === true) {
                this.setState({ response_record: response.data }, function () {
                    this.DesignPreviewGenratepdf();
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    DesignPreviewGenratepdf() {
        this.props.onDesignPreviewGenratepdf(this.state.bid_detail.bid_id).then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        bid_create_rightsidebar: false,
                        response_pdf: response.pdf_url,
                        bid_designpreview_rightsidebar: true,
                        bid_response_tabs: "bid_designpreview",
                    },
                    function () {
                        if (this.BidDesignPreviewEvent !== undefined) {
                            this.BidDesignPreviewEvent.BidDesignPreviewReload();
                        }
                        if (this.BidDesignPreviewRightSideBarEvent !== undefined) {
                            this.BidDesignPreviewRightSideBarEvent.BidDesignPreviewRightSideBarReload();
                        }
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    DesignPreviewUpdatepdf(form_data) {
        this.props.onDesignPreviewUpdatepdf(form_data).then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        response_pdf: "",
                    },
                    function () {
                        if (this.BidDesignPreviewRightSideBarEvent !== undefined) {
                            this.BidDesignPreviewRightSideBarEvent.BidDesignPreviewRightSideBarReload();
                        }
                        if (this.BidDesignPreviewEvent !== undefined) {
                            this.BidDesignPreviewEvent.BidDesignPreviewReload();
                        }
                        this.GetResponseRecord();
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    render() {
        const { bid_detail, bid_create_rightsidebar, bid_designpreview_rightsidebar, bid_create_rightsidebar_comment, bid_create_rightsidebar_sectioncomment } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.props.isAuthenticated === false ? <Redirect to="/logout" /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                {this.state.bid_insert_section_modal && (
                    <InsertSectionModal
                        bid_detail={bid_detail}
                        manage_content={this.state.manage_content}
                        bid_insert_section_modal={this.state.bid_insert_section_modal}
                        InsertSectionSubmit={(form_data) => this.InsertSectionSubmit(form_data)}
                        bid_insert_section_modal_action={(modal_action) => {
                            this.setState({
                                bid_insert_section_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.bid_team_modal && (
                    <AddBidTeamModal
                        bid_detail={bid_detail}
                        ref={(ref) => (this.AddBidTeamEvent = ref)}
                        company_users={this.state.company_users}
                        bid_team_modal={this.state.bid_team_modal}
                        UpdateBidTeam={(form_data) => this.UpdateBidTeam(form_data)}
                        RemoveBidTeam={(bid_detail, bid_team) => {
                            this.setState({ confirm_modal: true }, function () {
                                this.RemoveBidTeam(bid_detail, bid_team);
                            });
                        }}
                        bid_team_modal_action={(modal_action) => {
                            this.setState({
                                bid_team_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.bid_owner_modal && (
                    <AddBidOwnersModal
                        bid_detail={this.state.bid_detail}
                        ref={(ref) => (this.AddBidOwnersEvent = ref)}
                        company_users={this.state.company_users}
                        bid_owner_modal={this.state.bid_owner_modal}
                        UpdateBidOwner={(form_data) => this.UpdateBidOwner(form_data)}
                        RemoveBidOwner={(bid_detail, bid_owner) => {
                            this.setState({ confirm_modal: true }, function () {
                                this.RemoveBidOwner(bid_detail, bid_owner);
                            });
                        }}
                        bid_owner_modal_action={(modal_action) => {
                            this.setState({
                                bid_owner_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.section_team_modal && (
                    <AddSectionTeamModal
                        bid_detail={this.state.bid_detail}
                        company_users={this.state.company_users}
                        ref={(ref) => (this.AddSectionTeamEvent = ref)}
                        section_team_modal={this.state.section_team_modal}
                        bid_section_selected={this.state.bid_section_selected}
                        UpdateSectionTeam={(form_data) => this.UpdateSectionTeam(form_data)}
                        RemoveSectionTeam={(bid_section_selected, section_team) => {
                            this.setState({ confirm_modal: true }, function () {
                                this.RemoveSectionTeam(bid_section_selected, section_team);
                            });
                        }}
                        section_team_modal_action={(modal_action) => {
                            this.setState(
                                {
                                    bid_section_selected_handle: true,
                                    section_team_modal: modal_action,
                                },
                                function () {
                                    document.addEventListener("click", this.BidSelectedClickOutside);
                                }
                            );
                        }}
                    />
                )}

                {this.state.section_owner_modal && (
                    <AddSectionOwnerModal
                        bid_detail={this.state.bid_detail}
                        company_users={this.state.company_users}
                        ref={(ref) => (this.AddSectionOwnerEvent = ref)}
                        section_owner_modal={this.state.section_owner_modal}
                        bid_section_selected={this.state.bid_section_selected}
                        UpdateSectionOwner={(form_data) => this.UpdateSectionOwner(form_data)}
                        RemoveSectionOwner={(bid_section_selected, section_owner) => {
                            this.setState({ confirm_modal: true }, function () {
                                this.RemoveSectionOwner(bid_section_selected, section_owner);
                            });
                        }}
                        section_owner_modal_action={(modal_action) => {
                            this.setState(
                                {
                                    bid_section_selected_handle: true,
                                    section_owner_modal: modal_action,
                                },
                                function () {
                                    document.addEventListener("click", this.BidSelectedClickOutside);
                                }
                            );
                        }}
                    />
                )}

                <div
                    className={
                        "d-flex flex-column flex-root" +
                        (bid_create_rightsidebar ? " right-aside-wrap bid_create_rightsidebar" : "") +
                        (bid_designpreview_rightsidebar ? " right-aside-wrap bid_designpreview_rightsidebar" : "") +
                        (bid_create_rightsidebar_comment ? " bid_rightsidebar_comment" : "") +
                        (bid_create_rightsidebar_sectioncomment ? " bid_rightsidebar_comment" : "")
                    }
                >
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Bid creation" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div>
                                    <div className="toolbar flex-wrap mb-10" id="kt_toolbar">
                                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack flex-wrap">
                                            <div className="page-title align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                                <h2 className="d-flex align-items-center text-pink fw-bold-700 my-1">{bid_detail.bid_title}</h2>
                                                <div className="d-flex fw-bold fs-7 my-1">
                                                    <div className="me-4 text-dark">
                                                        <span className="fw-bold-700">Bid ID: </span> {bid_detail.bid_id}
                                                    </div>
                                                    <div className="text-dark">
                                                        <span className="fw-bold-700">Client: </span> {bid_detail.client !== undefined ? bid_detail.client.client_name : "-"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center py-1">
                                                <div className="symbol-group symbol-hover me-10">
                                                    {bid_detail.bid_teams &&
                                                        bid_detail.bid_teams.map((bid_team) => {
                                                            return (
                                                                <div className="custom-tooltip" key={"bid_owner_" + bid_team.user_id}>
                                                                    <div className="symbol symbol-25px symbol-circle">
                                                                        {bid_team && bid_team.avatar ? (
                                                                            <span className="symbol_img">
                                                                                <img src={bid_team.avatar} title={bid_team.first_name} alt={bid_team.first_name} />
                                                                                <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                                                            </span>
                                                                        ) : (
                                                                            <span className="symbol-label bg-primary text-inverse-primary">
                                                                                {bid_team.first_name.slice(0, 1) + bid_team.last_name.slice(0, 1)}
                                                                                <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                                <div className="">
                                                    {bid_detail.bid_status && (
                                                        <span className="btn btn-progress btn-sm rounded-50 fs-9" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                            <span className="svg-icon-5 me-1">
                                                                <i className={"p-0 bit_status_" + bid_detail.bid_status.replace(" ", "_").toLowerCase()}></i>
                                                            </span>
                                                            {bid_detail.bid_status}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-top container-fluid mt-2 w-100 d-flex overflow-auto h-40px nav-border-3">
                                            <ul className="nav nav-stretch custom-style">
                                                <li className={"nav-item" + (this.state.bid_response_tabs === "bid_create" ? " active" : "")} key="bid_create_tab">
                                                    <span className="nav-link me-4">
                                                        <span className="bid-txt1">1</span> Bid Creation
                                                    </span>
                                                </li>
                                                {localStorage.getItem("bidresponse_designpreviewTab") === "yes" && (
                                                    <li className={"nav-item" + (this.state.bid_response_tabs === "bid_designpreview" ? " active" : "")} key="bid_designpreview_tab">
                                                        <span className="nav-link me-4">
                                                            <span className="bid-txt1">2</span> Design Preview
                                                        </span>
                                                    </li>
                                                )}
                                                {localStorage.getItem("bidresponse_reviewandlockTab") === "yes" && (
                                                    <li className={"nav-item" + (this.state.bid_response_tabs === "bid_reviewandlock" ? " active" : "")} key="bid_reviewandlock_tab">
                                                        <span className="nav-link me-4">
                                                            <span className="bid-txt1">3</span> Review and Lock
                                                        </span>
                                                    </li>
                                                )}
                                                {localStorage.getItem("bidresponse_exportandshareTab") === "yes" && (
                                                    <li className={"nav-item" + (this.state.bid_response_tabs === "bid_exportandshare" ? " active" : "")} key="bid_exportandshare_tab">
                                                        <span className="nav-link me-4">
                                                            <span className="bid-txt1">4</span> Export and Share
                                                        </span>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-xxl">
                                            {this.state.bid_response_tabs === "bid_create" && bid_detail.bid_id && (
                                                <div id="bid_create_tab">
                                                    <BidCreateTab
                                                        bid_detail={bid_detail}
                                                        ref={(ref) => (this.BidCreateEvent = ref)}
                                                        section_select_ref={this.setBidCreateWrapperRef}
                                                        bid_response_form={this.state.bid_response_form}
                                                        bid_section_selected={this.state.bid_section_selected}
                                                        BidUploadDocument={(file) => this.BidUploadDocument(file)}
                                                        BidSectionUpdate={(form_data) => this.BidSectionUpdate(form_data)}
                                                        bid_insert_section_action={(modal_action) => this.setState({ bid_insert_section_modal: modal_action })}
                                                        BidSectionRemove={(event, bid_content) => {
                                                            this.setState({ confirm_modal: true }, function () {
                                                                this.BidSectionRemove(bid_content);
                                                            });
                                                        }}
                                                        BidRemoveDocument={(event, media) => {
                                                            this.setState({ confirm_modal: true }, function () {
                                                                this.BidRemoveDocument(event, media);
                                                            });
                                                        }}
                                                        BidSectionSelected={(bid_content_key, bid_content) => this.BidSectionSelected(bid_content_key, bid_content)}
                                                        BidSectionUnselected={(event) => {
                                                            this.setState({ bid_section_selected: undefined }, function () {
                                                                if (this.BidCreateEvent !== undefined) {
                                                                    this.BidCreateEvent.BidCreateTabReload();
                                                                }
                                                                if (this.BidRightSideBarEvent !== undefined) {
                                                                    this.BidRightSideBarEvent.BidRightSideBarReload();
                                                                }
                                                            });
                                                        }}
                                                        BidSectionShortingUpdate={(response_contents) => this.BidSectionShortingUpdate(response_contents)}
                                                    />
                                                    {localStorage.getItem("bidresponse_bidcreate_continuebutton") === "yes" && (
                                                        <div className="row">
                                                            <div className="col-lg-11 col-xl-10">
                                                                <div className="nav_footer_button text-end">
                                                                    <button type="button" className="btn btn-danger btn-lg rounded-50" onClick={(event) => this.GetResponseRecord(event)}>
                                                                        Continue <span className="icon-right-arrow fs-9 ps-1"></span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {this.state.bid_response_tabs === "bid_designpreview" && (
                                                <div id="bid_designpreview_tab">
                                                    <BidDesignPreviewTab bid_detail={bid_detail} response_pdf={this.state.response_pdf} ref={(ref) => (this.BidDesignPreviewEvent = ref)} bid_response_form={this.state.bid_response_form} />
                                                    <div className="row">
                                                        <div className="col-lg-11 col-xl-10">
                                                            <div className="nav_footer_button text-end">
                                                                <button
                                                                    type="button"
                                                                    className="btn back-button btn-lg rounded-50 me-2"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            bid_response_tabs: "bid_create",
                                                                            bid_create_rightsidebar: true,
                                                                            bid_designpreview_rightsidebar: false,
                                                                        });
                                                                    }}
                                                                >
                                                                    <span className="icon-left-arrow fs-9"></span>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-lg rounded-50 me-2"
                                                                    onClick={() => {
                                                                        this.setState({ bid_response_tabs: "bid_reviewandlock" });
                                                                    }}
                                                                >
                                                                    Continue <span className="icon-right-arrow fs-9 ps-1"></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.bid_response_tabs === "bid_reviewandlock" && (
                                                <div id="bid_designpreview_tab">
                                                    <BidReviewAndLockTab bid_detail={bid_detail} bid_response_form={this.state.bid_response_form} />
                                                    <div className="row">
                                                        <div className="col-lg-11 col-xl-10">
                                                            <div className="nav_footer_button text-end">
                                                                <button
                                                                    type="button"
                                                                    className="back-button btn  btn-lg rounded-50 me-2"
                                                                    onClick={() => {
                                                                        this.setState({ bid_response_tabs: "bid_designpreview" });
                                                                    }}
                                                                >
                                                                    <span className="icon-left-arrow fs-9"></span>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-lg rounded-50 me-2"
                                                                    onClick={() => {
                                                                        this.setState({ bid_response_tabs: "bid_exportandshare" });
                                                                    }}
                                                                >
                                                                    Continue <span className="icon-right-arrow fs-9 ps-1"></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.bid_response_tabs === "bid_exportandshare" && (
                                                <div id="bid_designpreview_tab">
                                                    <BidExportAndShareTab bid_detail={bid_detail} bid_response_form={this.state.bid_response_form} />
                                                    <div className="row">
                                                        <div className="col-lg-11 col-xl-10">
                                                            <div className="nav_footer_button text-end">
                                                                <button
                                                                    type="button"
                                                                    className="btn back-button btn-lg rounded-50 me-2"
                                                                    onClick={() => {
                                                                        this.setState({ bid_response_tabs: "bid_reviewandlock" });
                                                                    }}
                                                                >
                                                                    <span className="icon-left-arrow fs-9"></span>
                                                                </button>

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-lg rounded-50 me-2"
                                                                    onClick={() => {
                                                                        this.setState({ bid_response_tabs: "bid_exportandshare" });
                                                                    }}
                                                                >
                                                                    Complete bid
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {localStorage.getItem("bidresponse_bidcreatesidebar") === "yes" && this.state.bid_response_tabs === "bid_create" && (
                                    <div className="right-sidebar bid_create_rightsidebar" ref={this.setBidCreateSideBarWrapperRef}>
                                        <button
                                            className="arrow-btn-wrap"
                                            onClick={() => {
                                                this.setState({ bid_create_rightsidebar: bid_create_rightsidebar ? false : true });
                                            }}
                                        >
                                            <img src="assets/images/icons/whiterightarrow.svg" alt="" />
                                            <img src="assets/images/icons/rightgrayarrow.svg" alt="" />
                                        </button>
                                        {bid_create_rightsidebar && bid_detail.bid_id && (
                                            <BidRightSideBar
                                                bid_detail={bid_detail}
                                                ref={(ref) => (this.BidRightSideBarEvent = ref)}
                                                bid_response_form={this.state.bid_response_form}
                                                bid_section_selected={this.state.bid_section_selected}
                                                BidStatusUpdate={(event, bid_status) => this.BidStatusUpdate(event, bid_status)}
                                                bid_team_modal_action={(modal_action) => this.setState({ bid_team_modal: modal_action })}
                                                bid_owner_modal_action={(modal_action) => this.setState({ bid_owner_modal: modal_action })}
                                                bid_insert_section_action={(modal_action) => this.setState({ bid_insert_section_modal: modal_action })}
                                                BidCommentReply={(event, bid_comment) => this.BidCommentReply(event, bid_comment)}
                                                comment_sidebar_action={(modal_action) => this.setState({ bid_create_rightsidebar_comment: modal_action })}
                                                BidSectionUnselected={(event) => {
                                                    this.setState({ bid_section_selected: undefined }, function () {
                                                        if (this.BidCreateEvent !== undefined) {
                                                            this.BidCreateEvent.BidCreateTabReload();
                                                        }
                                                        if (this.BidRightSideBarEvent !== undefined) {
                                                            this.BidRightSideBarEvent.BidRightSideBarReload();
                                                        }
                                                    });
                                                }}
                                                BidSelectedStatusUpdate={(event, section_selected, section_status) => this.BidSelectedStatusUpdate(event, section_selected, section_status)}
                                                section_team_modal_action={(modal_action) => {
                                                    this.setState(
                                                        {
                                                            bid_section_selected_handle: false,
                                                            section_team_modal: modal_action,
                                                        },
                                                        function () {
                                                            document.removeEventListener("click", this.BidSelectedClickOutside);
                                                        }
                                                    );
                                                }}
                                                section_owner_modal_action={(modal_action) => {
                                                    this.setState(
                                                        {
                                                            bid_section_selected_handle: false,
                                                            section_owner_modal: modal_action,
                                                        },
                                                        function () {
                                                            document.removeEventListener("click", this.BidSelectedClickOutside);
                                                        }
                                                    );
                                                }}
                                                sectioncomment_sidebar_action={(modal_action) => {
                                                    this.setState(
                                                        {
                                                            bid_section_selected_handle: false,
                                                            bid_create_rightsidebar_sectioncomment: modal_action,
                                                        },
                                                        function () {
                                                            document.removeEventListener("click", this.BidSelectedClickOutside);
                                                        }
                                                    );
                                                }}
                                                BidSectionCommentReply={(event, section_comment) => this.BidSectionCommentReply(event, section_comment)}
                                                UpdateSectionWithLibraryContent={(event, content) => this.UpdateSectionWithLibraryContent(event, content)}
                                                AddNewBidproposal={(event, proposal) => this.AddNewBidproposal(event, proposal)}
                                            />
                                        )}
                                    </div>
                                )}

                                {this.state.bid_response_tabs === "bid_designpreview" && (
                                    <div className="right-sidebar bid_designpreview_rightsidebar">
                                        <button
                                            className="arrow-btn-wrap"
                                            onClick={() => {
                                                this.setState({ bid_designpreview_rightsidebar: bid_designpreview_rightsidebar ? false : true });
                                            }}
                                        >
                                            <img src="assets/images/icons/whiterightarrow.svg" alt="" />
                                            <img src="assets/images/icons/rightgrayarrow.svg" alt="" />
                                        </button>
                                        {bid_designpreview_rightsidebar && (
                                            <BidDesignPreviewRightSideBar
                                                bid_detail={bid_detail}
                                                response_record={this.state.response_record}
                                                ref={(ref) => (this.BidDesignPreviewRightSideBarEvent = ref)}
                                                DesignPreviewUpdatepdf={(form_data) => this.DesignPreviewUpdatepdf(form_data)}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className="comment-overlay">
                                <div className="right_sidebar_comment">
                                    {bid_create_rightsidebar_comment && (
                                        <BidRightSideComment
                                            bid_comment={this.state.bid_comment}
                                            ref={(ref) => (this.BidRightSideCommentEvent = ref)}
                                            BidCreateComment={(comment_text, parent_id) => this.BidCreateComment(comment_text, parent_id)}
                                            bid_create_rightsidebar_comment_action={(modal_action) => {
                                                this.setState({
                                                    bid_comment: undefined,
                                                    bid_create_rightsidebar_comment: modal_action,
                                                });
                                            }}
                                        />
                                    )}
                                    {bid_create_rightsidebar_sectioncomment && (
                                        <BidRightSideSectionComment
                                            section_comment={this.state.section_comment}
                                            bid_section_selected={this.state.bid_section_selected}
                                            ref={(ref) => (this.BidRightSideSectionCommentEvent = ref)}
                                            BidSectionComment={(comment_text, parent_id, section_id) => this.BidSectionComment(comment_text, parent_id, section_id)}
                                            bid_create_rightsidebar_sectioncomment_action={(modal_action) => {
                                                this.setState(
                                                    {
                                                        section_comment: undefined,
                                                        bid_section_selected_handle: false,
                                                        bid_create_rightsidebar_sectioncomment: modal_action,
                                                    },
                                                    function () {
                                                        document.removeEventListener("click", this.BidSelectedClickOutside);
                                                    }
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            </div>

                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetTags: userActions.GetTags,
    onGetBidById: userActions.GetBidById,
    onGetCompanyUsers: userActions.GetCompanyUsers,

    onGetBidtypeContent: userActions.GetBidtypeContent,

    onUpdateBidTeam: userActions.UpdateBidTeam,
    onRemoveBidTeam: userActions.RemoveBidTeam,

    onUpdateBidOwner: userActions.UpdateBidOwner,
    onRemoveBidOwner: userActions.RemoveBidOwner,

    onGetBidSectionByBidId: userActions.GetBidSectionByBidId,
    onGetBidSectionById: userActions.GetBidSectionById,
    onBidSectionInsert: userActions.BidSectionInsert,
    onBidSectionRemove: userActions.BidSectionRemove,

    onBidStatusUpdate: userActions.BidStatusUpdate,
    onBidSectionUpdate: userActions.BidSectionUpdate,
    onBidSectionShortingUpdate: userActions.BidSectionShortingUpdate,

    onBidUploadDocument: userActions.BidUploadDocument,
    onBidRemoveDocument: userActions.BidRemoveDocument,

    onCreateComment: userActions.CreateComment,
    onGetCommentById: userActions.GetCommentById,

    onBidSelectedStatusUpdate: userActions.BidSelectedStatusUpdate,

    onUpdateSectionTeam: userActions.UpdateSectionTeam,
    onRemoveSectionTeam: userActions.RemoveSectionTeam,
    onUpdateSectionOwner: userActions.UpdateSectionOwner,
    onRemoveSectionOwner: userActions.RemoveSectionOwner,

    onGetResponseRecord: userActions.GetResponseRecord,
    onDesignPreviewGenratepdf: userActions.DesignPreviewGenratepdf,
    onDesignPreviewUpdatepdf: userActions.DesignPreviewUpdatepdf,
};
export default connect(mapStateToProps, mapDispatchToProps)(BidResponse);