import React from "react";

// import { Editor } from "@tinymce/tinymce-react";
// const WysiwygEditor = (props) => {
    // return (
        // <Editor
            // initialValue={props.value}
            // init={{
                // height: 50,
                // menubar: false,
				// autosave_interval: '20s',
				// apiKey: "165cgzurjzlobcghhp4y6u6olqbo9rnqfw1wpdolubkjv7jd",
                // plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
                // toolbar: "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
            // }}
            // onSetContent={(value, editor) => {
				// console.log("value----------",value)
				// console.log("editor----------",editor)
			// }}
        // />
    // );
// };

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import FroalaEditor from "react-froala-wysiwyg";
const WysiwygEditor = (props) => {
    return (
        <FroalaEditor
            tag="textarea"
            config={{
                placeholderText: props.placeholder ? props.placeholder : "Something write",
                events: {
                    click: function (event, clickEvent) {
                        props.onClick(event);
                    },
                },
            }}
            model={props.value}
            onModelChange={(event) => props.onChange(event)}
        />
    );
};

export default WysiwygEditor;