import React from "react";

const UserDetails = (props) => {
    return (
        <tr id={props.invited_user.invite_id} key={props.invited_user.invite_id}>
            <td>
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                        <img title="user" alt="user" src={props.invited_user.user && props.invited_user.user.avatar ? props.invited_user.user.avatar : "assets/images/profile_dummy.jpg"} />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                        <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">{props.invited_user.user ? props.invited_user.user.first_name + " " + props.invited_user.user.last_name : props.invited_user.invite_email}</span>
                        <span className="text-muted text-hover-primary d-block fs-7">{props.invited_user.email}</span>
                    </div>
                </div>
            </td>
            <td>{props.user_roles ? props.user_roles[props.invited_user.role_id][0]["user_role"] : " -"}</td>
            <td>Today</td>
            <td>Access</td>
            <td>
                <span className={props.invited_user.invite_status === "Invited" ? "text-dark-blue" : ""}>{props.invited_user.invite_status}</span>
            </td>
            <td className="text-end">
                <span className="btn" onClick={(event) => props.EditInviteUser(props.invited_user)}>
                    <span className="svg-icon svg-icon-3">
                        <img src="assets/images/icons/edit.svg" title="edit" alt="edit" />
                    </span>
                </span>
                <span className="btn" onClick={(event) => props.RemoveInviteUser(props.invited_user)}>
                    <span className="svg-icon svg-icon-3">
                        <img src="assets/images/icons/delete.svg" title="delete" alt="delete" />
                    </span>
                </span>
            </td>
        </tr>
    );
};
export default UserDetails;