import React from "react";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Redirect } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { AsyncTypeahead, Token } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AddClient from "./AddClient";

import * as userActions from "../../../actions/index";
import Loader from "../../../components/Utility/Loader";
import LoginFooter from "../../../components/LoginFooter";
import LoginHeader from "../../../components/LoginHeader";
import LoginSidebar from "../../../components/LoginSidebar";
import Validations from "../../../components/Utility/Validations";

class BidCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bidcreate_form: {
                bid_title: {
                    type: "text",
                    label: "Bid name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                client_id: {
                    type: "select",
                    label: "Client Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [{ opk: "role_opk_0", name: "Select Client", value: "" }],
                },
                reference_number: {
                    type: "text",
                    label: "Bid reference ID",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                submission_date: {
                    type: "datepicker",
                    label: "Submission due date",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                submission_timezone: {
                    type: "select",
                    label: "Submission timezone",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                    options: [
                        { opk: "submission_timezone_0", name: "Select Submission timezone", value: "" },
                        { opk: "submission_timezone_1", name: "(UTC +08:00) Australia/Perth", value: "Australia/Perth" },
                        { opk: "submission_timezone_2", name: "(UTC +10:00) Australia/Melbourne, Sydney", value: "Australia/Melbourne, Sydney" },
                    ],
                },
                bid_value: {
                    type: "datepciker",
                    label: "Estimated value ($)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                tags: {
                    type: "typeahead",
                    label: "Tags",
                    value: [],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },
                bidtype_id: {
                    type: "select",
                    label: "Type of bid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [{ opk: "role_opk_0", name: "Select from dropdown", value: "" }],
                },
                bid_owners: {
                    type: "typeahead",
                    label: "Owners",
                    value: [],
                    options: [],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                bid_teams: {
                    type: "typeahead",
                    label: "Team mates",
                    value: [],
                    options: [],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
            },
            loader: false,
            addclient_modal: false,
            clent_name: "Select Client",
        };
    }

    async componentDidMount() {
        this.GetTags();
        this.GetClients();
        this.GetBidTypes();
        this.GetCompanyUsers();
    }

    GetTags() {
        this.props.onGetTags().then((response) => {
            if (response.success === true) {
                let all_tags = response.data;
                const update_bidcreate_form = { ...this.state.bidcreate_form };
                let tag_option = [];
                for (let key in all_tags) {
                    tag_option.push({
                        opk: all_tags[key].bidtag_id,
                        name: all_tags[key].tag_name,
                        value: all_tags[key].bidtag_id,
                    });
                }
                update_bidcreate_form.tags.options = tag_option;
                this.setState({ bidcreate_form: update_bidcreate_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    GetClients() {
        this.props.onGetClients().then((response) => {
            if (response.success === true) {
                let all_clients = response.data;
                const update_bidcreate_form = { ...this.state.bidcreate_form };
                let client_option = [{ opk: "role_opk_0", name: "Select Client", value: "" }];
                for (let key in all_clients) {
                    client_option.push({
                        opk: all_clients[key].client_id,
                        name: all_clients[key].client_name,
                        value: all_clients[key].client_id,
                    });
                }
                update_bidcreate_form.client_id.options = client_option;
                this.setState({ bidcreate_form: update_bidcreate_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    GetBidTypes() {
        this.props.onGetBidTypes().then((response) => {
            if (response.success === true) {
                let all_bidtype = response.data;
                const update_bidcreate_form = { ...this.state.bidcreate_form };
                let bidtype_option = [{ opk: "role_opk_0", name: "Select from dropdown", value: "" }];
                for (let key in all_bidtype) {
                    bidtype_option.push({
                        opk: all_bidtype[key].bidtype_id,
                        name: all_bidtype[key].title,
                        value: all_bidtype[key].bidtype_id,
                    });
                }
                update_bidcreate_form.bidtype_id.options = bidtype_option;
                this.setState({ bidcreate_form: update_bidcreate_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    GetCompanyUsers() {
        this.props.onGetCompanyUsers().then((response) => {
            if (response.success === true) {
                let bid_teams_option = [];
                let bid_owners_option = [];
                let company_users = response.data;
                const update_bidcreate_form = { ...this.state.bidcreate_form };
                for (let key in company_users) {
                    if (company_users[key].role.user_role === "Account owner" || company_users[key].role.user_role === "Bid Manager") {
                        bid_owners_option.push({
                            opk: company_users[key].user_id,
                            first_name: company_users[key].first_name,
                            last_name: company_users[key].last_name,
                            name: company_users[key].first_name + " " + company_users[key].last_name,
                            value: company_users[key].user_id,
                        });
                    }

                    bid_teams_option.push({
                        opk: company_users[key].user_id,
                        first_name: company_users[key].first_name,
                        last_name: company_users[key].last_name,
                        name: company_users[key].first_name + " " + company_users[key].last_name,
                        value: company_users[key].user_id,
                    });
                }
                update_bidcreate_form.bid_teams.options = bid_teams_option;
                update_bidcreate_form.bid_owners.options = bid_owners_option;
                this.setState({ bidcreate_form: update_bidcreate_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    AddNewClient = (event) => {
        event.preventDefault();
        this.setState({ addclient_modal: true });
    };

    CreateClientSubmit(form_data) {
        this.props.onCreateClient(form_data).then((response) => {
            if (response.success === true) {
                this.GetClients();
                this.AddUsersSuccessEvent.AddNewClientReload();
                this.setState({ addclient_modal: false });
                notify.show("Client created successfully. Please select from dropdown", "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    inputChangeHandlerClient(id, name, identifier) {
        let update_bidcreate_form = { ...this.state.bidcreate_form };
        update_bidcreate_form.client_id.value = id;
        this.setState({
            bidcreate_form: update_bidcreate_form,
            clent_name: name,
        });
    }
    inputChangeHandler(event, identifier) {
        const update_bidcreate_form = { ...this.state.bidcreate_form };
        const form_element = { ...update_bidcreate_form[identifier] };

        if (form_element.type === "datepicker") {
            form_element.value = event;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_bidcreate_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_bidcreate_form) {
            is_form_valid = update_bidcreate_form[identifier].valid && is_form_valid;
        }

        this.setState({
            bidcreate_form: update_bidcreate_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_bidcreate_form = this.state.bidcreate_form;
        for (let key in update_bidcreate_form) {
            let form_element = update_bidcreate_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_bidcreate_form[key] = form_element;
        }
        this.setState({ bidcreate_form: update_bidcreate_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.bidcreate_form) {
                if (this.state.bidcreate_form[key].type === "typeahead") {
                    if (key === "bid_owners" || key === "bid_teams") {
                        let form_element = this.state.bidcreate_form[key].value;
                        form_data[key] = form_element.map((t) => t.value);
                    } else {
                        let typeahead_value = this.state.bidcreate_form[key].value;
                        form_data[key] = typeahead_value.map((t) => t.name);
                    }
                } else {
                    form_data[key] = this.state.bidcreate_form[key].value;
                }
            }
            this.props.onCreateBid(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/bid-overview",
                        });
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { bidcreate_form } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                {this.state.addclient_modal && (
                    <AddClient
                        addclient_modal={this.state.addclient_modal}
                        ref={(ref) => (this.AddUsersSuccessEvent = ref)}
                        CreateClientSubmit={(event) => this.CreateClientSubmit(event)}
                        addclient_modal_action={(modal_action) => {
                            this.setState({
                                addclient_modal: modal_action,
                            });
                        }}
                    />
                )}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Bid creation" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card mb-7 bg-transparent">
                                                    <div className="card-header border-0 p-0">
                                                        <div className="card mb-2 bg-transparent">
                                                            <h3 className="card-title align-items-start flex-column m-0">
                                                                <span className="card-main-title card-label fw-bolder fs-2x mb-1">Create a new bid</span>
                                                                <span className="fw-normal text-dark-muted fs-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Placerat mauris tristiq.</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form className="form w-100" onSubmit={this.SubmitHandler}>
                                                    <div className="card mb-5 mb-xl-8">
                                                        <div className="card-header border-0 pt-5 mb-4">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <img src="assets/images/icons/bid-info.svg" title="all_bids" alt="all_bids" />
                                                                <h3 className="card-title align-items-start flex-column ms-4">
                                                                    <span className="card-label fw-bold-700 fs-2x mb-1 color1">Bid Information</span>
                                                                    <span className="fw-normal text-dark-muted fs-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et lacus mauris.</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="row mb-4">
                                                                <div className="col-lg-12 fv-row">
                                                                    <label className="form-label fs-6">{bidcreate_form.bid_title.label}</label>
                                                                    <input
                                                                        type={bidcreate_form.bid_title.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={bidcreate_form.bid_title.placeholder}
                                                                        value={bidcreate_form.bid_title.value ? bidcreate_form.bid_title.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "bid_title")}
                                                                        onBlur={(event) => {
                                                                            bidcreate_form.bid_title.onBlur_out = true;
                                                                            this.setState({
                                                                                bidcreate_form: bidcreate_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!bidcreate_form.bid_title.valid && bidcreate_form.bid_title.onBlur_out ? <div className="error field-error">{bidcreate_form.bid_title.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-sm-6 col-lg-6 mb-4 fv-row">
                                                                    <label className="form-label fs-6">{bidcreate_form.client_id.label}</label>
                                                                    <DropdownButton className="clientdorpdown" title={this.state.clent_name ? this.state.clent_name : ""}>
                                                                        {bidcreate_form.client_id.options.map((option) => (
                                                                            <Dropdown.Item onClick={(event) => this.inputChangeHandlerClient(option.value, option.name, "client_id")} key={option.opk} eventKey={option.value}>
                                                                                {option.name}
                                                                            </Dropdown.Item>
                                                                        ))}

                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item eventKey="0" onClick={(event) => this.AddNewClient(event)}>
                                                                            Add new client
                                                                        </Dropdown.Item>
                                                                    </DropdownButton>
                                                                    {!bidcreate_form.client_id.valid && bidcreate_form.client_id.onBlur_out ? <div className="error field-error">{bidcreate_form.client_id.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-sm-6 col-lg-6 mb-4 fv-row">
                                                                    <label className="form-label fs-6">{bidcreate_form.reference_number.label}</label>
                                                                    <input
                                                                        type={bidcreate_form.reference_number.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={bidcreate_form.reference_number.placeholder}
                                                                        value={bidcreate_form.reference_number.value ? bidcreate_form.reference_number.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "reference_number")}
                                                                        onBlur={(event) => {
                                                                            bidcreate_form.reference_number.onBlur_out = true;
                                                                            this.setState({
                                                                                bidcreate_form: bidcreate_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!bidcreate_form.reference_number.valid && bidcreate_form.reference_number.onBlur_out ? (
                                                                        <div className="error field-error">{bidcreate_form.reference_number.error_msg}</div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-sm-6 col-lg-6 mb-4 fv-row position-relative">
                                                                    <label className="form-label fs-6">{bidcreate_form.submission_date.label}</label>
                                                                    <DatePicker
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        className="form-control date-picker-outer"
                                                                        dateFormat="MM/dd/yyyy"
                                                                        selected={bidcreate_form.submission_date.value}
                                                                        placeholder={bidcreate_form.submission_date.placeholder}
                                                                        onChange={(event) => this.inputChangeHandler(event, "submission_date")}
                                                                        onBlur={(event) => {
                                                                            bidcreate_form.submission_date.onBlur_out = true;
                                                                            this.setState({
                                                                                bidcreate_form: bidcreate_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!bidcreate_form.submission_date.valid && bidcreate_form.submission_date.onBlur_out ? (
                                                                        <div className="error field-error">{bidcreate_form.submission_date.error_msg}</div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                                <div className="col-sm-6 col-lg-6 mb-4 fv-row">
                                                                    <label className="form-label fs-6">{bidcreate_form.submission_timezone.label}</label>
                                                                    <select
                                                                        className="form-select form-select-solid"
                                                                        value={bidcreate_form.submission_timezone.value}
                                                                        onChange={(event) => this.inputChangeHandler(event, "submission_timezone")}
                                                                    >
                                                                        {bidcreate_form.submission_timezone.options.map((option) => (
                                                                            <option value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!bidcreate_form.submission_timezone.valid && bidcreate_form.submission_timezone.onBlur_out ? (
                                                                        <div className="error field-error">{bidcreate_form.submission_timezone.error_msg}</div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-lg-12 fv-row">
                                                                    <label className="form-label fs-6">{bidcreate_form.bid_value.label}</label>
                                                                    <input
                                                                        type={bidcreate_form.bid_value.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={bidcreate_form.bid_value.placeholder}
                                                                        value={bidcreate_form.bid_value.value ? bidcreate_form.bid_value.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "bid_value")}
                                                                        onBlur={(event) => {
                                                                            bidcreate_form.bid_value.onBlur_out = true;
                                                                            this.setState({
                                                                                bidcreate_form: bidcreate_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!bidcreate_form.bid_value.valid && bidcreate_form.bid_value.onBlur_out ? <div className="error field-error">{bidcreate_form.bid_value.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-lg-12 fv-row">
                                                                    <label className="form-label fs-6">{bidcreate_form.tags.label}</label>
                                                                    <AsyncTypeahead
                                                                        multiple
                                                                        allowNew
                                                                        id="bidtags"
                                                                        labelKey="name"
                                                                        isLoading={false}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        options={bidcreate_form.tags.options}
                                                                        selected={bidcreate_form.tags.value}
                                                                        onSearch={(query) => {}}
                                                                        onChange={(selected) => {
                                                                            const update_bidcreate_form = { ...this.state.bidcreate_form };
                                                                            const form_element = { ...update_bidcreate_form["tags"] };
                                                                            form_element.value = selected;
                                                                            update_bidcreate_form["tags"] = form_element;
                                                                            this.setState({
                                                                                bidcreate_form: update_bidcreate_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card mb-5 mb-xl-8">
                                                        <div className="card-header border-0 pt-5 mb-4">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <img src="assets/images/icons/bid-type.svg" title="all_bids" alt="all_bids" />
                                                                <h3 className="card-title align-items-start flex-column ms-4">
                                                                    <span className="card-label fw-bolder fs-2x mb-1 color1">Type of Bid</span>
                                                                    <span className="fw-normal text-dark-muted fs-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et lacus mauris.</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="row mb-4">
                                                                <div className="col-lg-12 fv-row">
                                                                    <label className="form-label fs-6">{bidcreate_form.bidtype_id.label} <i className="icon-exclamation ms-1"></i></label>
                                                                    <select className="form-select form-select-solid" value={bidcreate_form.bidtype_id.value} onChange={(event) => this.inputChangeHandler(event, "bidtype_id")}>
                                                                        {bidcreate_form.bidtype_id.options.map((option) => (
                                                                            <option value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!bidcreate_form.bidtype_id.valid && bidcreate_form.bidtype_id.onBlur_out ? <div className="error field-error">{bidcreate_form.bidtype_id.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card mb-5 mb-xl-8 bid-team-outer">
                                                        <div className="card-header border-0 pt-5 mb-4">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <img src="assets/images/icons/bid-team.svg" title="all_bids" alt="all_bids" />
                                                                <h3 className="card-title align-items-start flex-column ms-4">
                                                                    <span className="card-label fw-bolder fs-2x mb-1 color1">Bid Team</span>
                                                                    <span className="fw-normal text-dark-muted fs-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et lacus mauris.</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-lg-6 mb-4 fv-row">
                                                                    <label className="form-label fs-6">{bidcreate_form.bid_owners.label}</label>
                                                                    <AsyncTypeahead
                                                                        multiple
                                                                        id="bidbid_owners"
                                                                        labelKey="name"
                                                                        isLoading={false}
                                                                        className="react-bootstrap-typeahead"
                                                                        onSearch={(query) => {}}
                                                                        options={bidcreate_form.bid_owners.options}
                                                                        selected={bidcreate_form.bid_owners.value}
                                                                        onChange={(selected) => {
                                                                            const update_bidcreate_form = { ...this.state.bidcreate_form };
                                                                            const form_element = { ...update_bidcreate_form["bid_owners"] };
                                                                            form_element.value = selected;
                                                                            update_bidcreate_form["bid_owners"] = form_element;
                                                                            this.setState({
                                                                                bidcreate_form: update_bidcreate_form,
                                                                            });
                                                                        }}
                                                                        renderToken={(option, props, index) => (
                                                                            <Token key={index} onRemove={props.onRemove} option={option}>
                                                                                {option.first_name.slice(0, 1) + option.last_name.slice(0, 1)}
                                                                            </Token>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6 col-lg-6 mb-4 fv-row">
                                                                    <label className="form-label fs-6">{bidcreate_form.bid_teams.label}</label>
                                                                    <AsyncTypeahead
                                                                        multiple
                                                                        id="bidbid_teams"
                                                                        labelKey="name"
                                                                        isLoading={false}
                                                                        className="react-bootstrap-typeahead"
                                                                        options={bidcreate_form.bid_teams.options}
                                                                        selected={bidcreate_form.bid_teams.value}
                                                                        onSearch={(query) => {}}
                                                                        onChange={(selected) => {
                                                                            const update_bidcreate_form = { ...this.state.bidcreate_form };
                                                                            const form_element = { ...update_bidcreate_form["bid_teams"] };
                                                                            form_element.value = selected;
                                                                            update_bidcreate_form["bid_teams"] = form_element;
                                                                            this.setState({
                                                                                bidcreate_form: update_bidcreate_form,
                                                                            });
                                                                        }}
                                                                        renderToken={(option, props, index) => (
                                                                            <Token key={index} onRemove={props.onRemove} option={option}>
                                                                                {option.first_name.slice(0, 1) + option.last_name.slice(0, 1)}
                                                                            </Token>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-5 text-end">
                                                        <button type="submit" className="btn rounded-50 btn-lg btn-danger ">
                                                            <span className="indicator-label">Continue </span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetTags: userActions.GetTags,
    onGetClients: userActions.GetClients,
    onGetBidTypes: userActions.GetBidTypes,
    onGetCompanyUsers: userActions.GetCompanyUsers,

    onCreateBid: userActions.CreateBid,
    onCreateClient: userActions.CreateClient,
};
export default connect(mapStateToProps, mapDispatchToProps)(BidCreate);