import React from "react";
import { Modal } from "react-bootstrap";
import Validations from "../../components/Utility/Validations";

class RenameContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addfolder_form: {
                content_rename: {
                    label: "Rename this content",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
            },
            contentrename_modal: false,
        };
    }

    inputChangeHandler(event, identifier) {
        const update_addfolder_form = { ...this.state.addfolder_form };
        const form_element = { ...update_addfolder_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_addfolder_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_addfolder_form) {
            is_form_valid = update_addfolder_form[identifier].valid && is_form_valid;
        }

        this.setState({
            addfolder_form: update_addfolder_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_addfolder_form = this.state.addfolder_form;
        for (let key in update_addfolder_form) {
            let form_element = update_addfolder_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_addfolder_form[key] = form_element;
        }
        this.setState({ addfolder_form: update_addfolder_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.addfolder_form) {
                form_data[key] = this.state.addfolder_form[key].value;
            }
            this.props.CreateClientSubmit(form_data);
        }
    };

    render() {
        const { addfolder_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="contentrename_modal"
                    show={this.props.contentrename_modal}
                    onHide={() => {
                        this.props.contentrename_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Rename</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100">
                            <div className="row">
                                <div className="col-lg-12 fv-row mb-4">
                                    <label className="form-label fs-6">{addfolder_form.content_rename.label}</label>
                                    <input
                                        type={addfolder_form.content_rename.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={addfolder_form.content_rename.placeholder}
                                        value={addfolder_form.content_rename.value ? addfolder_form.content_rename.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "content_rename")}
                                        onBlur={(event) => {
                                            addfolder_form.content_rename.onBlur_out = true;
                                            this.setState({
                                                addfolder_form: addfolder_form,
                                            });
                                        }}
                                    />
                                    {!addfolder_form.content_rename.valid && addfolder_form.content_rename.onBlur_out ? <div className="error field-error">{addfolder_form.content_rename.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="d-flex align-items-center my-2 justify-content-end">
                                <button type="submit" className="btn btn-danger rounded-50 w-200">
                                    Ok
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default RenameContent;