export const UpdatePersonalAvatar_START = "UpdatePersonalAvatar_START";
export const UpdatePersonalAvatar_SUCCESS = "UpdatePersonalAvatar_SUCCESS";
export const UpdatePersonalAvatar_FAIL = "UpdatePersonalAvatar_FAIL";

export const UpdatePersonal_START = "UpdatePersonal_START";
export const UpdatePersonal_SUCCESS = "UpdatePersonal_SUCCESS";
export const UpdatePersonal_FAIL = "UpdatePersonal_FAIL";

export const RemoveProfileImage_START = "RemoveProfileImage_START";
export const RemoveProfileImage_SUCCESS = "RemoveProfileImage_SUCCESS";
export const RemoveProfileImage_FAIL = "RemoveProfileImage_FAIL";

export const UpdatePassword_START = "UpdatePassword_START";
export const UpdatePassword_SUCCESS = "UpdatePassword_SUCCESS";
export const UpdatePassword_FAIL = "UpdatePassword_FAIL";

export const CompanyUpdateAvatar_START = "CompanyUpdateAvatar_START";
export const CompanyUpdateAvatar_SUCCESS = "CompanyUpdateAvatar_SUCCESS";
export const CompanyUpdateAvatar_FAIL = "CompanyUpdateAvatar_FAIL";

export const CompanyUpdate_START = "CompanyUpdate_START";
export const CompanyUpdate_SUCCESS = "CompanyUpdate_SUCCESS";
export const CompanyUpdate_FAIL = "CompanyUpdate_FAIL";

export const RemoveCompanyLogo_START = "RemoveCompanyLogo_START";
export const RemoveCompanyLogo_SUCCESS = "RemoveCompanyLogo_SUCCESS";
export const RemoveCompanyLogo_FAIL = "RemoveCompanyLogo_FAIL";

export const GetInviteUsers_START = "GetInviteUsers_START";
export const GetInviteUsers_SUCCESS = "GetInviteUsers_SUCCESS";
export const GetInviteUsers_FAIL = "GetInviteUsers_FAIL";

export const InviteUser_START = "InviteUser_START";
export const InviteUser_SUCCESS = "InviteUser_SUCCESS";
export const InviteUser_FAIL = "InviteUser_FAIL";

export const UpdateInviteUser_START = "UpdateInviteUser_START";
export const UpdateInviteUser_SUCCESS = "UpdateInviteUser_SUCCESS";
export const UpdateInviteUser_FAIL = "UpdateInviteUser_FAIL";

export const ReInviteUser_START = "ReInviteUser_START";
export const ReInviteUser_SUCCESS = "ReInviteUser_SUCCESS";
export const ReInviteUser_FAIL = "ReInviteUser_FAIL";

export const RemoveInviteUser_START = "RemoveInviteUser_START";
export const RemoveInviteUser_SUCCESS = "RemoveInviteUser_SUCCESS";
export const RemoveInviteUser_FAIL = "RemoveInviteUser_FAIL";

export const GetCompanyUsers_START = "GetCompanyUsers_START";
export const GetCompanyUsers_SUCCESS = "GetCompanyUsers_SUCCESS";
export const GetCompanyUsers_FAIL = "GetCompanyUsers_FAIL";

/*** Bid Action Type ***/
export const GetBids_START = "GetBids_START";
export const GetBids_SUCCESS = "GetBids_SUCCESS";
export const GetBids_FAIL = "GetBids_FAIL";

export const GetCountByStatus_START = "GetCountByStatus_START";
export const GetCountByStatus_SUCCESS = "GetCountByStatus_SUCCESS";
export const GetCountByStatus_FAIL = "GetCountByStatus_FAIL";

export const GetTags_START = "GetTags_START";
export const GetTags_SUCCESS = "GetTags_SUCCESS";
export const GetTags_FAIL = "GetTags_FAIL";

export const GetBidTypes_START = "GetBidTypes_START";
export const GetBidTypes_SUCCESS = "GetBidTypes_SUCCESS";
export const GetBidTypes_FAIL = "GetBidTypes_FAIL";

export const CreateBid_START = "CreateBid_START";
export const CreateBid_SUCCESS = "CreateBid_SUCCESS";
export const CreateBid_FAIL = "CreateBid_FAIL";

export const UpdateBid_START = "UpdateBid_START";
export const UpdateBid_SUCCESS = "UpdateBid_SUCCESS";
export const UpdateBid_FAIL = "UpdateBid_FAIL";

export const DeleteBid_START = "DeleteBid_START";
export const DeleteBid_SUCCESS = "DeleteBid_SUCCESS";
export const DeleteBid_FAIL = "DeleteBid_FAIL";

export const GetBidById_START = "GetBidById_START";
export const GetBidById_SUCCESS = "GetBidById_SUCCESS";
export const GetBidById_FAIL = "GetBidById_FAIL";

export const UpdateBidTeam_START = "UpdateBidTeam_START";
export const UpdateBidTeam_SUCCESS = "UpdateBidTeam_SUCCESS";
export const UpdateBidTeam_FAIL = "UpdateBidTeam_FAIL";

export const RemoveBidTeam_START = "RemoveBidTeam_START";
export const RemoveBidTeam_SUCCESS = "RemoveBidTeam_SUCCESS";
export const RemoveBidTeam_FAIL = "RemoveBidTeam_FAIL";

export const UpdateBidOwner_START = "UpdateBidOwner_START";
export const UpdateBidOwner_SUCCESS = "UpdateBidOwner_SUCCESS";
export const UpdateBidOwner_FAIL = "UpdateBidOwner_FAIL";

export const RemoveBidOwner_START = "RemoveBidOwner_START";
export const RemoveBidOwner_SUCCESS = "RemoveBidOwner_SUCCESS";
export const RemoveBidOwner_FAIL = "RemoveBidOwner_FAIL";

export const GetBidtypeContent_START = "GetBidtypeContent_START";
export const GetBidtypeContent_SUCCESS = "GetBidtypeContent_SUCCESS";
export const GetBidtypeContent_FAIL = "GetBidtypeContent_FAIL";

export const GetBidProposal_START = "GetBidProposal_START";
export const GetBidProposal_SUCCESS = "GetBidProposal_SUCCESS";
export const GetBidProposal_FAIL = "GetBidProposal_FAIL";

export const GetBidSectionByBidId_START = "GetBidSectionByBidId_START";
export const GetBidSectionByBidId_SUCCESS = "GetBidSectionByBidId_SUCCESS";
export const GetBidSectionByBidId_FAIL = "GetBidSectionByBidId_FAIL";

export const GetBidSectionById_START = "GetBidSectionById_START";
export const GetBidSectionById_SUCCESS = "GetBidSectionById_SUCCESS";
export const GetBidSectionById_FAIL = "GetBidSectionById_FAIL";

export const BidSectionInsert_START = "BidSectionInsert_START";
export const BidSectionInsert_SUCCESS = "BidSectionInsert_SUCCESS";
export const BidSectionInsert_FAIL = "BidSectionInsert_FAIL";

export const BidSectionRemove_START = "BidSectionRemove_START";
export const BidSectionRemove_SUCCESS = "BidSectionRemove_SUCCESS";
export const BidSectionRemove_FAIL = "BidSectionRemove_FAIL";

export const BidSectionUpdate_START = "BidSectionUpdate_START";
export const BidSectionUpdate_SUCCESS = "BidSectionUpdate_SUCCESS";
export const BidSectionUpdate_FAIL = "BidSectionUpdate_FAIL";

export const BidSectionShortingUpdate_START = "BidSectionShortingUpdate_START";
export const BidSectionShortingUpdate_SUCCESS = "BidSectionShortingUpdate_SUCCESS";
export const BidSectionShortingUpdate_FAIL = "BidSectionShortingUpdate_FAIL";

export const BidUploadDocument_START = "BidUploadDocument_START";
export const BidUploadDocument_SUCCESS = "BidUploadDocument_SUCCESS";
export const BidUploadDocument_FAIL = "BidUploadDocument_FAIL";

export const BidRemoveDocument_START = "BidRemoveDocument_START";
export const BidRemoveDocument_SUCCESS = "BidRemoveDocument_SUCCESS";
export const BidRemoveDocument_FAIL = "BidRemoveDocument_FAIL";

export const BidStatusUpdate_START = "BidStatusUpdate_START";
export const BidStatusUpdate_SUCCESS = "BidStatusUpdate_SUCCESS";
export const BidStatusUpdate_FAIL = "BidStatusUpdate_FAIL";

export const CreateComment_START = "CreateComment_START";
export const CreateComment_SUCCESS = "CreateComment_SUCCESS";
export const CreateComment_FAIL = "CreateComment_FAIL";

export const GetCommentById_START = "GetCommentById_START";
export const GetCommentById_SUCCESS = "GetCommentById_SUCCESS";
export const GetCommentById_FAIL = "GetCommentById_FAIL";

export const BidSelectedStatusUpdate_START = "BidSelectedStatusUpdate_START";
export const BidSelectedStatusUpdate_SUCCESS = "BidSelectedStatusUpdate_SUCCESS";
export const BidSelectedStatusUpdate_FAIL = "BidSelectedStatusUpdate_FAIL";

export const UpdateSectionTeam_START = "UpdateSectionTeam_START";
export const UpdateSectionTeam_SUCCESS = "UpdateSectionTeam_SUCCESS";
export const UpdateSectionTeam_FAIL = "UpdateSectionTeam_FAIL";

export const RemoveSectionTeam_START = "RemoveSectionTeam_START";
export const RemoveSectionTeam_SUCCESS = "RemoveSectionTeam_SUCCESS";
export const RemoveSectionTeam_FAIL = "RemoveSectionTeam_FAIL";

export const UpdateSectionOwner_START = "UpdateSectionOwner_START";
export const UpdateSectionOwner_SUCCESS = "UpdateSectionOwner_SUCCESS";
export const UpdateSectionOwner_FAIL = "UpdateSectionOwner_FAIL";

export const RemoveSectionOwner_START = "RemoveSectionOwner_START";
export const RemoveSectionOwner_SUCCESS = "RemoveSectionOwner_SUCCESS";
export const RemoveSectionOwner_FAIL = "RemoveSectionOwner_FAIL";

export const GetResponseRecord_START = "GetResponseRecord_START";
export const GetResponseRecord_SUCCESS = "GetResponseRecord_SUCCESS";
export const GetResponseRecord_FAIL = "GetResponseRecord_FAIL";

export const DesignPreviewGenratepdf_START = "DesignPreviewGenratepdf_START";
export const DesignPreviewGenratepdf_SUCCESS = "DesignPreviewGenratepdf_SUCCESS";
export const DesignPreviewGenratepdf_FAIL = "DesignPreviewGenratepdf_FAIL";

export const DesignPreviewUpdatepdf_START = "DesignPreviewUpdatepdf_START";
export const DesignPreviewUpdatepdf_SUCCESS = "DesignPreviewUpdatepdf_SUCCESS";
export const DesignPreviewUpdatepdf_FAIL = "DesignPreviewUpdatepdf_FAIL";

/*** Client Action Type ***/
export const GetClients_START = "GetClients_START";
export const GetClients_SUCCESS = "GetClients_SUCCESS";
export const GetClients_FAIL = "GetClients_FAIL";

export const CreateClient_START = "CreateClient_START";
export const CreateClient_SUCCESS = "CreateClient_SUCCESS";
export const CreateClient_FAIL = "CreateClient_FAIL";

export const UpdateClient_START = "UpdateClient_START";
export const UpdateClient_SUCCESS = "UpdateClient_SUCCESS";
export const UpdateClient_FAIL = "UpdateClient_FAIL";

export const DeleteClient_START = "DeleteClient_START";
export const DeleteClient_SUCCESS = "DeleteClient_SUCCESS";
export const DeleteClient_FAIL = "DeleteClient_FAIL";

export const GetClientById_START = "GetClientById_START";
export const GetClientById_SUCCESS = "GetClientById_SUCCESS";
export const GetClientById_FAIL = "GetClientById_FAIL";

/*** Library Action Type ***/
export const GetLibraryWithContents_START = "GetLibraryWithContents_START";
export const GetLibraryWithContents_SUCCESS = "GetLibraryWithContents_SUCCESS";
export const GetLibraryWithContents_FAIL = "GetLibraryWithContents_FAIL";