import React from "react";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import * as userActions from "../../../actions/index";
import AddAnotherDocument from "./AddAnotherDocument";
import WysiwygEditor from "../../../components/Utility/WysiwygEditor";
class BidCreateTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bid_documents: [],
            response_content: [],
        };
    }

    componentDidMount() {
        let bid_response_form = this.props.bid_response_form;
        for (let key in bid_response_form.response_content) {
            bid_response_form.response_content[key].title_editable = false;
        }
        this.setState({
            bid_documents: [],
            bid_detail: this.props.bid_detail,
            response_content: bid_response_form.response_content,
            bid_section_selected: this.props.bid_section_selected,
        });
    }

    BidCreateTabReload() {
        let bid_response_form = this.props.bid_response_form;
        for (let key in bid_response_form.response_content) {
            bid_response_form.response_content[key].title_editable = false;
        }
        this.setState({
            // bid_documents: [],
            bid_detail: this.props.bid_detail,
            response_content: bid_response_form.response_content,
            bid_section_selected: this.props.bid_section_selected,
        });
    }

    inputChangeHandler(event, identifier) {
        let response_content = this.state.response_content;
        response_content[identifier].description = event;
        this.setState({ response_content: response_content });
        this.props.BidSectionUpdate(response_content[identifier]);
    }

    onFileChange(event, identifier) {
        event.preventDefault();
        this.props.BidUploadDocument(event.target.files[0]);
    }

    handleOnDragEnd = (result) => {
        if (result.destination) {
            const response_contents = Array.from(this.state.response_content);
            const [reorderedItem] = response_contents.splice(result.source.index, 1);
            response_contents.splice(result.destination.index, 0, reorderedItem);
            for (let key in response_contents) {
                response_contents[key].sort_order = key;
            }
            this.props.BidSectionShortingUpdate(response_contents);
            this.setState({ response_content: response_contents });
        }
    };

    CheckBidSectionUserAccess = (bid_content) => {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        if (user_info.user.role.user_role !== "Account owner" && user_info.user.role.user_role !== "Bid Manager") {
            let section_users = [];
            if (bid_content.response_user) {
                if (bid_content.response_user.section_teams !== null) {
                    let section_teams = bid_content.response_user.section_teams;
                    for (let section_team_key in section_teams) {
                        section_users.push(section_teams[section_team_key].user_id);
                    }
                }

                if (bid_content.response_user.section_owners !== null) {
                    let section_owners = bid_content.response_user.section_owners;
                    for (let section_owner_key in section_owners) {
                        section_users.push(section_owners[section_owner_key].user_id);
                    }
                }
            }
            if (user_info.user.role.user_role === "Guest") {
                return section_users.includes(user_info.user.user_id) ? "" : "displaynone ";
            } else {
                return section_users.includes(user_info.user.user_id) ? "" : "disable_overlay ";
            }
        } else {
            return user_info.user.role.user_role.replace(" ", "_").toLowerCase();
        }
    };

    render() {
        const { bid_detail, response_content, bid_documents } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-11 col-xl-10">
                        <div className="response_content_section" ref={this.props.section_select_ref}>
                            <DragDropContext onDragEnd={(event) => this.handleOnDragEnd(event)}>
                                <Droppable droppableId="response_content">
                                    {(provided) => (
                                        <div className="response_content" {...provided.droppableProps} ref={provided.innerRef}>
                                            {response_content &&
                                                response_content.map((bid_content, bid_content_key) => {
                                                    return (
                                                        <Draggable
                                                            key={"result_" + bid_content.section_id}
                                                            draggableId={"result_" + bid_content.sort_order}
                                                            index={bid_content_key}
                                                            isDragDisabled={localStorage.getItem("bidresponse_bidcreate_sectionDraggable") === "yes" ? false : true}
                                                        >
                                                            {(provided) => (
                                                                <div
                                                                    className={
                                                                        "card mb-3 " +
                                                                        (this.state.bid_section_selected && this.state.bid_section_selected.section_id === bid_content.section_id ? "card_active " : "") +
                                                                        this.CheckBidSectionUserAccess(bid_content)
                                                                    }
                                                                    key={bid_content.section_id}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <div className="card-header card-header-edit border-0 pt-5 mb-5">
                                                                        <h3 className="card-title align-items-start flex-column">
                                                                            {bid_content.title_editable === false ? (
                                                                                <span className="card-label fw-bold-700 fs-2 mb-1 text-dark">
                                                                                    {bid_content.title}
                                                                                    <span
                                                                                        className="btn-icon ms-1"
                                                                                        onClick={(event) => {
                                                                                            for (let key in response_content) {
                                                                                                if (response_content[key].section_id === bid_content.section_id) {
                                                                                                    response_content[key].title_editable = true;
                                                                                                }
                                                                                            }
                                                                                            this.setState({ response_content: response_content });
                                                                                        }}
                                                                                    >
                                                                                        <i className="icon-edit"></i>
                                                                                    </span>
                                                                                </span>
                                                                            ) : (
                                                                                <span className="card-label fw-bolder fs-4 mb-1 text-pink">
                                                                                    <input
                                                                                        type="text"
                                                                                        value={bid_content.title}
                                                                                        placeholder="Search for bid..."
                                                                                        className="form-control form-control-sm edit-input"
                                                                                        onChange={(event) => {
                                                                                            for (let key in response_content) {
                                                                                                if (response_content[key].section_id === bid_content.section_id) {
                                                                                                    response_content[key].title = event.target.value;
                                                                                                }
                                                                                            }
                                                                                            this.setState({ response_content: response_content });
                                                                                            this.props.BidSectionUpdate(response_content[bid_content_key]);
                                                                                        }}
                                                                                    />
                                                                                    <span
                                                                                        className="btn-icon ms-1"
                                                                                        onClick={(event) => {
                                                                                            for (let key in response_content) {
                                                                                                if (response_content[key].section_id === bid_content.section_id) {
                                                                                                    response_content[key].title_editable = false;
                                                                                                }
                                                                                            }
                                                                                            this.setState({ response_content: response_content });
                                                                                        }}
                                                                                    >
                                                                                        <i className="icon-cross-2"></i>
                                                                                    </span>
                                                                                </span>
                                                                            )}
                                                                        </h3>
                                                                        <div className="card-toolbar">
                                                                            <span className="btn-icon">
                                                                                <i className="icon-book"></i>
                                                                            </span>
                                                                            <span className="btn-icon">
                                                                                <i className="icon-move"></i>
                                                                            </span>
                                                                            <span className="btn-icon" onClick={(event) => this.props.BidSectionRemove(event, bid_content)}>
                                                                                <i className="icon-delete" aria-hidden="true"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body pt-0">
                                                                        <div className="row mt-4">
                                                                            <WysiwygEditor
                                                                                value={bid_content.description}
                                                                                onClick={(event) => {
                                                                                    this.props.BidSectionSelected(bid_content_key, bid_content);
                                                                                }}
                                                                                onChange={(event) => this.inputChangeHandler(event, bid_content_key)}
                                                                            />
                                                                        </div>
                                                                        <div className="row mt-4">
                                                                            <div className="col-md-8">
                                                                                <span className="text-gray-400 fs-8 fw-normal">
                                                                                    Last edited at {moment(bid_content.updated_date).format("h:mm a, DD/MM/YYYY")} by{" "}
                                                                                    {bid_content.updated_user ? bid_content.updated_user.first_name + " " + bid_content.updated_user.last_name : ""}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-4 text-right">
                                                                                <span className="text-gray-400 fs-8 fw-normal">
                                                                                    Autosaved{" "}
                                                                                    <span className="timeline-badge">
                                                                                        <i className="fa fa-circle text-success ms-2" aria-hidden="true"></i>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        {localStorage.getItem("bidresponse_insert_section") === "yes" && (
                            <div
                                className="text-gray-400 fs-6 text-pink curser_pointer mb-3"
                                onClick={() => {
                                    this.props.bid_insert_section_action(true);
                                }}
                            >
                                <span className="b-icon-weight insert-txt">
                                    <i className="bi bi-plus text-pink"></i> <p className="m-0 ms-2 fw-bold-600">Insert a new section</p>
                                </span>
                            </div>
                        )}

                        <div className="card mb-3 bid_document_section">
                            <div className="card-header border-bottom-2 pt-5 mb-6">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fs-6 mb-1 text-orange">Returnable documents</span>
                                    <button
                                        className="btn btn-secondary btn-sm rounded-50 mt-2 mb-4"
                                        onClick={(event) => {
                                            let bid_documents = this.state.bid_documents;
                                            bid_documents.push({ id: bid_documents.length + 1 });
                                            this.setState({ bid_documents: bid_documents });
                                        }}
                                    >
                                        {bid_detail && bid_detail.response_media.length !== 0 ? "Add another document" : "Add document"}
                                    </button>
                                </h3>
                                <div className="card-toolbar">
                                    <span className="btn-icon">
                                        <i className="icon-book"></i>
                                    </span>
                                    <span className="btn-icon">
                                        <i className="icon-move"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="add-document-outer">
                                    {bid_documents.length !== 0 &&
                                        bid_documents.map((bid_document) => <AddAnotherDocument key={bid_document.id} bid_document={bid_document} onFileChange={(event, file_type) => this.onFileChange(event, file_type)} />)}
                                </div>
                                <div className="upload-document-outer">
                                    {bid_detail &&
                                        bid_detail.response_media.length !== 0 &&
                                        bid_detail.response_media.map((media) => {
                                            return (
                                                <div className="bid_response_document" key={"bid_document_key_" + media.response_media_id}>
                                                    <div className="row">
                                                        <div className="col-sm-10">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <img src="assets/images/icons/successfully.svg" title="all_bids" alt="all_bids" />
                                                                <div className="card-title align-items-start flex-column ms-4 d-flex ">
                                                                    <span className="success-title card-label fw-bold-700 fs-6 ">{media.media_meta.title}</span>
                                                                    <span className="mt-1 fw-normal text-gray fs-7">
                                                                        {userActions.bytesToSize(media.media_meta.media_size)} {media.media_meta.media_type.split("/").pop().toUpperCase()} | Successfully uploaded
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2 text-end">
                                                            <span
                                                                className="del-btn-icon"
                                                                onClick={(event) => {
                                                                    this.props.BidRemoveDocument(event, media);
                                                                }}
                                                            >
                                                                <i className="icon-delete"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default BidCreateTab;