import React, { useState, useEffect, useCallback } from "react";
import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";

import ClientItems from "./ClientItems";
import * as userActions from "../../actions/index";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

const ClientList = (props) => {
    const dispatch = useDispatch();

    const [clients, setclients] = useState([]);
    const [clientcount, setclientcount] = useState(0);

    const [filterlimit] = useState(5);
    const [filterpage, setfilterpage] = useState(1);
    const [filtersort, setfiltersort] = useState("client_id");
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersearch, setfiltersearch] = useState(null);

    const GetClients = useCallback(() => {
        dispatch(userActions.GetClients(filtersearch, filterpage, filterlimit, filtersort, filterorder)).then((response) => {
            if (response.success === true) {
                setclients(response.data);
                setclientcount(response.count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    useEffect(() => {
        GetClients();
    }, [dispatch, GetClients, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    const ConfirmArchiveClient = (client_detail) => {
        if (window.confirm("Are you sure to delete this Cilent?")) {
            const form_data = {};
            form_data["client_id"] = client_detail.client_id;
            dispatch(userActions.DeleteClient(form_data)).then((response) => {
                if (response.success === true) {
                    GetClients();
                    notify.show(response.message, "success");
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    return (
        <React.Fragment>
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <LoginHeader page_title="Client management" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card mb-5 mb-xl-8 bg-transparent">
                                                <div className="card-header border-0 p-0 mb-10">
                                                    <h3 className="card-title align-items-start flex-column">
                                                        <span className="card-label fw-bolder fs-2x mb-1">Client Overview</span>{" "}
                                                        <span className="mt-1 fw-normal text-dark-muted fs-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Placerat mauris tristiq.</span>
                                                    </h3>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <ClientItems
                                        clients={clients}
                                        filterlimit={filterlimit}
                                        filterpage={filterpage}
                                        clientcount={clientcount}
                                        propsfiltersort={(event) => setfiltersort(event)}
                                        propsfilterpage={(event) => setfilterpage(event)}
                                        propsfilterorder={(event) => setfilterorder(event)}
                                        propsfiltersearch={(event) => setfiltersearch(event)}
                                        ConfirmArchiveClient={(event) => ConfirmArchiveClient(event)}
                                        // ConfirmArchiveClient={(client_detail) => {
                                        // setconfirm_modal(true);
                                        // ConfirmArchiveClient(client_detail);
                                        // }}
                                    />
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default ClientList;