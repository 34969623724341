import React from "react";

const BidStatus = (props) => {
    return (
        <React.Fragment>
            <div className="card-body p-0 users-images">
                <div className="row mb-5">
                    <div className="col-sm-6 col-md-4 col-xl-2 mb-4">
                        <div className="card">
                            <div className="card-header flex-nowrap border-0 list-block-link p-3 curser_pointer" onClick={(event) => props.propsbid_status("all")}>
                                <div className="card-title m-0">
                                    <div className="symbol me-3">
                                        <i className="icon-info text-dark fs-3x"></i>
                                    </div>
                                    <div>
                                        <span className="text-dark m-0">All bids</span> <span className="text-dark fw-bolder">{props.bidstatus_all}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xl-2 mb-4">
                        <div className="card">
                            <div className="card-header flex-nowrap border-0 list-block-link p-3 curser_pointer" onClick={(event) => props.propsbid_status("Won")}>
                                <div className="card-title m-0">
                                    <div className="symbol me-3">
                                        <i className="icon-Won title_vector_won fs-3x"></i>
                                    </div>
                                    <div>
                                        <span className="text-dark m-0">Won</span> <span className="text-dark fw-bolder">{props.bidstatus_won}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xl-2 mb-4">
                        <div className="card">
                            <div className="card-header flex-nowrap border-0 list-block-link p-3 curser_pointer" onClick={(event) => props.propsbid_status("In progress")}>
                                <div className="card-title m-0">
                                    <div className="symbol me-3">
                                        <i className="icon-progress-1 text-dark-blue-light fs-3x"></i>
                                    </div>
                                    <div>
                                        <span className="text-dark m-0">Working</span> <span className="text-dark fw-bolder">{props.bidstatus_inprogress}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xl-2 mb-4">
                        <div className="card">
                            <div className="card-header flex-nowrap border-0 list-block-link p-3 curser_pointer" onClick={(event) => props.propsbid_status("Submitted")}>
                                <div className="card-title m-0">
                                    <div className="symbol me-3">
                                        <i className="icon-submit next-text-color fs-3x"></i>
                                    </div>
                                    <div>
                                        <span className="text-dark m-0">Submitted</span> <span className="text-dark fw-bolder">{props.bidstatus_submitted}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xl-2 mb-4">
                        <div className="card">
                            <div className="card-header flex-nowrap border-0 list-block-link p-3 curser_pointer" onClick={(event) => props.propsbid_status("Withdrawn")}>
                                <div className="card-title m-0">
                                    <div className="symbol me-3">
                                        <i className="icon-Withdrawn text-gray-new fs-3x"></i>
                                    </div>
                                    <div>
                                        <span className="text-dark m-0">Withdrawn</span> <span className="text-dark fw-bolder">{props.bidstatus_withdrawn}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xl-2 mb-4">
                        <div className="card">
                            <div className="card-header flex-nowrap border-0 list-block-link p-3 curser_pointer" onClick={(event) => props.propsbid_status("Lost")}>
                                <div className="card-title m-0">
                                    <div className="symbol me-3">
                                        <i className="icon-cross-1 text-grey-dark fs-3x"></i>
                                    </div>
                                    <div>
                                        <span className="text-dark m-0">Lost</span> <span className="text-dark fw-bolder">{props.bidstatus_lost}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default BidStatus;
