import React from "react";
import Notifications from "react-notify-toast";

import LibraryList from "../../components/Library/LibraryList";
import LibraryReturnableDocument from "../../components/Library/LibraryReturnableDocument";
import CaseStudy from "../../components/Library/CaseStudy";
class Client extends React.Component {
    render() {
        const renderview = () => {
            if (this.props.match.path === "/library") {
                return <LibraryList />;
            }
            if (this.props.match.path === "/returnable-documents") {
                return <LibraryReturnableDocument />;
            }
            if (this.props.match.path === "/case-study") {
                return <CaseStudy />;
            }
        };

        return (
            <React.Fragment>
                <Notifications />
                {renderview()}
            </React.Fragment>
        );
    }
}
export default Client;