import React from "react";
import { Modal } from "react-bootstrap";

import Validations from "../../../components/Utility/Validations";
class AddClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addclient_form: {
                client_name: {
                    label: "Client Name",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                client_email: {
                    label: "Client Email",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },
            },
            addclient_modal: false,
        };
    }

    AddNewClientReload() {
        const update_addclient_form = { ...this.state.addclient_form };
        let form_element = { ...update_addclient_form["client_name"] };
        form_element.value = "";
        update_addclient_form["client_name"] = form_element;

        form_element = { ...update_addclient_form["client_email"] };
        form_element.value = "";
        update_addclient_form["client_email"] = form_element;
        this.setState({
            addclient_form: update_addclient_form,
        });
    }

    inputChangeHandler(event, identifier) {
        const update_addclient_form = { ...this.state.addclient_form };
        const form_element = { ...update_addclient_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_addclient_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_addclient_form) {
            is_form_valid = update_addclient_form[identifier].valid && is_form_valid;
        }

        this.setState({
            addclient_form: update_addclient_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_addclient_form = this.state.addclient_form;
        for (let key in update_addclient_form) {
            let form_element = update_addclient_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_addclient_form[key] = form_element;
        }
        this.setState({ addclient_form: update_addclient_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.addclient_form) {
                form_data[key] = this.state.addclient_form[key].value;
            }
            this.props.CreateClientSubmit(form_data);
        }
    };

    render() {
        const { addclient_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="addclient_modal"
                    show={this.props.addclient_modal}
                    onHide={() => {
                        this.props.addclient_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Client</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row">
                                <div className="col-lg-12 fv-row mb-4">
                                    <label className="form-label fs-6">{addclient_form.client_name.label}</label>
                                    <input
                                        type={addclient_form.client_name.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={addclient_form.client_name.placeholder}
                                        value={addclient_form.client_name.value ? addclient_form.client_name.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "client_name")}
                                        onBlur={(event) => {
                                            addclient_form.client_name.onBlur_out = true;
                                            this.setState({
                                                addclient_form: addclient_form,
                                            });
                                        }}
                                    />
                                    {!addclient_form.client_name.valid && addclient_form.client_name.onBlur_out ? <div className="error field-error">{addclient_form.client_name.error_msg}</div> : ""}
                                </div>
                                <div className="col-lg-12 fv-row mb-4">
                                    <label className="form-label fs-6">{addclient_form.client_email.label}</label>
                                    <input
                                        type={addclient_form.client_email.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={addclient_form.client_email.placeholder}
                                        value={addclient_form.client_email.value ? addclient_form.client_email.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "client_email")}
                                        onBlur={(event) => {
                                            addclient_form.client_email.onBlur_out = true;
                                            this.setState({
                                                addclient_form: addclient_form,
                                            });
                                        }}
                                    />
                                    {!addclient_form.client_email.valid && addclient_form.client_email.onBlur_out ? <div className="error field-error">{addclient_form.client_email.error_msg}</div> : ""}
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary rounded-50 w-200">
                                Create Client
                            </button>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddClient;