import React from "react";
import { Modal } from "react-bootstrap";

class ConfirmDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ConfirmDialog_show: false,
        };
    }

    componentDidMount() {
        this.setState({ ConfirmDialog_show: this.props.confirm_modal });
    }

    ModalActionHide() {
        this.setState({ ConfirmDialog_show: false });
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    className="bid_owner_modal"
                    show={this.state.ConfirmDialog_show}
                    onHide={() => {
                        this.ModalActionHide();
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="m-0">{this.props.body}</p>
                    
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn cancel-butn rounded-50 w-150"
                            onClick={() => {
                                this.ModalActionHide();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary remove-user rounded-50 w-150"
                            onClick={() => {
                                this.props.onConfirm();
                            }}
                        >
                            Save
                        </button>
                    </Modal.Footer>
					</Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default ConfirmDialog;