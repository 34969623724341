import React from "react";
import { SketchPicker } from "react-color";

import Validations from "../../../../components/Utility/Validations";
class BidDesignPreviewRightSideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            designpreviewpdf_form: {
                bid_id: {
                    type: "hidden",
                    label: "Bid ID",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                bidpdf_logo: {
                    type: "file",
                    label: "File",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "assets/images/icons/logo-thumb.svg",
                },
                bidpdf_fonts: {
                    type: "select",
                    label: "Fonts",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                    options: [{ opk: "fonts_opk_0", name: "Pick font pairing", value: "" }],
                },
                bidpdf_color: {
                    type: "sketchpicker",
                    label: "Select colour",
                    value: "#191964",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                bidpdf_additionalsettings: {
                    type: "switch",
                    label: "Additional Settings",
                    value: ["intro_page_pattern", "footer_logo", "footer_number", "footer_address"],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [
                        { opk: "additionalsettings_opk_1", value: "intro_page_pattern", name: "Intro page pattern", isChecked: true },
                        { opk: "additionalsettings_opk_2", value: "footer_logo", name: "Footer logo", isChecked: true },
                        { opk: "additionalsettings_opk_3", value: "footer_number", name: "Footer number", isChecked: true },
                        { opk: "additionalsettings_opk_4", value: "footer_address", name: "Footer Address", isChecked: true },
                    ],
                },
            },
            color_picker: false,
        };
        this.SubmitHandler = this.SubmitHandler.bind(this);
        this.setColorPickerRef = this.setColorPickerRef.bind(this);
        this.ColorPickerClickOutside = this.ColorPickerClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("click", this.ColorPickerClickOutside);
        this.timer = null;
        const bid_detail = this.props.bid_detail;
        const response_record = this.props.response_record;
        let update_designpreviewpdf_form = { ...this.state.designpreviewpdf_form };
        update_designpreviewpdf_form.bid_id.value = bid_detail.bid_id;

        if (response_record) {
            update_designpreviewpdf_form.bidpdf_logo.thumbnail_image = response_record.bidpdf_logo;
            update_designpreviewpdf_form.bidpdf_fonts.value = response_record.bidpdf_fonts;
            update_designpreviewpdf_form.bidpdf_color.value = response_record.bidpdf_color;
            if (response_record.bidpdf_additionalsettings) {
                let option_value = [];
                let bidpdf_additionalsettings = update_designpreviewpdf_form.bidpdf_additionalsettings;
                for (let key in bidpdf_additionalsettings.options) {
                    if (response_record.bidpdf_additionalsettings.includes(bidpdf_additionalsettings.options[key].value)) {
                        bidpdf_additionalsettings.options[key].isChecked = true;
                    } else {
                        bidpdf_additionalsettings.options[key].isChecked = false;
                    }
                    if (bidpdf_additionalsettings.options[key].isChecked === true) {
                        option_value.push(bidpdf_additionalsettings.options[key].value);
                    }
                }
                update_designpreviewpdf_form.bidpdf_additionalsettings.value = option_value;
            }
        }

        this.setState({ designpreviewpdf_form: update_designpreviewpdf_form });
    }

    BidDesignPreviewRightSideBarReload() {
        this.timer = null;
        const bid_detail = this.props.bid_detail;
        const response_record = this.props.response_record;
        let update_designpreviewpdf_form = { ...this.state.designpreviewpdf_form };
        update_designpreviewpdf_form.bid_id.value = bid_detail.bid_id;

        if (response_record) {
            update_designpreviewpdf_form.bidpdf_logo.thumbnail_image = response_record.bidpdf_logo;
            update_designpreviewpdf_form.bidpdf_fonts.value = response_record.bidpdf_fonts;
            update_designpreviewpdf_form.bidpdf_color.value = response_record.bidpdf_color;
            if (response_record.bidpdf_additionalsettings) {
                let option_value = [];
                let bidpdf_additionalsettings = update_designpreviewpdf_form.bidpdf_additionalsettings;
                for (let key in bidpdf_additionalsettings.options) {
                    if (response_record.bidpdf_additionalsettings.includes(bidpdf_additionalsettings.options[key].value)) {
                        bidpdf_additionalsettings.options[key].isChecked = true;
                    } else {
                        bidpdf_additionalsettings.options[key].isChecked = false;
                    }
                    if (bidpdf_additionalsettings.options[key].isChecked === true) {
                        option_value.push(bidpdf_additionalsettings.options[key].value);
                    }
                }
                update_designpreviewpdf_form.bidpdf_additionalsettings.value = option_value;
            }
        }

        this.setState({ designpreviewpdf_form: update_designpreviewpdf_form });
    }

    onFileChange(event, identifier) {
        event.preventDefault();

        const update_designpreviewpdf_form = { ...this.state.designpreviewpdf_form };
        const form_element = { ...update_designpreviewpdf_form[identifier] };
        form_element.value = event.target.files[0];
        update_designpreviewpdf_form[identifier] = form_element;
        this.setState(
            {
                designpreviewpdf_form: update_designpreviewpdf_form,
            },
            function () {
                this.SubmitHandler();
            }
        );
    }

    inputChangeHandler(event, identifier) {
        clearTimeout(this.timer);
        const update_designpreviewpdf_form = { ...this.state.designpreviewpdf_form };
        const form_element = { ...update_designpreviewpdf_form[identifier] };

        if (form_element.type === "switch") {
            let option_value = [];
            for (let key in form_element.options) {
                if (form_element.options[key].value === event.target.value) {
                    form_element.options[key].isChecked = event.target.checked;
                }
                if (form_element.options[key].isChecked === true) {
                    option_value.push(form_element.options[key].value);
                }
            }
            form_element.value = option_value;
        } else if (form_element.type === "sketchpicker") {
            form_element.value = event.hex;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_designpreviewpdf_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_designpreviewpdf_form) {
            is_form_valid = update_designpreviewpdf_form[identifier].valid && is_form_valid;
        }
        this.setState(
            {
                designpreviewpdf_form: update_designpreviewpdf_form,
            },
            function () {
                this.timer = setTimeout(() => {
                    this.SubmitHandler();
                }, 500);
            }
        );
    }

    SubmitHandler = () => {
        let is_form_valid = true;
        const update_designpreviewpdf_form = this.state.designpreviewpdf_form;
        for (let key in update_designpreviewpdf_form) {
            let form_element = update_designpreviewpdf_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_designpreviewpdf_form[key] = form_element;
        }
        this.setState({ designpreviewpdf_form: update_designpreviewpdf_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.designpreviewpdf_form) {
                form_data[key] = this.state.designpreviewpdf_form[key].value;
            }
            console.log("form_data-------------", form_data);
            this.props.DesignPreviewUpdatepdf(form_data);
        }
    };

    /*** Set the wrapper ref ***/
    setColorPickerRef(node) {
        this.ColorPickerRef = node;
    }

    ColorPickerClickOutside(event) {
        if (this.state.color_picker) {
            if (this.ColorPickerRef && !this.ColorPickerRef.contains(event.target)) {
                this.setState({
                    color_picker: false,
                });
            }
        }
    }

    render() {
        const { designpreviewpdf_form } = this.state;
        return (
            <React.Fragment>
                <div className="wrap-right-info">
                    <div className="card-toolbar py-4 design-border align-items-center d-flex justify-content-center">
                        <h4 className="text-dark-blue m-0 fs-8">Design</h4>
                    </div>
                    <div className="scroll-y right-scroll-height">
                        <div className="p-5 border-bottom border-2">
                            <h6 className="text-dark-blue fs-7 mb-3">
                                <i className="icon-Logo text-dark-blue fs-4"></i> Logo
                            </h6>
                            <p className="text-grey-dark fs-9">The logo that will appear on all pages.</p>

                            <div className="border border-gray-300 logo-design py-4">
                                <div className="logo-design-thumb pb-4">
                                    <img width="80px" height="80px" title="bidpdf_logo" alt="bidpdf_logo" src={designpreviewpdf_form.bidpdf_logo.thumbnail_image} />
                                </div>
                                <span className="p-4 pb-0 d-flex align-items-center re-upload text-gray-new">
                                    <label className="btn reupload-btn-main text-gray-new">
                                        Reupload <i className="icon-right-arrow ms-2"></i>
                                        <input hidden accept="image/*" type={designpreviewpdf_form.bidpdf_logo.type} onChange={(event) => this.onFileChange(event, "bidpdf_logo")} />
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="p-5 border-bottom border-2">
                            <h6 className="text-dark-blue fs-7 mb-3">
                                <i className="icon-Text text-dark-blue fs-4"></i> Fonts
                            </h6>
                            <p className="text-grey-dark fs-9">Pick a font pairing to achive the typography style for your copy.</p>
                            <div className="border border-gray-300 logo-design py-4">
                                <div className="px-4">
                                    <span className="text-grey-dark">Title font:</span>
                                    <h6>Lexend (Semilbold)</h6>
                                    <span className="text-grey-dark">Paragraph font:</span>
                                    <p className="">Lexend (Regular)</p>
                                </div>
                                <span className="p-4 pb-0 d-flex align-items-center re-upload text-gray-new">
                                    Pick font pairing <i className="icon-right-arrow ms-2"></i>
                                </span>
                            </div>
                        </div>

                        <div className="p-5 border-bottom border-2">
                            <h6 className="text-dark-blue fs-7 mb-3">
                                <i className="icon-Coulor text-dark-blue fs-4"></i> Colour
                            </h6>
                            <p className="text-grey-dark fs-9">Pick an accent colour for your document.</p>
                            <div className="border border-gray-300 logo-design pb-4" ref={this.setColorPickerRef}>
                                <div className="color-box p-4" style={{ background: designpreviewpdf_form.bidpdf_color.value }}>
                                    <span>{designpreviewpdf_form.bidpdf_color.value ? designpreviewpdf_form.bidpdf_color.value : ""}</span>
                                </div>
                                <span
                                    className="p-4 pb-0 d-flex align-items-center re-upload text-gray-new"
                                    onClick={(event) => {
                                        this.setState({ color_picker: true });
                                    }}
                                >
                                    Select colour <i className="icon-right-arrow ms-2"></i>
                                </span>
                                {this.state.color_picker && (
                                    <div className="color_picker">
                                        <SketchPicker color={designpreviewpdf_form.bidpdf_color.value} onChangeComplete={(event) => this.inputChangeHandler(event, "bidpdf_color")} />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="p-5 border-bottom border-2">
                            <h6 className="text-dark-blue fs-7 mb-3">
                                <i className="icon-additional-setting text-dark-blue fs-4"></i> {designpreviewpdf_form.bidpdf_additionalsettings.label}
                            </h6>
                            {designpreviewpdf_form.bidpdf_additionalsettings.options.map((additionalsetting) => (
                                <div className="intro-pattern d-flex flex-stack p-4" key={additionalsetting.opk}>
                                    <div className="d-flex flex-stack">
                                        <span className="fs-8 color1  ">{additionalsetting.name}</span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value={additionalsetting.value}
                                                checked={additionalsetting.isChecked}
                                                onChange={(event) => this.inputChangeHandler(event, "bidpdf_additionalsettings")}
                                            />
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default BidDesignPreviewRightSideBar;