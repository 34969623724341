import React, { useState } from "react";

import moment from "moment";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

const BidItems = (props) => {
    const [bid_title_show, setbid_title_show] = useState(true);
    const [bid_id_show, setbid_id_show] = useState(true);
    const [client_name_show, setclient_name_show] = useState(true);
    const [bid_value_show, setbid_value_show] = useState(true);
    const [bid_manager_show, setbid_manager_show] = useState(true);
    const [bid_team_show, setbid_team_show] = useState(true);
    const [submission_date_show, setsubmission_date_show] = useState(true);
    const [tags_show, settags_show] = useState(true);
    const [bid_status_show, setbid_status_show] = useState(true);
    const [columns, setcolumns] = useState([
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Name of bid",
            dataField: "bid_title",
            formatter: (cellContent, row, index, extraData) => {
                return <Link to={`/bid-response/${row.bid_id}`}>{row.bid_title}</Link>;
            },
        },
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Bid ID",
            dataField: "bid_id",
        },
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Client name",
            dataField: "client_name",
            formatter: (cellContent, row, index, extraData) => {
                return row.client.client_name;
            },
        },
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Value",
            dataField: "bid_value",
        },
        {
            text: "Manager",
            dataField: "bid_manager",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <div className="symbol-group symbol-hover" key={"bid_manager_" + row.bid_id}>
                            {row.bid_owners &&
                                row.bid_owners.map((bid_owner) => (
                                    <div className="custom-tooltip" key={"bid_owner_" + bid_owner.user_id}>
                                        <div className="symbol symbol-25px symbol-circle">
                                            {bid_owner && bid_owner.avatar ? (
                                                <span className="symbol_img">
                                                    <img src={bid_owner.avatar} title={bid_owner.first_name} alt={bid_owner.first_name} />
                                                    <span className="custom-tooltip-text">{bid_owner.first_name + " " + bid_owner.last_name}</span>
                                                </span>
                                            ) : (
                                                <span className="symbol-label bg-primary text-inverse-primary">
                                                    {bid_owner.first_name.slice(0, 1) + bid_owner.last_name.slice(0, 1)}
                                                    <span className="custom-tooltip-text">{bid_owner.first_name + " " + bid_owner.last_name}</span>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                );
            },
        },
        {
            text: "Team",
            dataField: "bid_team",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <div className="symbol-group symbol-hover" key={"bid_team_" + row.bid_id}>
                            {row.bid_teams.map((bid_team) => (
                                <div className="custom-tooltip" key={"bid_owner_" + bid_team.user_id}>
                                    <div className="symbol symbol-25px symbol-circle">
                                        {bid_team && bid_team.avatar ? (
                                            <span className="symbol_img">
                                                <img src={bid_team.avatar} title={bid_team.first_name} alt={bid_team.first_name} />
                                                <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                            </span>
                                        ) : (
                                            <span className="symbol-label bg-primary text-inverse-primary">
                                                {bid_team.first_name.slice(0, 1) + bid_team.last_name.slice(0, 1)}
                                                <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                );
            },
        },
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Due Date",
            dataField: "submission_date",
            formatter: (cellContent, row, index, extraData) => {
                return moment(row.submission_date).format("DD MMM YYYY");
            },
        },
        {
            text: "Tags",
            dataField: "tags",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <div className="d-flex tags-scroll">
                            {row.bidtag_ids.map((bidtag) => (
                                <span className="badge badge-primary rounded-50" key={"tag_key_" + row.bid_id + "_" + bidtag.bidtag_id}>
                                    {bidtag ? bidtag.tag_name : "-"}
                                </span>
                            ))}
                        </div>
                    </>
                );
            },
        },
        {
            text: "Status",
            dataField: "bid_status",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <span className="svg-icon-5 me-1">
                            <i className={"bit_status_" + row.bid_status.replace(" ", "_").toLowerCase()}></i>
                        </span>
                    </>
                );
            },
        },
        {
            text: "",
            dataField: "bid_action",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="dropdown" key={"bid_action_" + row.bid_id}>
                        <div className="" aria-expanded="false" id={`bid_action_${index}`} data-bs-toggle="dropdown">
                            <img src="assets/images/icons/more.svg" title="more" alt="more" />
                        </div>
                        <div className="dropdown-menu" aria-labelledby={`bid_action_${index}`}>
                            <Link className="dropdown-item" to={`/bid-update/${row.bid_id}`}>
                                Edit bid
                            </Link>
                            <span className="dropdown-item curser_pointer" onClick={(event) => props.ConfirmArchiveBid(row)}>
                                Archive bid
                            </span>
                        </div>
                    </div>
                );
            },
        },
    ]);

    const onColumnToggle = (identifier, actions) => {
        let newTableColumns = columns.map((val) => {
            if (val.dataField === identifier) {
                val.hidden = actions;
            }
            return val;
        });
        setcolumns(newTableColumns);
    };

    const handleTableChange = (type, { page, sizePerPage }) => {
        props.propsfilterpage(page);
    };

    const RemotePagination = ({ columns, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive">
                        <BootstrapTable
                            remote
                            bootstrap4
                            striped
                            data={data}
                            keyField="bid_title"
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered align-middle custom-table-style fs-8"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pb-7 pagination-main">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="row align-items-center">
                <div className="col-md-4">
                    <div className="d-flex align-items-center position-relative search-bids">
                        <span className="svg-icon svg-icon-3 position-absolute me-2">
                            <img src="assets/images/icons/search.svg" title="all_bids" alt="all_bids" />
                        </span>
                        <input
                            type="text"
                            placeholder="Search for bid..."
                            className="form-control form-control-sm ps-7"
                            onChange={(event) => {
                                props.propsfiltersearch(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="d-flex align-items-center mt-2 justify-content-end">
                        {localStorage.getItem("bidlist_tablefilter") === "yes" && (
                            <div className="me-5">
                                <div className="dropdown toggle-btn-dropdown column-drop-out">
                                    <div aria-expanded="false" id="columns_menu" data-bs-toggle="dropdown" className="btn rounded-50 btn-white btn-sm">
                                        <h6 className="text-dark m-0 fs-7">
                                            Columns <i className="icon-down-arrow text-dark fs-10 ms-1"></i>
                                        </h6>
                                    </div>
                                    <div className="dropdown-menu" aria-labelledby="columns_menu">
                                        <h6 className="fs-7 mb-3">Customise Fields</h6>

                                        <div className="dropdown-item">
                                            <div className="d-flex flex-stack">
                                                <div className="d-flex">
                                                    <span className="fs-8 color1">Name Of bid</span>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                        <input
                                                            type="checkbox"
                                                            checked={bid_title_show}
                                                            className="form-check-input"
                                                            onChange={(event) => {
                                                                onColumnToggle("bid_title", bid_title_show);
                                                                setbid_title_show(bid_title_show ? false : true);
                                                            }}
                                                        />
                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="d-flex flex-stack">
                                                <div className="d-flex">
                                                    <span className="fs-8 color1">Bid ID</span>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                        <input
                                                            type="checkbox"
                                                            checked={bid_id_show}
                                                            className="form-check-input"
                                                            onChange={(event) => {
                                                                onColumnToggle("bid_id", bid_id_show);
                                                                setbid_id_show(bid_id_show ? false : true);
                                                            }}
                                                        />
                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="d-flex flex-stack">
                                                <div className="d-flex">
                                                    <span className="fs-8 color1">Client name</span>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                        <input
                                                            type="checkbox"
                                                            checked={client_name_show}
                                                            className="form-check-input"
                                                            onChange={(event) => {
                                                                onColumnToggle("client_name", client_name_show);
                                                                setclient_name_show(client_name_show ? false : true);
                                                            }}
                                                        />
                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="d-flex flex-stack">
                                                <div className="d-flex">
                                                    <span className="fs-8 color1">Value</span>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                        <input
                                                            type="checkbox"
                                                            checked={bid_value_show}
                                                            className="form-check-input"
                                                            onChange={(event) => {
                                                                onColumnToggle("bid_value", bid_value_show);
                                                                setbid_value_show(bid_value_show ? false : true);
                                                            }}
                                                        />
                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="d-flex flex-stack">
                                                <div className="d-flex">
                                                    <span className="fs-8 color1">Manager</span>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                        <input
                                                            type="checkbox"
                                                            checked={bid_manager_show}
                                                            className="form-check-input"
                                                            onChange={(event) => {
                                                                onColumnToggle("bid_manager", bid_manager_show);
                                                                setbid_manager_show(bid_manager_show ? false : true);
                                                            }}
                                                        />
                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="d-flex flex-stack">
                                                <div className="d-flex">
                                                    <span className="fs-8 color1">Team</span>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                        <input
                                                            type="checkbox"
                                                            checked={bid_team_show}
                                                            className="form-check-input"
                                                            onChange={(event) => {
                                                                onColumnToggle("bid_team", bid_team_show);
                                                                setbid_team_show(bid_team_show ? false : true);
                                                            }}
                                                        />
                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="d-flex flex-stack">
                                                <div className="d-flex">
                                                    <span className="fs-8 color1">Due date</span>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                        <input
                                                            type="checkbox"
                                                            checked={submission_date_show}
                                                            className="form-check-input"
                                                            onChange={(event) => {
                                                                onColumnToggle("submission_date", submission_date_show);
                                                                setsubmission_date_show(submission_date_show ? false : true);
                                                            }}
                                                        />
                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="d-flex flex-stack">
                                                <div className="d-flex">
                                                    <span className="fs-8 color1">Tags</span>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                        <input
                                                            type="checkbox"
                                                            checked={tags_show}
                                                            className="form-check-input"
                                                            onChange={(event) => {
                                                                onColumnToggle("tags", tags_show);
                                                                settags_show(tags_show ? false : true);
                                                            }}
                                                        />
                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="d-flex flex-stack">
                                                <div className="d-flex">
                                                    <span className="fs-8 color1">Status</span>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                        <input
                                                            type="checkbox"
                                                            checked={bid_status_show}
                                                            className="form-check-input"
                                                            onChange={(event) => {
                                                                onColumnToggle("bid_status", bid_status_show);
                                                                setbid_status_show(bid_status_show ? false : true);
                                                            }}
                                                        />
                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {localStorage.getItem("bidlist_createbitbutton") === "yes" && (
                            <Link to="/bid-create" className="rounded-50 btn btn-danger btn-sm">
                                Create a new bid
                                <span className="svg-icon svg-icon-2">
                                    <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                                </span>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div className="card-body p-0 bg-white radious-style radious-users  mt-5">
                <RemotePagination data={props.bids} columns={columns} page={props.filterpage} totalSize={props.bidcount} sizePerPage={props.filterlimit} onTableChange={handleTableChange} />
            </div>
        </React.Fragment>
    );
};

export default BidItems;