import React from "react";
import Notifications from "react-notify-toast";

import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

class Dashboard extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Notifications />
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Dashboard" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        Dashboard
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Dashboard;