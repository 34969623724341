import React from "react";

class BidReviewAndLockTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bid_detail: "",
            response_content: [],
        };
    }

    componentDidMount() {
        this.setState({
            bid_detail: this.props.bid_detail,
            response_content: this.props.bid_response_form.response_content,
        });
    }

    render() {
        const { bid_detail } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-11 col-xl-10">
                        <div className="card mb-6">
                            <div className="p-7">
                                <h2 className="card-title text-dark fw-bold-700">Review</h2>
                                <p className="m-0">
                                    There are {bid_detail && bid_detail.response_media.length !== 0 ? bid_detail.response_media.length : 0} files. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Placerat mauris tristiq.
                                </p>
                            </div>
                            {bid_detail && (
                                <div className="p-7 pb-15">
                                    <div className="row">
                                        {bid_detail.response_media.length !== 0 &&
                                            bid_detail.response_media.map((media) => (
                                                <div className="col" key={"reviewlocktabdocument_key_" + media.response_media_id}>
                                                    <div className="review1">
                                                        <div className="review-top pt-3">
                                                            <img className="img-fluid" src="assets/images/pdf-thumb.png" alt="" />
                                                        </div>
                                                        <div className="review-bottom py-4 px-3">
                                                            <span className="text-dark fw-bold-700">{media.media_meta.title}</span>
                                                            <p className="text-gray-400 m-0">Bid response</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="card mb-6">
                            <div className="p-7">
                                <h2 className="card-title text-dark fw-bold-700">Lock</h2>
                                <p className="m-0">Locking your bid means it will no longer be editable until it is unlocked again. Only admins and managers can unlock. This is to ensure that the export reflects the latest approval.</p>
                                <button type="button" className="btn btn-danger btn-lg rounded-50 mt-4">
                                    <i className="icon-lock"></i> Yes, I’m ready to lock
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default BidReviewAndLockTab;