import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import Validations from "../../components/Utility/Validations";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forgotpassword_form: {
                email: {
                    label: "Email Address",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "e.g example@mail.com",
                    validations: { required: true, email: true },
                },
            },
            loader: false,
        };
    }

    inputChangeHandler(event, identifier) {
        event.preventDefault();

        const update_forgotpassword_form = { ...this.state.forgotpassword_form };
        const form_element = { ...update_forgotpassword_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_forgotpassword_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_forgotpassword_form) {
            is_form_valid = update_forgotpassword_form[identifier].valid && is_form_valid;
        }

        this.setState({
            forgotpassword_form: update_forgotpassword_form,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_forgotpassword_form = this.state.forgotpassword_form;
        for (let key in update_forgotpassword_form) {
            let form_element = update_forgotpassword_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_forgotpassword_form[key] = form_element;
        }
        this.setState({ forgotpassword_form: update_forgotpassword_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.forgotpassword_form) {
                form_data[key] = this.state.forgotpassword_form[key].value;
            }
            this.props.onForgotPassword(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/reset-password",
                        });
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { forgotpassword_form } = this.state;
        return (
            <React.Fragment>
                <Notifications />

                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative bg-dark-color">
                            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 text-center-md w-xl-600px scroll-y">
                                <div className="d-flex flex-row-fluid flex-column pt-lg-10 ps-lg-20">
                                    <Link to="signup" className="mb-10 ps-md-10">
                                        <img alt="Logo" src="assets/images/logo.svg" className="h-60px" />
                                    </Link>
                                    <h1 className="fw-bolder fs-3x pb-md-5 ps-md-10 mb-0 text-white">
                                        No more
                                        <br />
                                        confusion. <br />
                                        No more <br />
                                        missing out.
                                    </h1>
                                </div>
                                <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px sign-in-banner"></div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row-fluid py-9">
                            <div className="d-flex flex-center flex-column-fluid">
                                <div className="w-lg-600px p-10 pb-0 pt-0 mx-auto w-100">
                                    <form onSubmit={this.SubmitHandler} autoComplete="off">
                                        <div className="mb-5">
                                            <h1 className="text-dark mb-2 fs-3qx">Forgot Password</h1>
                                        </div>
                                        <div className="fv-row mb-5">
                                            <label className="form-label fs-6">{forgotpassword_form.email.label}</label>
                                            <input
                                                type={forgotpassword_form.email.type}
                                                className="form-control form-control-lg form-control-solid"
                                                placeholder={forgotpassword_form.email.placeholder}
                                                value={forgotpassword_form.email.value ? forgotpassword_form.email.value : ""}
                                                onChange={(event) => this.inputChangeHandler(event, "email")}
                                                onBlur={(event) => {
                                                    forgotpassword_form.email.onBlur_out = true;
                                                    this.setState({
                                                        forgotpassword_form: forgotpassword_form,
                                                    });
                                                }}
                                            />
                                            {!forgotpassword_form.email.valid && forgotpassword_form.email.onBlur_out ? <div className="error field-error">{forgotpassword_form.email.error_msg}</div> : ""}
                                        </div>
                                        <div className="d-flex flex-stack mb-5">
                                            <span>
                                                Don't Have Account? 
												<Link to="signup" className="link-primary">Sign Up</Link>
                                            </span>
                                        </div>
                                        <button type="submit" className="btn btn-primary sign-inbtn">
                                            Forgot Password
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onForgotPassword: userActions.ForgotPassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);