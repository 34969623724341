import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
import Validations from "../../components/Utility/Validations";

class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            conpanydetails_form: {
                avatar: {
                    label: "File",
                    type: "file",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "assets/images/company_dummy.jpg",
                },
                company_name: {
                    type: "text",
                    label: "Company name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Company name",
                    validations: { required: true },
                },
                abn: {
                    type: "text",
                    label: "ABN",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter ABN",
                    validations: { required: true },
                },
                company_email: {
                    type: "email",
                    label: "Company email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Company email",
                    validations: { required: true },
                },
                industry: {
                    type: "text",
                    label: "Industry",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Industry",
                    validations: { required: true },
                },
                company_size: {
                    label: "Size of company",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Size of company",
                    validations: { required: true },
                    options: [
                        { opk: "company_size_opk_0", name: "Size of company", value: "" },
                        { opk: "company_size_opk_1", name: "1-500", value: "1-500" },
                        { opk: "company_size_opk_2", name: "501-1,000", value: "501-1,000" },
                        { opk: "company_size_opk_3", name: "1,001-5,000", value: "1,001-5,000" },
                        { opk: "company_size_opk_4", name: "5,001+", value: "5,001+" },
                    ],
                },
                address: {
                    type: "text",
                    label: "Address",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Company Address",
                    validations: { required: true },
                },
                zipcode: {
                    type: "text",
                    label: "Postcode",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Postcode",
                    validations: { required: true },
                },
                state: {
                    label: "State",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [{ opk: "state_opk0", name: "Select state", value: "" }],
                },
            },
            redirect_url: "",
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        userActions.GetCountries().then((response) => {
            if (response.success === true) {
                const update_conpanydetails_form = { ...this.state.conpanydetails_form };
                let country_detail = response.data;
                let country_state = country_detail["AU"][0].state;
                let state_option = [{ opk: "state_opk0", name: "Select a state", value: "" }];
                for (let key in country_state) {
                    state_option.push({
                        opk: country_state[key].state_code,
                        name: country_state[key].state_name,
                        value: country_state[key].state_code,
                    });
                }
                update_conpanydetails_form.state.options = state_option;
                this.setState({
                    loader: false,
                    conpanydetails_form: update_conpanydetails_form,
                });
            } else {
                notify.show(response.message, "error");
            }
        });

        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        const company_info = user_info.company;
        const update_conpanydetails_form = { ...this.state.conpanydetails_form };

        let form_element = { ...update_conpanydetails_form["company_name"] };
        form_element.value = company_info.company_name;
        update_conpanydetails_form["company_name"] = form_element;

        form_element = { ...update_conpanydetails_form["abn"] };
        form_element.value = company_info.abn;
        update_conpanydetails_form["abn"] = form_element;

        form_element = { ...update_conpanydetails_form["company_email"] };
        form_element.value = company_info.company_email;
        update_conpanydetails_form["company_email"] = form_element;

        form_element = { ...update_conpanydetails_form["industry"] };
        form_element.value = company_info.industry;
        update_conpanydetails_form["industry"] = form_element;

        form_element = { ...update_conpanydetails_form["company_size"] };
        form_element.value = company_info.company_size;
        update_conpanydetails_form["company_size"] = form_element;

        form_element = { ...update_conpanydetails_form["address"] };
        form_element.value = company_info.address;
        update_conpanydetails_form["address"] = form_element;

        form_element = { ...update_conpanydetails_form["zipcode"] };
        form_element.value = company_info.zipcode;
        update_conpanydetails_form["zipcode"] = form_element;

        form_element = { ...update_conpanydetails_form["state"] };
        form_element.value = company_info.state;
        update_conpanydetails_form["state"] = form_element;

        if (company_info.avatar) {
            form_element = { ...update_conpanydetails_form["avatar"] };
            form_element.thumbnail_image = company_info.avatar;
            update_conpanydetails_form["avatar"] = form_element;
        }

        this.setState({
            conpanydetails_form: update_conpanydetails_form,
        });
    }

    remove_company_logo = (event) => {
        event.preventDefault();

        this.props.onRemoveCompanyLogo().then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");

                let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                user_info.company = response.company_info;
                localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                window.location.reload();
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    onFileChange(event, identifier) {
        event.preventDefault();
        this.props.onCompanyUpdateAvatar(event.target.files[0]).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");

                let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                user_info.company = response.company_info;
                localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                window.location.reload();
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    inputChangeHandler(event, identifier) {
        const update_conpanydetails_form = { ...this.state.conpanydetails_form };
        const form_element = { ...update_conpanydetails_form[identifier] };

        if (form_element.type === "file") {
            form_element.value = event.target.files[0];
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_conpanydetails_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_conpanydetails_form) {
            is_form_valid = update_conpanydetails_form[identifier].valid && is_form_valid;
        }

        this.setState({
            conpanydetails_form: update_conpanydetails_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        // Check Validation
        let is_form_valid = true;
        if (!this.state.is_form_valid) {
            const update_conpanydetails_form = this.state.conpanydetails_form;
            for (let key in update_conpanydetails_form) {
                let form_element = update_conpanydetails_form[key];
                let validate_element = Validations(form_element);

                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;

                update_conpanydetails_form[key] = form_element;
            }
            this.setState({
                conpanydetails_form: update_conpanydetails_form,
                is_form_valid: is_form_valid,
            });
        }

        // Form submit handler
        if (this.state.is_form_valid) {
            const form_data = {};
            for (let key in this.state.conpanydetails_form) {
                form_data[key] = this.state.conpanydetails_form[key].value;
            }
            this.props.onCompanyUpdate(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");

                    let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                    user_info.company = response.company_info;
                    localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                    window.location.reload();
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { conpanydetails_form } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.props.isAuthenticated === false ? <Redirect to="/logout" /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Settings" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/settings-account" className="nav-link text-active-primary me-6">
                                                        Account settings
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-company" className="nav-link text-active-primary me-6 active">
                                                        Company details
                                                    </Link>
                                                </li>
												<li className="nav-item">
                                                    <Link to="/account-settings" className="nav-link text-active-primary me-6">
                                                        Account
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-billing" className="nav-link text-active-primary me-6">
                                                        Billing
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-users" className="nav-link text-active-primary me-6">
                                                        Users
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10">
                                                <div className="card mb-5 mb-xl-10">
                                                    <div className="card-header border-0 pt-5 mb-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-2x mb-1">Company details</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body pt-0 pb-0">
                                                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                                                            <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-3">
                                                                <div className="me-7 mb-4">
                                                                    <div className="symbol symbol-100px symbol-fixed position-relative">
                                                                        <img title="company_logo" alt="company_logo" src={conpanydetails_form.avatar.thumbnail_image} />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                                        <div className="d-flex flex-column">
                                                                            <div className="mb-2">
                                                                                <p className="text-gray-400 ">Your organisation’s logo</p>
                                                                                <div className="d-flex align-items-center">
                                                                                    <label className="btn btn-sm btn-outline-primary rounded-50 fw-bolder">
                                                                                        Change photo
                                                                                        <input hidden accept="image/*" type={conpanydetails_form.avatar.type} onChange={(event) => this.onFileChange(event, "avatar")} />
                                                                                    </label>
                                                                                    {!conpanydetails_form.avatar.valid && conpanydetails_form.avatar.onBlur_out ? (
                                                                                        <div className="error field-error">{conpanydetails_form.avatar.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    {user_info.company.avatar && (
                                                                                        <>
                                                                                            <span className="h-25px border-gray-700 border-start mx-4"></span>
                                                                                            <span
                                                                                                className="btn btn-sm btn-link"
                                                                                                onClick={(event) => {
                                                                                                    this.remove_company_logo(event);
                                                                                                }}
                                                                                            >
                                                                                                Remove photo
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-lg-6 mb-4 fv-row">
                                                                    <label className="form-label fs-6">{conpanydetails_form.company_name.label}</label>
                                                                    <input
                                                                        type={conpanydetails_form.company_name.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={conpanydetails_form.company_name.placeholder}
                                                                        value={conpanydetails_form.company_name.value ? conpanydetails_form.company_name.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "company_name")}
                                                                        onBlur={(event) => {
                                                                            conpanydetails_form.company_name.onBlur_out = true;
                                                                            this.setState({
                                                                                conpanydetails_form: conpanydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!conpanydetails_form.company_name.valid && conpanydetails_form.company_name.onBlur_out ? (
                                                                        <div className="error field-error">{conpanydetails_form.company_name.error_msg}</div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                                <div className="col-sm-6 col-lg-6 mb-4 fv-row">
                                                                    <label className="form-label fs-6">{conpanydetails_form.abn.label}</label>
                                                                    <input
                                                                        type={conpanydetails_form.abn.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={conpanydetails_form.abn.placeholder}
                                                                        value={conpanydetails_form.abn.value ? conpanydetails_form.abn.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "abn")}
                                                                        onBlur={(event) => {
                                                                            conpanydetails_form.abn.onBlur_out = true;
                                                                            this.setState({
                                                                                conpanydetails_form: conpanydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!conpanydetails_form.abn.valid && conpanydetails_form.abn.onBlur_out ? <div className="error field-error">{conpanydetails_form.abn.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className="fv-row mb-4 fv-plugins-icon-container">
                                                                <label className="form-label fs-6">{conpanydetails_form.company_email.label}</label>
                                                                <input
                                                                    type={conpanydetails_form.company_email.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={conpanydetails_form.company_email.placeholder}
                                                                    value={conpanydetails_form.company_email.value ? conpanydetails_form.company_email.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "company_email")}
                                                                    onBlur={(event) => {
                                                                        conpanydetails_form.company_email.onBlur_out = true;
                                                                        this.setState({
                                                                            conpanydetails_form: conpanydetails_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!conpanydetails_form.company_email.valid && conpanydetails_form.company_email.onBlur_out ? (
                                                                    <div className="error field-error">{conpanydetails_form.company_email.error_msg}</div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="row fv-row">
                                                                <div className="col-xl-6 mb-4">
                                                                    <label className="form-label fs-6">{conpanydetails_form.industry.label}</label>
                                                                    <input
                                                                        type={conpanydetails_form.industry.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={conpanydetails_form.industry.placeholder}
                                                                        value={conpanydetails_form.industry.value ? conpanydetails_form.industry.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "industry")}
                                                                        onBlur={(event) => {
                                                                            conpanydetails_form.industry.onBlur_out = true;
                                                                            this.setState({
                                                                                conpanydetails_form: conpanydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!conpanydetails_form.industry.valid && conpanydetails_form.industry.onBlur_out ? <div className="error field-error">{conpanydetails_form.industry.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-xl-6 mb-4">
                                                                    <label className="form-label fs-6">{conpanydetails_form.company_size.label}</label>
                                                                    <select
                                                                        className="form-select form-select-solid"
                                                                        data-control="select2"
                                                                        data-placeholder="10"
                                                                        value={conpanydetails_form.company_size.value}
                                                                        onChange={(event) => this.inputChangeHandler(event, "company_size")}
                                                                    >
                                                                        {conpanydetails_form.company_size.options.map((option) => (
                                                                            <option value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!conpanydetails_form.company_size.valid && conpanydetails_form.company_size.onBlur_out ? (
                                                                        <div className="error field-error">{conpanydetails_form.company_size.error_msg}</div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="fv-row mb-4 fv-plugins-icon-container">
                                                                <label className="form-label fs-6">{conpanydetails_form.address.label}</label>
                                                                <input
                                                                    type={conpanydetails_form.address.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={conpanydetails_form.address.placeholder}
                                                                    value={conpanydetails_form.address.value ? conpanydetails_form.address.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "address")}
                                                                    onBlur={(event) => {
                                                                        conpanydetails_form.address.onBlur_out = true;
                                                                        this.setState({
                                                                            conpanydetails_form: conpanydetails_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!conpanydetails_form.address.valid && conpanydetails_form.address.onBlur_out ? <div className="error field-error">{conpanydetails_form.address.error_msg}</div> : ""}
                                                            </div>
                                                            <div className="row fv-row">
                                                                <div className="col-xl-6 mb-4">
                                                                    <label className="form-label fs-6">{conpanydetails_form.zipcode.label}</label>
                                                                    <input
                                                                        type={conpanydetails_form.zipcode.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={conpanydetails_form.zipcode.placeholder}
                                                                        value={conpanydetails_form.zipcode.value ? conpanydetails_form.zipcode.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "zipcode")}
                                                                        onBlur={(event) => {
                                                                            conpanydetails_form.zipcode.onBlur_out = true;
                                                                            this.setState({
                                                                                conpanydetails_form: conpanydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!conpanydetails_form.zipcode.valid && conpanydetails_form.zipcode.onBlur_out ? <div className="error field-error">{conpanydetails_form.zipcode.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-xl-6 mb-4">
                                                                    <label className="form-label fs-6">{conpanydetails_form.state.label}</label>
                                                                    <select className="form-select form-select-solid" value={conpanydetails_form.state.value} onChange={(event) => this.inputChangeHandler(event, "state")}>
                                                                        {conpanydetails_form.state.options.map((option) => (
                                                                            <option value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!conpanydetails_form.state.valid && conpanydetails_form.state.onBlur_out ? <div className="error field-error">{conpanydetails_form.state.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className="mt-5">
                                                                <button type="submit" className="btn rounded-50 btn-lg btn-primary">
                                                                    <span className="indicator-label">Save and continue</span>
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onCompanyUpdateAvatar: userActions.CompanyUpdateAvatar,
    onCompanyUpdate: userActions.CompanyUpdate,
    onRemoveCompanyLogo: userActions.RemoveCompanyLogo,
};
export default connect(mapStateToProps, mapDispatchToProps)(Company);