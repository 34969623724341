import { React, useEffect, useRef } from "react";
import WebViewer from "@pdftron/webviewer";

import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
import LoginFooter from "../../components/LoginFooter";
const Test = () => {
    const viewerDiv = useRef(null);

    useEffect(() => {
        WebViewer(
            {
                path: "/pdfviewer",
                initialDoc: "http://localhost:8001/uploads/pdf/BidDesignPreviewpdf_2.pdf",
            },
            viewerDiv.current
        ).then((instance) => {
            console.log("instance-------", instance);
        });
    }, []);

    return (
        <div className="d-flex flex-column flex-root">
            <div className="page d-flex flex-row flex-column-fluid">
                <LoginSidebar />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <LoginHeader page_title="Test Page" />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row">
                                <div className="col-lg-12 w-100 h-100">
                                    <div className="webviewer" ref={viewerDiv} style={{ height: "70vh" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoginFooter />
                </div>
            </div>
        </div>
    );
};

export default Test;