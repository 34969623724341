import React from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

import AddFolder from "./AddFolder";

class LibraryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            addfolder_modal: false,
        };
    }

    componentDidMount() {}
    AddNewFolder = (event) => {
        event.preventDefault();
        this.setState({ addfolder_modal: true });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                {this.state.addfolder_modal && (
                    <AddFolder
                        addfolder_modal={this.state.addfolder_modal}
                        ref={(ref) => (this.AddUsersSuccessEvent = ref)}
                        CreateClientSubmit={(event) => this.CreateClientSubmit(event)}
                        addfolder_modal_action={(modal_action) => {
                            this.setState({
                                addfolder_modal: modal_action,
                            });
                        }}
                    />
                )}
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Library" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/content" className="nav-link text-active-primary me-6 active">
                                                        Content
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="returnable-documents" className="nav-link text-active-primary me-6">
                                                        Returnable Documents
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <form className="form w-100">
                                                <div className="card mb-5 mb-xl-8">
                                                    <div className="card-header border-0 pt-5 mb-10">
                                                        <div className="col-md-6">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <h3 className="card-title align-items-start flex-column ms-4">
                                                                    <span className="card-label fw-bolder fs-2x mb-1">Content</span>
                                                                    <span className="mt-1 fw-normal text-dark-muted fs-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et lacus mauris.</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="d-flex align-items-center my-2 justify-content-end">
                                                                <span className="svg-icon svg-icon-3 me-2">
                                                                    <img src="assets/images/icons/search.svg" title="all_content" alt="all_content" />
                                                                </span>
                                                                <button type="button" className="rounded-50 btn btn-primary btn-sm" onClick={(event) => this.AddNewFolder(event)}>
                                                                    Create new folder
                                                                    <span className="svg-icon svg-icon-2">
                                                                        <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-0">
                                                        <div className="row mb-4">
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="row">
                                            <form className="form w-100">
                                                <div className="card mb-5 mb-xl-8">
                                                    <div className="card-header border-0 pt-5 mb-10">
                                                        <div className="col-md-6">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <h3 className="card-title align-items-start flex-column ms-4">
                                                                    <span className="card-label fw-bolder fs-2x mb-1">Past proposals</span>
                                                                    <span className="mt-1 fw-normal text-dark-muted fs-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et lacus mauris.</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="d-flex align-items-center my-2 justify-content-end">
                                                                <span className="svg-icon svg-icon-3 me-2">
                                                                    <img src="assets/images/icons/search.svg" title="all_content" alt="all_content" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-0">
                                                        <div className="row mb-4">
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link className="col-sm-2 col-lg-2 fv-row " to="/case-study">
                                                                <div className="row main_floder_div">
                                                                    <div className="col-sm-3 col-lg-3">
                                                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                                                    </div>
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className="folder_name">Folder name</div>
                                                                        <div className="folder_foles">00 files</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    OnCreateClient: userActions.CreateClient,
};
export default connect(mapStateToProps, mapDispatchToProps)(LibraryList);