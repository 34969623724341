import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import Validations from "../../components/Utility/Validations";
import SocialButton from "../../components/Utility/SocialButton";
import { google_client_id } from "../../constants/actionTypes";

class signin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login_form: {
                Email: {
                    type: "email",
                    label: "Your email address",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },
                Password: {
                    type: "password",
                    label: "Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
                remember: {
                    valid: true,
                    error_msg: "",
                    type: "checkbox",
                    isChecked: false,
                    value: "remember",
                    label: " Keep me sign in",
                    validations: { required: false },
                },
            },
            is_form_valid: false,
            passwordshow: false,
            redirect_url: "",
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        /*** Remember Me Function ***/
        if (localStorage.getItem("remember_email") && localStorage.getItem("remember_password")) {
            const update_login_form = { ...this.state.login_form };

            /*** Email ***/
            let form_element = { ...update_login_form["Email"] };
            form_element.value = localStorage.getItem("remember_email");
            update_login_form["Email"] = form_element;

            /*** Password ***/
            form_element = { ...update_login_form["Password"] };
            form_element.value = localStorage.getItem("remember_password");
            update_login_form["Password"] = form_element;

            /*** Remember ***/
            form_element = { ...update_login_form["remember"] };
            form_element.isChecked = true;
            update_login_form["remember"] = form_element;

            this.setState({ login_form: update_login_form });
        }
    }

    inputChangeHandler(event, identifier) {
        const update_login_form = { ...this.state.login_form };
        const form_element = { ...update_login_form[identifier] };
        if (identifier === "remember") {
            form_element.isChecked = event.target.checked;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_login_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_login_form) {
            is_form_valid = update_login_form[identifier].valid && is_form_valid;
        }

        this.setState({
            login_form: update_login_form,
            is_form_valid: is_form_valid,
        });
    }

    loginSubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_login_form = this.state.login_form;
        for (let key in update_login_form) {
            let form_element = update_login_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_login_form[key] = form_element;
        }
        this.setState({ login_form: update_login_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.login_form) {
                if (key === "remember") {
                    if (this.state.login_form[key].isChecked === false) {
                        break;
                    }
                }
                form_data[key] = this.state.login_form[key].value;
            }
            this.props.onSignIn(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/verification",
                        });
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    handleSocialLogin = (user) => {
        this.props.onSocialSignIn(user).then((response) => {
            if (response.success === true) {
                this.props.onGetAuthUser().then((response) => {
                    if (response.success === true) {
                        setTimeout(() => {
                            this.setState({
                                isAuthenticated: true,
                                redirect_url: "/dashboard",
                            });
                        }, 500);
                    } else {
                        notify.show(response.message, "error");
                    }
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    handleSocialLoginFailure = (error) => {
        console.log("error -------------", error);
    };

    render() {
        const { login_form } = this.state;
        return (
            <React.Fragment>
                <Notifications />
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative bg-dark-color">
                            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 text-center-md w-xl-600px scroll-y">
                                <div className="d-flex flex-row-fluid flex-column pt-lg-10 ps-lg-20">
                                    <Link to="signup" className="mb-10 ps-md-10">
                                        <img src="assets/images/logo.svg" title="logo" alt="logo" className="h-60px" />
                                    </Link>
                                    <h1 className="fw-bolder fs-3x pb-md-5 ps-md-10 mb-0 text-white">
                                        No more
                                        <br />
                                        confusion. <br />
                                        No more <br />
                                        missing out.
                                    </h1>
                                </div>
                                <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px sign-in-banner"></div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row-fluid py-9">
                            <div className="d-flex flex-center flex-column-fluid">
                                <div className="w-lg-600px p-10 pb-0 pt-0 mx-auto w-100">
                                    <form className="form w-100" autoComplete="off" onSubmit={this.loginSubmitHandler}>
                                        <div className="mb-5">
                                            <h1 className="text-dark mb-2 fs-3qx">Log in</h1>
                                            <div className="text-dark fs-2x fs-4">Welcome back!</div>
                                        </div>
                                        <div className="fv-row mb-5">
                                            <label className="form-label fs-6">{login_form.Email.label}</label>
                                            <input
                                                type="email"
                                                className="form-control form-control-lg form-control-solid"
                                                placeholder={login_form.Email.placeholder}
                                                value={login_form.Email.value ? login_form.Email.value : ""}
                                                onChange={(event) => this.inputChangeHandler(event, "Email")}
                                                onBlur={(event) => {
                                                    login_form.Email.onBlur_out = true;
                                                    this.setState({
                                                        login_form: login_form,
                                                    });
                                                }}
                                            />
                                            {!login_form.Email.valid && login_form.Email.onBlur_out ? <div className="error field-error">{login_form.Email.error_msg}</div> : ""}
                                        </div>
                                        <div className="fv-row mb-5">
                                            <label className="form-label fs-6">{login_form.Password.label}</label>
                                            <div className="position-relative mb-3">
                                                <input
                                                    type={this.state.passwordshow ? "text" : "Password"}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={login_form.Password.placeholder}
                                                    value={login_form.Password.value ? login_form.Password.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "Password")}
                                                    onBlur={(event) => {
                                                        login_form.Password.onBlur_out = true;
                                                        this.setState({
                                                            login_form: login_form,
                                                        });
                                                    }}
                                                />
                                                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                    <i
                                                        className={this.state.passwordshow ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                        onClick={(event) => {
                                                            this.setState({ passwordshow: this.state.passwordshow ? false : true });
                                                        }}
                                                    ></i>
                                                </span>
                                            </div>
                                            {!login_form.Password.valid && login_form.Password.onBlur_out ? <div className="error field-error">{login_form.Password.error_msg}</div> : ""}
                                        </div>
                                        <div className="d-flex flex-stack mb-5">
                                            <label className="form-check form-check-custom form-check-solid form-check-inline" htmlFor="signin_remember">
                                                <input
                                                    id="signin_remember"
                                                    className="form-check-input"
                                                    type={login_form.remember.type}
                                                    value={login_form.remember.value}
                                                    checked={login_form.remember.isChecked}
                                                    onChange={(event) => this.inputChangeHandler(event, "remember")}
                                                />
                                                <span className="form-check-label text-gray-400 fs-6">{login_form.remember.label}</span>
                                            </label>
                                            <Link to="/forgot-password" className="fs-6 text-gray-400">
                                                Forgot Password ?
                                            </Link>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-lg btn-primary rounded-50 w-100 mb-8">
                                                <span className="indicator-label">Log in</span>
                                            </button>
                                            <div className="d-flex align-items-center mb-8">
                                                <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                                                <span className="fw-bold text-gray-400 fs-7 mx-2">or</span>
                                                <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="text-center">
                                        <SocialButton provider="google" appId={google_client_id} onLoginSuccess={this.handleSocialLogin} onLoginFailure={this.handleSocialLoginFailure}>
                                            <img src="assets/images/icons/google-g.svg" title="google-logo" alt="google-logo" className="h-20px me-3" />
                                            Sign in with Google
                                        </SocialButton>
                                        <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
                                            <div className="d-flex flex-center fs-6">
                                                Don’t have an account?
                                                <Link to="/signup" className="ms-1 link-primary">
                                                    Sign up
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onSignIn: userActions.SignIn,
    onSocialSignIn: userActions.SocialSignIn,
    onGetAuthUser: userActions.GetAuthUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(signin);