import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import Validations from "../../components/Utility/Validations";
import SocialButton from "../../components/Utility/SocialButton";
import { google_client_id } from "../../constants/actionTypes";

class signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            register_form: {
                first_name: {
                    type: "text",
                    label: "First Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Last  Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                email: {
                    label: "Email Address",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },
                phone: {
                    type: "phone",
                    label: "Phone number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                password: {
                    label: "Password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
                confirm_password: {
                    label: "Confirm Password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
            },
            is_form_valid: false,
            passwordshow: false,
            confirm_passwordshow: false,
            redirect_url: "",
            isAuthenticated: false,
        };
    }

    inputChangeHandler(event, identifier) {
        const update_register_form = { ...this.state.register_form };
        const form_element = { ...update_register_form[identifier] };
        if (identifier === "remember") {
            form_element.isChecked = event.target.checked;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_register_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_register_form) {
            is_form_valid = update_register_form[identifier].valid && is_form_valid;
        }

        this.setState({
            register_form: update_register_form,
            is_form_valid: is_form_valid,
        });
    }

    SignUpSubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_register_form = this.state.register_form;
        for (let key in update_register_form) {
            let form_element = update_register_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_register_form[key] = form_element;
        }
        this.setState({ register_form: update_register_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.register_form) {
                form_data[key] = this.state.register_form[key].value;
            }
            this.props.onSignUp(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/signup-company",
                        });
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    handleSocialLogin = (user) => {
        this.props.onSocialSignUp(user).then((response) => {
            if (response.success === true) {
                this.props.onGetAuthUser().then((response) => {
                    if (response.success === true) {
                        setTimeout(() => {
                            this.setState({
                                isAuthenticated: true,
                                redirect_url: "/dashboard",
                            });
                        }, 500);
                    } else {
                        notify.show(response.message, "error");
                    }
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    handleSocialLoginFailure = (error) => {
        console.log("error -------------", error);
    };

    render() {
        const { register_form } = this.state;
        return (
            <React.Fragment>
                <Notifications />
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative bg-dark-color">
                            <div className="d-flex flex-column text-center-md position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
                                <div className="d-flex flex-row-fluid flex-column pt-lg-10 ps-lg-20">
                                    <Link to="/signin" className="mb-10 ps-lg-10">
                                        <img src="assets/images/logo.svg" title="logo" alt="logo" className="h-60px" />
                                    </Link>
                                    <h1 className="fw-bolder fs-3x pb-md-5 ps-md-10 mb-0 text-white">
                                        You’re just a<br />
                                        few clicks away <br />
                                        from creating <br />
                                        better bids
                                    </h1>
                                </div>
                                <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px sign-up-banner"></div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row-fluid py-9">
                            <div className="d-flex flex-center flex-column flex-column-fluid">
                                <div className="w-lg-600px p-10 pb-0 pt-0 mx-auto w-100">
                                    <form className="form w-100" autoComplete="off" onSubmit={this.SignUpSubmitHandler}>
                                        <div className="mb-5">
                                            <h1 className="text-dark mb-2 fs-3qx">Sign up</h1>
                                            <div className="text-dark fs-2x fs-4">Let’s create your account.</div>
                                        </div>
                                        <div className="row fv-row">
                                            <div className="col-xl-6 mb-4">
                                                <label className="form-label fs-6">{register_form.first_name.label}</label>
                                                <input
                                                    type={register_form.first_name.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={register_form.first_name.placeholder}
                                                    value={register_form.first_name.value ? register_form.first_name.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "first_name")}
                                                    onBlur={(event) => {
                                                        register_form.first_name.onBlur_out = true;
                                                        this.setState({
                                                            register_form: register_form,
                                                        });
                                                    }}
                                                />
                                                {!register_form.first_name.valid && register_form.first_name.onBlur_out ? <div className="error field-error">{register_form.first_name.error_msg}</div> : ""}
                                            </div>
                                            <div className="col-xl-6 mb-4">
                                                <label className="form-label fs-6">{register_form.last_name.label}</label>
                                                <input
                                                    type={register_form.last_name.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={register_form.last_name.placeholder}
                                                    value={register_form.last_name.value ? register_form.last_name.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "last_name")}
                                                    onBlur={(event) => {
                                                        register_form.last_name.onBlur_out = true;
                                                        this.setState({
                                                            register_form: register_form,
                                                        });
                                                    }}
                                                />
                                                {!register_form.last_name.valid && register_form.last_name.onBlur_out ? <div className="error field-error">{register_form.last_name.error_msg}</div> : ""}
                                            </div>
                                        </div>
                                        <div className="row fv-row">
                                            <div className="col-xl-6 mb-4">
                                                <label className="form-label fs-6">{register_form.email.label}</label>
                                                <input
                                                    type={register_form.email.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={register_form.email.placeholder}
                                                    value={register_form.email.value ? register_form.email.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "email")}
                                                    onBlur={(event) => {
                                                        register_form.email.onBlur_out = true;
                                                        this.setState({
                                                            register_form: register_form,
                                                        });
                                                    }}
                                                />
                                                {!register_form.email.valid && register_form.email.onBlur_out ? <div className="error field-error">{register_form.email.error_msg}</div> : ""}
                                            </div>
                                            <div className="col-xl-6 mb-4">
                                                <label className="form-label fs-6">{register_form.phone.label}</label>
                                                <input
                                                    type={register_form.phone.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={register_form.phone.placeholder}
                                                    value={register_form.phone.value ? register_form.phone.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "phone")}
                                                    onBlur={(event) => {
                                                        register_form.phone.onBlur_out = true;
                                                        this.setState({
                                                            register_form: register_form,
                                                        });
                                                    }}
                                                />
                                                {!register_form.phone.valid && register_form.phone.onBlur_out ? <div className="error field-error">{register_form.phone.error_msg}</div> : ""}
                                            </div>
                                        </div>
                                        <div className="row fv-row">
                                            <div className="col-xl-6 mb-4">
                                                <label className="form-label fs-6">{register_form.password.label}</label>
                                                <div className="position-relative mb-3">
                                                    <input
                                                        type={this.state.passwordshow ? "text" : "password"}
                                                        className="form-control form-control-lg form-control-solid"
                                                        placeholder={register_form.password.placeholder}
                                                        value={register_form.password.value ? register_form.password.value : ""}
                                                        onChange={(event) => this.inputChangeHandler(event, "password")}
                                                        onBlur={(event) => {
                                                            register_form.password.onBlur_out = true;
                                                            this.setState({
                                                                register_form: register_form,
                                                            });
                                                        }}
                                                    />
                                                    <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                        <i
                                                            className={this.state.passwordshow ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                            onClick={(event) => {
                                                                this.setState({ passwordshow: this.state.passwordshow ? false : true });
                                                            }}
                                                        ></i>
                                                    </span>
                                                </div>
                                                {!register_form.password.valid && register_form.password.onBlur_out ? <div className="error field-error">{register_form.password.error_msg}</div> : ""}
                                            </div>
                                            <div className="col-xl-6 mb-4">
                                                <label className="form-label fs-6">{register_form.confirm_password.label}</label>
                                                <div className="position-relative mb-3">
                                                    <input
                                                        type={this.state.confirm_passwordshow ? "text" : "password"}
                                                        className="form-control form-control-lg form-control-solid"
                                                        placeholder={register_form.confirm_password.placeholder}
                                                        value={register_form.confirm_password.value ? register_form.confirm_password.value : ""}
                                                        onChange={(event) => this.inputChangeHandler(event, "confirm_password")}
                                                        onBlur={(event) => {
                                                            register_form.confirm_password.onBlur_out = true;
                                                            this.setState({
                                                                register_form: register_form,
                                                            });
                                                        }}
                                                    />
                                                    <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                        <i
                                                            className={this.state.confirm_passwordshow ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                            onClick={(event) => {
                                                                this.setState({ confirm_passwordshow: this.state.confirm_passwordshow ? false : true });
                                                            }}
                                                        ></i>
                                                    </span>
                                                </div>
                                                {!register_form.confirm_password.valid && register_form.confirm_password.onBlur_out ? <div className="error field-error">{register_form.confirm_password.error_msg}</div> : ""}
                                            </div>
                                        </div>
                                        <div className="fv-row mb-4">
                                            <div>
                                                By clicking the Signing Up, you are creating a Mailchimp account, and you agree to BidHQ’s
                                                <a href="/terms" target="_blank" className="ms-1 link-primary">
                                                    terms of service
                                                </a>
                                                . and
                                                <a href="/privacy" target="_blank" className="ms-1 link-primary">
                                                    privacy policy
                                                </a>
                                                .
                                            </div>
                                        </div>
                                        <div className="text-center mb-4">
                                            <button type="submit" className="btn rounded-50 btn-lg btn-primary w-100">
                                                <span className="indicator-label">Sign Up !</span>{" "}
                                                <span className="indicator-progress">
                                                    Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                                            <span className="fw-bold text-gray-400 fs-7 mx-2">or</span>
                                            <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                                        </div>
                                    </form>
                                    <SocialButton provider="google" appId={google_client_id} onLoginSuccess={this.handleSocialLogin} onLoginFailure={this.handleSocialLoginFailure}>
                                        <img src="assets/images/icons/google-g.svg" title="google-logo" alt="google-logo" className="h-20px me-3" />
                                        Sign in with Google
                                    </SocialButton>
                                </div>
                            </div>
                            <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
                                <div className="d-flex flex-center fw-bold fs-6">
                                    Already have an account?
                                    <Link to="/signin" className="ms-1 link-primary">
                                        Log in
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onSignUp: userActions.SignUp,
    onSocialSignUp: userActions.SocialSignUp,
    onGetAuthUser: userActions.GetAuthUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(signup);