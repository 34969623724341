import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
import Validations from "../../components/Utility/Validations";

class SettingsAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            personal_form: {
                avatar: {
                    label: "File",
                    type: "file",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "assets/images/profile_dummy.jpg",
                },
                first_name: {
                    type: "text",
                    label: "First Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter first name",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Second name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Second name",
                    validations: { required: true },
                },
                role: {
                    label: "What’s your role?",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Size of company",
                    validations: { required: true },
                    options: [{ opk: "role_opk_0", name: "What’s your role?", value: "" }],
                },
                email: {
                    label: "Email Address",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "e.g example@mail.com",
                    validations: { required: true, email: true },
                },
                phone: {
                    type: "phone",
                    label: "Phone number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Phone number",
                    validations: { required: true },
                },
            },

            password_form: {
                current_password: {
                    label: "Current password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
                password: {
                    label: "New Password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
                confirm_password: {
                    label: "Confirm Password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
            },

            loader: false,
            is_form_valid: false,
            redirect_url: "",
            isAuthenticated: false,
            password_show: false,
            current_password_show: false,
            confirm_password_show: false,
        };
    }

    async componentDidMount() {
        await userActions.GetRoles().then((response) => {
            if (response.success === true) {
                let roles = response.data;
                const update_personal_form = { ...this.state.personal_form };
                let roles_option = [{ opk: "role_opk_0", name: "What’s your role?", value: "" }];
                for (let key in roles) {
                    roles_option.push({
                        opk: roles[key][0].role_id,
                        name: roles[key][0].user_role,
                        value: roles[key][0].role_id,
                    });
                }
                update_personal_form.role.options = roles_option;
                this.setState({
                    personal_form: update_personal_form,
                });
            } else {
                notify.show(response.message, "error");
            }
        });

        const update_personal_form = { ...this.state.personal_form };
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        let personal_info = user_info.user;

        let form_element = { ...update_personal_form["first_name"] };
        form_element.value = personal_info.first_name;
        update_personal_form["first_name"] = form_element;

        form_element = { ...update_personal_form["last_name"] };
        form_element.value = personal_info.last_name;
        update_personal_form["last_name"] = form_element;

        form_element = { ...update_personal_form["role"] };
        form_element.value = personal_info.role_id;
        update_personal_form["role"] = form_element;

        form_element = { ...update_personal_form["email"] };
        form_element.value = personal_info.email;
        update_personal_form["email"] = form_element;

        form_element = { ...update_personal_form["phone"] };
        form_element.value = personal_info.phone;
        update_personal_form["phone"] = form_element;

        if (personal_info.avatar) {
            form_element = { ...update_personal_form["avatar"] };
            form_element.thumbnail_image = personal_info.avatar;
            update_personal_form["avatar"] = form_element;
        }

        this.setState({
            personal_form: update_personal_form,
        });
    }

    remove_profile_image = (event) => {
        event.preventDefault();

        this.props.onRemoveProfileImage().then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                user_info.user = response.user_info;
                localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                window.location.reload();
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    onFileChange(event, identifier) {
        event.preventDefault();
        this.props.onUpdatePersonalAvatar(event.target.files[0]).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");

                let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                user_info.user = response.user_info;
                console.log("user_info-----------------", user_info);
                localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                window.location.reload();
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    PersonalChangeHandler(event, identifier) {
        const update_personal_form = { ...this.state.personal_form };
        const form_element = { ...update_personal_form[identifier] };

        if (form_element.type === "file") {
            form_element.value = event.target.files[0];
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_personal_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_personal_form) {
            is_form_valid = update_personal_form[identifier].valid && is_form_valid;
        }

        this.setState({
            personal_form: update_personal_form,
            is_form_valid: is_form_valid,
        });
    }

    PersonalSubmitHandler = (event) => {
        event.preventDefault();

        // Check Validation
        let is_form_valid = true;
        if (!this.state.is_form_valid) {
            const update_personal_form = this.state.personal_form;
            for (let key in update_personal_form) {
                let form_element = update_personal_form[key];
                let validate_element = Validations(form_element);

                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;

                update_personal_form[key] = form_element;
            }
            this.setState({
                personal_form: update_personal_form,
                is_form_valid: is_form_valid,
            });
        }

        // Form submit handler
        if (this.state.is_form_valid) {
            const form_data = {};
            for (let key in this.state.personal_form) {
                form_data[key] = this.state.personal_form[key].value;
            }
            this.props.onUpdatePersonal(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");

                    let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                    user_info.user = response.user_info;
                    localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    PasswordChangeHandler(event, identifier) {
        const update_password_form = { ...this.state.password_form };
        const form_element = { ...update_password_form[identifier] };

        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_password_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_password_form) {
            is_form_valid = update_password_form[identifier].valid && is_form_valid;
        }

        this.setState({
            password_form: update_password_form,
            is_form_valid: is_form_valid,
        });
    }

    PasswordSubmitHandler = (event) => {
        event.preventDefault();

        // Check Validation
        let is_form_valid = true;
        if (!this.state.is_form_valid) {
            const update_password_form = this.state.password_form;
            for (let key in update_password_form) {
                let form_element = update_password_form[key];
                let validate_element = Validations(form_element);

                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;

                update_password_form[key] = form_element;
            }
            this.setState({
                password_form: update_password_form,
                is_form_valid: is_form_valid,
            });
        }

        // Form submit handler
        if (this.state.is_form_valid) {
            const form_data = {};
            for (let key in this.state.password_form) {
                form_data[key] = this.state.password_form[key].value;
            }
            this.props.onUpdatePassword(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    // window.location.reload();
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { password_form } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.props.isAuthenticated === false ? <Redirect to="/logout" /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Settings" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container " className="container-xxl ">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/settings-account" className="nav-link text-active-primary me-6 ">
                                                        Account settings
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-company" className="nav-link text-active-primary me-6">
                                                        Company details
                                                    </Link>
                                                </li>
												<li className="nav-item">
                                                    <Link to="/account-settings" className="nav-link text-active-primary me-6 active">
                                                        Account
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-billing" className="nav-link text-active-primary me-6">
                                                        Billing
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-users" className="nav-link text-active-primary me-6">
                                                        Users
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10">


											 <div className="card mb-5 mb-xl-10">
                                                    <div className="card-header border-0 pt-5 mb-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label text-dark fw-bolder fs-2x mb-3">Your Organisations</span>

														<span className="text-muted mt-1 fw-normal fs-7">Organisation:<span className="color1 ms-2">[Organisation name]</span></span>
														</h3>
                                                    </div>

                                                </div>


											 {user_info.user.oauth_users.length === 0 && (
                                                    <>
                                                        <div className="card mb-5 mb-xl-10">
                                                            <div className="card-header border-0 pt-5 mb-4">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label text-dark fw-bolder fs-2x mb-1">Password</span>{" "}
                                                                    <span className="text-muted mt-1 fw-normal fs-7">To update your password please enter your current password and then choose and confirm a new one</span>
                                                                </h3>
                                                            </div>
                                                            <form className="form w-100" onSubmit={this.PasswordSubmitHandler}>
                                                                <div className="card-body pt-0 pb-0">
                                                                    <div className="row mb-4">
                                                                        <div className="col-lg-6 fv-row">
                                                                            <label className="form-label fs-6">{password_form.current_password.label}</label>
                                                                            <div className="position-relative mb-3">
                                                                                <input
                                                                                    type={this.state.current_password_show ? "text" : "password"}
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder={password_form.current_password.placeholder}
                                                                                    value={password_form.current_password.value ? password_form.current_password.value : ""}
                                                                                    onChange={(event) => this.PasswordChangeHandler(event, "current_password")}
                                                                                    onBlur={(event) => {
                                                                                        password_form.current_password.onBlur_out = true;
                                                                                        this.setState({
                                                                                            password_form: password_form,
                                                                                        });
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            {!password_form.current_password.valid && password_form.current_password.onBlur_out ? (
                                                                                <div className="error field-error">{password_form.current_password.error_msg}</div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-6 col-lg-6 mb-4 fv-row">
                                                                            <label className="form-label fs-6">{password_form.password.label}</label>
                                                                            <div className="position-relative mb-3">
                                                                                <input
                                                                                    type={this.state.password_show ? "text" : "password"}
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder={password_form.password.placeholder}
                                                                                    value={password_form.password.value ? password_form.password.value : ""}
                                                                                    onChange={(event) => this.PasswordChangeHandler(event, "password")}
                                                                                    onBlur={(event) => {
                                                                                        password_form.password.onBlur_out = true;
                                                                                        this.setState({
                                                                                            password_form: password_form,
                                                                                        });
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            {!password_form.password.valid && password_form.password.onBlur_out ? <div className="error field-error">{password_form.password.error_msg}</div> : ""}
                                                                        </div>
                                                                        <div className="col-sm-6 col-lg-6 mb-4 fv-row">
                                                                            <label className="form-label fs-6">{password_form.confirm_password.label}</label>
                                                                            <div className="position-relative mb-3">
                                                                                <input
                                                                                    type={this.state.confirm_password_show ? "text" : "password"}
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder={password_form.confirm_password.placeholder}
                                                                                    value={password_form.confirm_password.value ? password_form.confirm_password.value : ""}
                                                                                    onChange={(event) => this.PasswordChangeHandler(event, "confirm_password")}
                                                                                    onBlur={(event) => {
                                                                                        password_form.confirm_password.onBlur_out = true;
                                                                                        this.setState({
                                                                                            password_form: password_form,
                                                                                        });
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            {!password_form.confirm_password.valid && password_form.confirm_password.onBlur_out ? (
                                                                                <div className="error field-error">{password_form.confirm_password.error_msg}</div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-footer d-flex pt-0">
                                                                        <button type="submit" className="save-button btn btn-primary rounded-50 w-200">
                                                                            Save changes
                                                                        </button>
                                                                        <button type="submit" className="text-muted btn btn-link ms-6 fs-7">
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>

														 <div className="card mb-5 mb-xl-10">
                                                    <div className="card-header border-0 pt-5 mb-0">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label text-dark fw-bolder fs-2x mb-1">Two-Factor Authentication</span>
															</h3>
															 </div>
															 <div className="card-body pt-0 pb-5">
															<div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-5">
															<div className="flex-grow-1">
															<span className="text-black mt-1 fw-normal fs-5">Require an authentication code when you log in with an email and password</span>
															</div>
															<div className="d-flex">
															Enable
															<label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2"><input type="checkbox" className="form-check-input" checked=""/><span className="form-check-label fw-bold text-muted"></span></label>

															</div>

															</div>
														</div>


                                                </div>



												 <div className="card mb-5 mb-xl-10">

                                                    <div className="card-body pt-5 pb-5 table-responsive">

													<table className="table billing-table">
												<tbody>
													<tr>
													  <td className="min-w-200px  text-gray-new"><h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label text-dark fw-bolder fs-2x mb-1">Notifications</span>
                                                        </h3></td>
													  <td className="w-175px text-gray-new ">Desktop notification</td>
													  <td className="w-175px text-gray-new">Email</td>

													</tr>

													<tr>
													  <td className="text-black mt-1 fw-normal fs-5">Notification type sentence here</td>
													  <td><label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2"><input type="checkbox" className="form-check-input" checked=""/><span className="form-check-label fw-bold text-muted"></span></label></td>
													  <td><label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2"><input type="checkbox" className="form-check-input" checked=""/><span className="form-check-label fw-bold text-muted"></span></label></td>

													</tr>
													<tr>
													  <td className="text-black mt-1 fw-normal fs-5">Notification type sentence here</td>
													  <td><label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2"><input type="checkbox" className="form-check-input" checked=""/><span className="form-check-label fw-bold text-muted"></span></label></td>
													  <td><label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2"><input type="checkbox" className="form-check-input" checked=""/><span className="form-check-label fw-bold text-muted"></span></label></td>

													</tr>
													<tr>
													  <td className="text-black mt-1 fw-normal fs-5">Notification type sentence here</td>
													  <td ><label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2"><input type="checkbox" className="form-check-input" checked=""/><span className="form-check-label fw-bold text-muted"></span></label></td>
													  <td><label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2"><input type="checkbox" className="form-check-input" checked=""/><span className="form-check-label fw-bold text-muted"></span></label></td>

													</tr>
													<tr>
													  <td className="text-black mt-1 fw-normal fs-5">Notification type sentence here</td>
													  <td ><label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2"><input type="checkbox" className="form-check-input" checked=""/><span className="form-check-label fw-bold text-muted"></span></label></td>
													  <td><label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2"><input type="checkbox" className="form-check-input" checked=""/><span className="form-check-label fw-bold text-muted"></span></label></td>

													</tr>
												  </tbody>
												</table>







                                                    </div>
                                                </div>





                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onUpdatePersonalAvatar: userActions.UpdatePersonalAvatar,
    onUpdatePersonal: userActions.UpdatePersonal,
    onRemoveProfileImage: userActions.RemoveProfileImage,
    onUpdatePassword: userActions.UpdatePassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingsAccount);
