import React from "react";
import moment from "moment";

import WysiwygEditorComment from "../../../../components/Utility/WysiwygEditorComment";
class BidRightSideSectionComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parent_id: "",
            section_id: "",
            comment_text: "",
            section_comment: "",
        };
    }

    componentDidMount() {
        if (this.props.section_comment !== undefined) {
            this.setState({
                comment_text: "",
                section_comment: this.props.section_comment,
                section_id: this.props.bid_section_selected.section_id,
                parent_id: this.props.section_comment.comment.comment_id,
            });
        } else {
            this.setState({
                parent_id: "",
                comment_text: "",
                section_comment: "",
                section_id: this.props.bid_section_selected.section_id,
            });
        }
    }

    BidRightSideSectionCommentReload() {
        if (this.props.section_comment !== undefined) {
            this.setState({
                comment_text: "",
                section_comment: this.props.section_comment,
                section_id: this.props.bid_section_selected.section_id,
                parent_id: this.props.section_comment.comment.comment_id,
            });
        } else {
            this.setState({
                parent_id: "",
                comment_text: "",
                section_comment: "",
                section_id: this.props.bid_section_selected.section_id,
            });
        }
    }

    inputChangeHandler(event, identifier) {
        this.setState({ comment_text: event });
    }

    SubmitHandler = (event) => {
        event.preventDefault();
        this.props.BidSectionComment(this.state.comment_text, this.state.parent_id, this.state.section_id);
    };

    render() {
        const { section_comment } = this.state;
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        return (
            <React.Fragment>
                <div className="wrap-right-info right-comment bid_rightsidebar_sectioncomment">
                    <div
                        className="comment-close-button"
                        onClick={() => {
                            this.props.bid_create_rightsidebar_sectioncomment_action(false);
                        }}
                    >
                        <i className="icon-cross-1" aria-hidden="true"></i>
                    </div>
                    <div className="card-toolbar m-0">
                        <div className="card-header border-0 pt-5">
                            <h2 className="card-title align-items-start flex-column">{section_comment ? "Comment" : "Add New Comment"}</h2>
                        </div>
                        {section_comment && (
                            <div className="comment_section ">
                                <div className="original_comment_section p-5">
                                    <h6 className="text-grey-dark fs-8 mb-3">ORIGINAL COMMENT</h6>
                                    <div className="d-flex flex-column flex-row-fluid">
                                        <div className="d-flex align-items-center flex-wrap mb-1">
                                            <span className="text-hover-primary fw-bolder me-2 fs-8">
                                                {section_comment.comment.user.first_name} {section_comment.comment.user.last_name}
                                            </span>
                                            <span className="time-date-wrap fs-8">{moment(section_comment.comment.comment_date).format("DD/MM/YYYY")}</span>
                                        </div>
                                        <span className=" fs-8 fw-normal">{renderHTML(section_comment.comment.comment_text)}</span>
                                    </div>
                                </div>
                                <div className="reply_comment_section p-5">
                                    <h6 className="text-grey-dark fs-8 mb-3">{section_comment.comment.reply_count} REPLIES</h6>
                                    {section_comment.child &&
                                        section_comment.child.map((comment) => {
                                            return (
                                                <div className="d-flex flex-column flex-row-fluid" key={"reply_comment_" + comment.comment_id}>
                                                    <div className="d-flex align-items-center flex-wrap mb-1">
                                                        <span className=" text-hover-primary fw-bolder me-2 fs-8">
                                                            {comment.user.first_name} {comment.user.last_name}
                                                        </span>
                                                        <span className=" time-date-wrap fs-8">{moment(comment.comment_date).format("DD/MM/YYYY")}</span>
                                                    </div>
                                                    <span className=" fs-8 fw-normal pb-2">{renderHTML(comment.comment_text)}</span>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="card-body pt-0">
                                <div className="row mt-4">
                                    <WysiwygEditorComment value={this.state.comment_text} onChange={(event) => this.inputChangeHandler(event, "comment_text")} />
                                </div>
                                <button type="submit" className="btn btn-primary rounded-50 w-200 mt-4">
                                    submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default BidRightSideSectionComment;