import React from "react";
const AddAnotherDocument = (props) => {
    return (
        <div className="doc-outer mb-2" key={"add_document_key_" + props.bid_document.id}>
            <div className="row">
                <div className="col-sm-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
                        <i className="icon-folder-plus main-img-icon"></i>
                        <div className="card-title align-items-start flex-column ms-7 d-flex ">
                            <span className="card-label fw-bold-700 fs-7 ">
                                Untitled document
                                <span className="btn-icon ms-1 doc-edit-icon">
                                    <i className="icon-edit"></i>
                                </span>
                            </span>
                            <span className="fw-normal text-gray fs-7">Define the document name</span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 text-end d-flex align-items-center justify-content-end">
                    <span className="fileinput-button">
                        <span>Choose a file</span>
                        <i className="icon-upload"></i>
                        <input type="file" onChange={(event) => props.onFileChange(event, "bid_document")} />
                    </span>
                </div>
            </div>
        </div>
    );
};
export default AddAnotherDocument;