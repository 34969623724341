import React, { useState } from "react";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

const ClientItems = (props) => {
    const [client_name_show, setclient_name_show] = useState(true);
    const [client_email_show, setclient_email_show] = useState(true);
    const [columns, setcolumns] = useState([
        {
            sort: true,
            text: "Client Name",
            dataField: "client_name",
            formatter: (cellContent, row, index, extraData) => {
                return <Link to={`/client-update/${row.client_id}`}>{row.client_name}</Link>;
            },
        },
        {
            sort: true,
            text: "Email Address",
            dataField: "client_email",
        },
        {
            text: "",
            dataField: "client_action",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="dropdown" key={"client_action_" + row.client_id}>
                        <div className="" aria-expanded="false" id={`client_action_${index}`} data-bs-toggle="dropdown">
                            <img src="assets/images/icons/more.svg" title="more" alt="more" />
                        </div>
                        <div className="dropdown-menu" aria-labelledby={`client_action_${index}`}>
                            <Link className="dropdown-item" to={`/client-update/${row.client_id}`}>
                                Edit client information
                            </Link>

                            <Link className="dropdown-item" to={`/clients-bids/${row.client_id}`}>
                                View total bids to client
                            </Link>
                            <span className="dropdown-item" onClick={(event) => props.ConfirmArchiveClient(row)}>
                                Archive Client
                            </span>
                        </div>
                    </div>
                );
            },
        },
    ]);

    const onColumnToggle = (identifier, actions) => {
        let newTableColumns = columns.map((val) => {
            if (val.dataField === identifier) {
                val.hidden = actions;
            }
            return val;
        });
        setcolumns(newTableColumns);
    };

    const handleTableChange = (type, { page, sizePerPage }) => {
        props.propsfilterpage(page);
    };

    const RemotePagination = ({ columns, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive">
                        <BootstrapTable
                            remote
                            bootstrap4
                            striped
                            data={data}
                            keyField="client_name"
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered align-middle custom-table-style"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-end flex-wrap pt-10">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="row align-items-center">
                <div className="col-md-5">
                    <div className="d-flex align-items-center position-relative search-bids">
                        <span className="svg-icon svg-icon-3 position-absolute me-2">
                            <img src="assets/images/icons/search.svg" title="all_bids" alt="all_bids" />
                        </span>
                        <input
                            type="text"
                            placeholder="Search for client..."
                            className="form-control form-control-sm ps-9"
                            onChange={(event) => {
                                props.propsfiltersearch(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="d-flex align-items-center my-2 justify-content-end">
                        <div className="me-5">
                            <div className="dropdown">
                                <div aria-expanded="false" id="columns_menu" data-bs-toggle="dropdown" className="btn rounded-50 btn-white btn-sm me-4">
                                    Columns
                                </div>
                                <div className="dropdown-menu" aria-labelledby="columns_menu">
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-5 text-dark text-hover-primary fw-bolder">Client Name</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={client_name_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("client_name", client_name_show);
                                                            setclient_name_show(client_name_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-5 text-dark text-hover-primary fw-bolder">Email Address</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={client_email_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("client_email", client_email_show);
                                                            setclient_email_show(client_email_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link to="/client-create" className="rounded-50 btn btn-danger btn-sm">
                            Create a new client
                            <span className="svg-icon svg-icon-2">
                                <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="card-body p-0 bg-white radious-style mt-5">
                <RemotePagination data={props.clients} columns={columns} page={props.filterpage} totalSize={props.clientcount} sizePerPage={props.filterlimit} onTableChange={handleTableChange} />
            </div>
        </React.Fragment>
    );
};
export default ClientItems;