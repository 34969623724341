import React from "react";
import { Link } from "react-router-dom";

class LoginHeader extends React.Component {
    render() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                <div id="kt_header" className="header align-items-center">
                    <div className="container-fluid d-flex justify-content-between">
                        <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
                            <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
                                <span className="svg-icon svg-icon-2x mt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                                        <path
                                            opacity="0.3"
                                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex flex-grow-1 flex-lg-grow-0">
                            <Link to="/dashboard" className="d-lg-none">
                                <img alt="Logo" src="assets/images/logo.svg" className="" />
                            </Link>
                        </div>
                        <div className="d-flex flex-stack flex-lg-grow-1">
                            <div className="d-flex" id="kt_header_nav">
                                <div
                                    data-kt-swapper="true"
                                    data-kt-swapper-mode="prepend"
                                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_header_nav'}"
                                    className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                >
                                    <h1 className="d-flex align-items-center text-dark fw-bold-700 fs-3 my-1">{this.props.page_title ? this.props.page_title : "Dashboard"}</h1>
                                </div>
                            </div>
                            <div className="d-flex align-items-stretch flex-shrink-0">
                                <div className="d-flex align-items-stretch flex-shrink-0">
                                    <div className="d-flex align-items-center ms-1 me-lg-2" id="kt_header_user_menu_toggle">
                                        <div className="dropdown">
                                            <div className="cursor-pointer drop-icon-change align-items-center" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="d-none d-md-flex flex-column justify-content-center ms-2">
                                                    <span className="head-btn-bg">
                                                        <i className="fas fa-plus"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold px-2 py-2 fs-6 w-275px mt-2"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                {localStorage.getItem("bidlist_createbitbutton") === "yes" && (
                                                    <div className="menu-item">
                                                        <Link to="/bid-create" className="menu-link py-4">
                                                            <span className="me-4 icon-info1"></span>
                                                            Create New Bid
                                                        </Link>
                                                    </div>
                                                )}
                                                <div className="menu-item ">
                                                    <Link to="/client-create" className="menu-link py-4">
                                                        <i className="icon-clients me-4"></i>
                                                        Add New Client
                                                    </Link>
                                                </div>
                                                <div className="menu-item">
                                                    <Link to="/library" className="menu-link py-4">
                                                        <i className="icon-folder-plus1 me-4"></i>
                                                        Create New Content
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center ms-1 me-lg-2" id="kt_header_user_menu_toggle">
                                        <div className="dropdown">
                                            <div className="drop-bell-change cursor-pointer symbol align-items-center symbol-30px symbol-md-40px " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="d-none d-md-flex flex-column justify-content-center ms-2 ">
                                                    <span className="bell-btn-bg">
                                                        <i className="far fa-bell">
                                                            <span className="bullet bullet-dot bg-danger h-9px w-9px position-absolute translate-middle top-10px"></span>
                                                        </i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-450px"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <div className="px-7 py-5 d-flex flex-stack border-bottom">
                                                    <div className="fs-1 text-dark fw-bolder">Notifications</div>
                                                    <div className="fs-7 text-dark">
                                                        <Link to="#">Mark all as read</Link>
                                                    </div>
                                                </div>

                                                <div className="p-5">
                                                    <div className="d-flex mb-5 pb-5 border-bottom">
                                                        <div className="symbol symbol-30px me-3">
                                                            <img title="user" alt="user" src={user_info.user.avatar ? user_info.user.avatar : "assets/images/profile_dummy.jpg"} />
                                                        </div>
                                                        <div className="d-flex flex-column flex-row-fluid">
                                                            <div className="d-flex align-items-center flex-wrap mb-1">
                                                                <span className="text-grey-dark me-2 fs-6">Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex mb-5 pb-5 border-bottom">
                                                        <div className="symbol symbol-30px me-3">
                                                            <img title="user" alt="user" src={user_info.user.avatar ? user_info.user.avatar : "assets/images/profile_dummy.jpg"} />
                                                        </div>
                                                        <div className="d-flex flex-column flex-row-fluid">
                                                            <div className="d-flex align-items-center flex-wrap mb-1">
                                                                <span className="text-grey-dark me-2 fs-6">Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex mb-5 pb-5 border-bottom">
                                                        <div className="symbol symbol-30px me-3">
                                                            <img title="user" alt="user" src={user_info.user.avatar ? user_info.user.avatar : "assets/images/profile_dummy.jpg"} />
                                                        </div>
                                                        <div className="d-flex flex-column flex-row-fluid">
                                                            <div className="d-flex align-items-center flex-wrap mb-1">
                                                                <span className="text-grey-dark me-2 fs-6">Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex mb-5 pb-5 border-bottom">
                                                        <div className="symbol symbol-30px me-3">
                                                            <img title="user" alt="user" src={user_info.user.avatar ? user_info.user.avatar : "assets/images/profile_dummy.jpg"} />
                                                        </div>
                                                        <div className="d-flex flex-column flex-row-fluid">
                                                            <div className="d-flex align-items-center flex-wrap mb-1">
                                                                <span className="text-grey-dark me-2 fs-6">Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column flex-row-fluid">
                                                            <div className="align-items-center text-center flex-wrap mb-1">
                                                                <Link to="#">View all notifications</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                                        <div className="dropdown">
                                            <div className="cursor-pointer symbol align-items-center symbol-30px symbol-md-40px ps-5 border-start" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img title="user" alt="user" src={user_info.user.avatar ? user_info.user.avatar : "assets/images/profile_dummy.jpg"} />
                                                <div className="d-none d-md-flex flex-column justify-content-center ms-2">
                                                    <span className="text-dark fs-7 fw-bold-700 lh-1 mb-2">
                                                        {user_info.user.first_name} {user_info.user.last_name}
                                                    </span>
                                                    <span className="text-dark fs-8 fw-bold lh-1">{user_info.company.company_name}</span>
                                                </div>
                                                <span className="menu-arrow ms-4">
                                                    <img src="assets/images/icons/down-arrow.svg" title="down-arrow" alt="down-arrow" width="12" height="7" />
                                                </span>
                                            </div>
                                            <div className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column  menu-gray-800 menu-state-bg menu-state-primary fw-bold px-2 py-2 fs-6 w-275px" aria-labelledby="dropdownMenuButton1">
                                                <div className="menu-item">
                                                    <Link to="/settings-account" className="menu-link py-4">
                                                        <span className="me-2">
                                                            <img src="assets/images/icons/setting.svg" title="setting" alt="setting" />
                                                        </span>
                                                        Settings
                                                    </Link>
                                                </div>
                                                <div className="menu-item">
                                                    <Link to="/settings-users" className="menu-link py-4">
                                                        <span className="me-2">
                                                            <img src="assets/images/icons/user.svg" title="user" alt="user" />
                                                        </span>
                                                        Invite users
                                                    </Link>
                                                </div>
                                                <div className="menu-item">
                                                    <Link to="/settings-billing" className="menu-link py-4">
                                                        <span className="me-2">
                                                            <img src="assets/images/icons/price.svg" title="price" alt="price" />
                                                        </span>
                                                        Pricing
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default LoginHeader;