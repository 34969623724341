import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** GetLibraryWithContents Action ***/
export const GetLibraryWithContentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetLibraryWithContents_START,
    };
};
export const GetLibraryWithContentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetLibraryWithContents_SUCCESS,
    };
};
export const GetLibraryWithContentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetLibraryWithContents_FAIL,
    };
};
export const GetLibraryWithContents = (search) => {
    return (dispatch) => {
        dispatch(GetLibraryWithContentsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetLibraryWithContents`,
            params: {
                search: search,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetLibraryWithContentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetLibraryWithContentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};