import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import * as userActions from "../../../actions/index";
import Validations from "../../../components/Utility/Validations";
class EditInviteUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit_userinvite_form: {
                invite_id: {
                    label: "Invite Id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                avatar: {
                    label: "File",
                    type: "file",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "assets/images/profile_dummy.jpg",
                },
                first_name: {
                    type: "text",
                    label: "First Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Last  Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                email: {
                    label: "Email Address",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },
                role: {
                    label: "Access",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Access",
                    validations: { required: true },
                    options: [{ opk: "role_opk_0", name: "Select Access", value: "" }],
                },
            },
        };
    }

    componentDidMount() {
        let edit_invited_user = this.props.edit_invited_user;
        const update_edit_userinvite_form = { ...this.state.edit_userinvite_form };

        let user_roles = this.props.user_roles;
        if (user_roles) {
            let roles_option = [{ opk: "role_opk_0", name: "Select Access", value: "" }];
            for (let key in user_roles) {
                roles_option.push({
                    opk: user_roles[key][0]["role_id"],
                    name: user_roles[key][0]["user_role"],
                    value: user_roles[key][0]["role_id"],
                });
            }
            update_edit_userinvite_form.role.options = roles_option;
        }

        let form_element = { ...update_edit_userinvite_form["invite_id"] };
        form_element.value = edit_invited_user.invite_id;
        update_edit_userinvite_form["invite_id"] = form_element;

        form_element = { ...update_edit_userinvite_form["email"] };
        form_element.value = edit_invited_user.invite_email;
        update_edit_userinvite_form["email"] = form_element;

        if (edit_invited_user.user) {
            form_element = { ...update_edit_userinvite_form["first_name"] };
            form_element.value = edit_invited_user.user.first_name;
            update_edit_userinvite_form["first_name"] = form_element;

            form_element = { ...update_edit_userinvite_form["last_name"] };
            form_element.value = edit_invited_user.user.last_name;
            update_edit_userinvite_form["last_name"] = form_element;
        }

        form_element = { ...update_edit_userinvite_form["role"] };
        form_element.value = edit_invited_user.role_id;
        update_edit_userinvite_form["role"] = form_element;

        this.setState({ edit_userinvite_form: update_edit_userinvite_form });
    }

    inputChangeHandler(event, identifier) {
        const update_edit_userinvite_form = { ...this.state.edit_userinvite_form };
        const form_element = { ...update_edit_userinvite_form[identifier] };
        if (identifier === "include_completed_tasks") {
            form_element.isChecked = event.target.checked;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_edit_userinvite_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_edit_userinvite_form) {
            is_form_valid = update_edit_userinvite_form[identifier].valid && is_form_valid;
        }

        this.setState({
            edit_userinvite_form: update_edit_userinvite_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_edit_userinvite_form = this.state.edit_userinvite_form;
        for (let key in update_edit_userinvite_form) {
            let form_element = update_edit_userinvite_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_edit_userinvite_form[key] = form_element;
        }
        this.setState({ edit_userinvite_form: update_edit_userinvite_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.edit_userinvite_form) {
                form_data[key] = this.state.edit_userinvite_form[key].value;
            }
            this.props.ConfirmEditInviteUser(form_data);
        }
    };

    render() {
        const { edit_userinvite_form } = this.state;
        let edit_invited_user = this.props.edit_invited_user;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                <Modal
                    className="userinvite_modal"
                    show={this.props.edit_invited_modal}
                    onHide={() => {
                        this.props.edit_invited_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>User Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-3">
                            <div className="me-7 mb-4">
                                <div className="symbol symbol-75px symbol-fixed position-relative">
                                    <img title="profile_logo" alt="profile_logo" src={edit_userinvite_form.avatar.thumbnail_image} />
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    <div className="d-flex flex-column">
                                        <div className="mb-2">
                                            {edit_userinvite_form.first_name.value && (
                                                <span className="text-dark-blue userinvite_name">
                                                    {edit_userinvite_form.first_name.value} {edit_userinvite_form.last_name.value}
                                                </span>
                                            )}
                                            <p className="text-gray-400 userinvite_email">{edit_userinvite_form.email.value}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-lg-12 fv-row mg-bottom">
                                <label className="form-label">{edit_userinvite_form.role.label}</label>
                                <select className="form-select form-select-solid" value={edit_userinvite_form.role.value} onChange={(event) => this.inputChangeHandler(event, "role")}>
                                    {edit_userinvite_form.role.options.map((option) => (
                                        <option value={option.value} key={option.opk}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                                {!edit_userinvite_form.role.valid && edit_userinvite_form.role.onBlur_out ? <div className="error field-error">{edit_userinvite_form.role.error_msg}</div> : ""}
                            </div>

                            <div className="col-lg-12 fv-row mg-bottom border-bottom pb-4">
                                <label className="form-label label-title">Access</label>

                                <div className="form-check">
                                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                        <input className="form-check-radio" type="radio" name="radios15" />
                                        <span className="form-check-label">Admin</span>
                                    </label>
                                    <p>An admin can lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                        <input className="form-check-radio" type="radio" name="radios15" />
                                        <span className="form-check-label">Member</span>
                                    </label>
                                    <p>A member does not have lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                        <input className="form-check-radio" type="radio" name="radios15" />
                                        <span className="form-check-label">Viewer</span>
                                    </label>
                                    <p>A viewer does not have lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </div>

                            <div className="col-lg-12 fv-row mg-bottom border-bottom">
                                <label className="form-label">Invite</label>
                                <div className="info-outer">
                                    <div className="info-left">
                                        <p>
                                            Was invited by {edit_invited_user.created_user.first_name} {edit_invited_user.created_user.last_name} {userActions.TimeAgo(new Date(edit_invited_user.invite_date))}.
                                        </p>
                                    </div>
                                    {edit_invited_user.invite_status === "Invited" && (
                                        <>
                                            <div className="info-right">
                                                <span className="btn invite-user rounded-50" onClick={(event) => this.props.ReInviteUser(edit_invited_user)}>
                                                    Reinvite
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="col-lg-12 fv-row ">
                                <label className="form-label">Remove user</label>
                                <div className="info-outer">
                                    <div className="info-left">
                                        <p>User will no longer be able to access {user_info.company.company_name}.</p>
                                    </div>
                                    <div className="info-right">
                                        <span className="btn invite-user rounded-50" onClick={(event) => this.props.RemoveInviteUser(edit_invited_user)}>
                                            Remove
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary  save-user-btn rounded-50 w-150">
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetInviteUsers: userActions.GetInviteUsers,
    onGetCompanyUsers: userActions.GetCompanyUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditInviteUser);