import React from "react";
import Notifications from "react-notify-toast";

import ClientList from "../../components/Clients/ClientList.js";
import ClientCreate from "../../components/Clients/ClientCreate.js";
import ClientUpdate from "../../components/Clients/ClientUpdate.js";
class Client extends React.Component {
    render() {
		
        const renderview = () => {
            if (this.props.match.path === "/clients") {
                return <ClientList />;
            }
            if (this.props.match.path === "/client-create") {
                return <ClientCreate />;
            }
            if (this.props.match.path === "/client-update/:id") {
                return <ClientUpdate client_id={this.props.match.params.id} />;
            }
        };
		
        return (
            <React.Fragment>
                <Notifications />
                {renderview()}
            </React.Fragment>
        );
		
    }
}
export default Client;