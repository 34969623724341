import React, { useState, useEffect, useCallback } from "react";

import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";

import BidItems from "./BidItems";
import BidStatus from "./BidStatus";
import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

const BidOverview = (props) => {
    const dispatch = useDispatch();

    const [loader, setloader] = useState(false);
    const [bids, setbids] = useState([]);
    const [bidcount, setbidcount] = useState(0);
    const [bidstatus_count, setbidstatus_count] = useState([]);

    const [bidstatus_all, setbidstatus_all] = useState(0);
    const [bidstatus_lost, setbidstatus_lost] = useState(0);
    const [bidstatus_won, setbidstatus_won] = useState(0);
    const [bidstatus_submitted, setbidstatus_submitted] = useState(0);
    const [bidstatus_inprogress, setbidstatus_inprogress] = useState(0);
    const [bidstatus_withdrawn, setbidstatus_withdrawn] = useState(0);

    const [filterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filtersort, setfiltersort] = useState("bid_id");
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersearch, setfiltersearch] = useState(null);
    const [filterbid_status, setfilterbid_status] = useState("all");

    const GetCountByStatus = useCallback(() => {
        setloader(true);
        dispatch(userActions.GetCountByStatus()).then((response) => {
            setloader(false);
            if (response.success === true) {
                let bid_count = 0;
                setbidstatus_count(response.data);
                for (let key in response.data) {
                    bid_count += response.data[key].count;
                    if (response.data[key].bid_status === "In progress") {
                        setbidstatus_inprogress(response.data[key].count);
                    }
                    if (response.data[key].bid_status === "Won") {
                        setbidstatus_won(response.data[key].count);
                    }
                    if (response.data[key].bid_status === "Submitted") {
                        setbidstatus_submitted(response.data[key].count);
                    }
                    if (response.data[key].bid_status === "Withdrawn") {
                        setbidstatus_withdrawn(response.data[key].count);
                    }
                    if (response.data[key].bid_status === "Lost") {
                        setbidstatus_lost(response.data[key].count);
                    }
                }
                setbidstatus_all(bid_count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch]);

    const GetBids = useCallback(() => {
        setloader(true);
        dispatch(userActions.GetBids(filterbid_status, filtersearch, filterpage, filterlimit, filtersort, filterorder)).then((response) => {
            setloader(false);
            if (response.success === true) {
                GetCountByStatus();
                setbids(response.data);
                setbidcount(response.count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, GetCountByStatus, filterbid_status, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    useEffect(() => {
        GetBids();
    }, [GetBids, filterbid_status, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    const ConfirmArchiveBid = (bid_detail) => {
        if (window.confirm("Are you sure to delete this bid?")) {
            const form_data = {};
            form_data["bid_id"] = bid_detail.bid_id;
            setloader(true);
            dispatch(userActions.DeleteBid(form_data)).then((response) => {
                setloader(false);
                if (response.success === true) {
                    GetBids();
                    notify.show(response.message, "success");
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    return (
        <React.Fragment>
            {loader ? <Loader /> : null}
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <LoginHeader page_title="Bid overview" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card mb-5 mb-xl-7 bg-transparent all-users">
                                                <div className="card-header border-0 p-0 mb-10">
                                                    <h3 className="card-title align-items-start flex-column">
                                                        <span className="card-label fw-bold-700 fs-2x mb-1"> All users </span>
                                                        <span className="mt-1 fw-normal color1 fs-7"> Lorem ipsum dolor sit amet, consectetur adipiscing elit.Placerat mauris tristiq. </span>
                                                    </h3>
                                                </div>
                                                <BidStatus
                                                    bidcount={bidcount}
                                                    bidstatus_all={bidstatus_all}
                                                    bidstatus_count={bidstatus_count}
                                                    bidstatus_lost={bidstatus_lost}
                                                    bidstatus_won={bidstatus_won}
                                                    bidstatus_submitted={bidstatus_submitted}
                                                    bidstatus_inprogress={bidstatus_inprogress}
                                                    bidstatus_withdrawn={bidstatus_withdrawn}
                                                    propsbid_status={(bid_status) => {
                                                        setfilterbid_status(bid_status);
                                                    }}
                                                />
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <BidItems
                                        bids={bids}
                                        filterlimit={filterlimit}
                                        bidcount={bidcount}
                                        filterpage={filterpage}
                                        propsfiltersort={(event) => setfiltersort(event)}
                                        propsfilterpage={(event) => setfilterpage(event)}
                                        propsfilterorder={(event) => setfilterorder(event)}
                                        propsfiltersearch={(event) => setfiltersearch(event)}
                                        ConfirmArchiveBid={(event) => ConfirmArchiveBid(event)}
                                    />
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default BidOverview;