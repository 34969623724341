import React from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import Validations from "../../../../components/Utility/Validations";
class AddSectionTeamModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            section_team_form: {
                bid_id: {
                    label: "Bid ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                section_id: {
                    label: "Section ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                section_teams: {
                    type: "typeahead",
                    label: "Owners",
                    value: [],
                    options: [],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: {
                        required: true,
                        typeahead: true,
                    },
                },
            },
            bid_section_selected: [],
        };
    }

    componentDidMount() {
        let bid_teams = this.props.bid_detail.bid_teams;
        let bid_section_selected = this.props.bid_section_selected;
        const update_section_team_form = { ...this.state.section_team_form };

        let section_teams_option = [];
        for (let key in bid_teams) {
            section_teams_option.push({
                opk: bid_teams[key].user_id,
                name: bid_teams[key].first_name + " " + bid_teams[key].last_name,
                value: bid_teams[key].user_id,
            });
        }

        update_section_team_form.bid_id.value = bid_section_selected.bid_id;
        update_section_team_form.section_id.value = bid_section_selected.section_id;
        update_section_team_form.section_teams.options = section_teams_option;
        this.setState({
            bid_section_selected: bid_section_selected,
            section_team_form: update_section_team_form,
        });
    }

    AddSectionTeamReload() {
        let bid_teams = this.props.bid_detail.bid_teams;
        let bid_section_selected = this.props.bid_section_selected;
        const update_section_team_form = { ...this.state.section_team_form };

        let section_teams_option = [];
        for (let key in bid_teams) {
            section_teams_option.push({
                opk: bid_teams[key].user_id,
                name: bid_teams[key].first_name + " " + bid_teams[key].last_name,
                value: bid_teams[key].user_id,
            });
        }

        update_section_team_form.bid_id.value = bid_section_selected.bid_id;
        update_section_team_form.section_id.value = bid_section_selected.section_id;

        update_section_team_form.section_teams.value = [];
        update_section_team_form.section_teams.options = section_teams_option;
        this.setState({
            bid_section_selected: bid_section_selected,
            section_team_form: update_section_team_form,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_section_team_form = this.state.section_team_form;
        for (let key in update_section_team_form) {
            let form_element = update_section_team_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_section_team_form[key] = form_element;
        }
        this.setState({ section_team_form: update_section_team_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.section_team_form) {
                if (this.state.section_team_form[key].type === "typeahead") {
                    let form_element = this.state.section_team_form[key].value;
                    form_data[key] = form_element.map((t) => t.value);
                } else {
                    form_data[key] = this.state.section_team_form[key].value;
                }
            }
            this.props.UpdateSectionTeam(form_data);
        }
    };

    render() {
        const { section_team_form, bid_section_selected } = this.state;
        return (
            <React.Fragment>
                <Modal
                    className="section_team_modal"
                    show={this.props.section_team_modal}
                    onHide={() => {
                        this.props.section_team_modal_action(false);
                    }}
                >
                    <Modal.Header>
                        <Modal.Title>Assign team members "AddSectionTeamModal"</Modal.Title>
                        <CloseButton
                            className="bid_section_selected_handle_false"
                            onClick={() => {
                                this.props.section_team_modal_action(false);
                            }}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-5">You can only assign members already part of the bid’s Global team.</p>
                        <p className="mb-3 fw-bold-700">Team ({bid_section_selected.response_user && bid_section_selected.response_user.section_teams !== null ? bid_section_selected.response_user.section_teams.length : 0})</p>
                        <div className="button-group tags-btn d-flex flex-wrap">
                            {bid_section_selected.response_user &&
                                bid_section_selected.response_user.section_teams &&
                                bid_section_selected.response_user.section_teams.map((section_team) => {
                                    return (
                                        <div className="badge badge-grey bid-badge-main rounded-50" key={"bid_owner_" + section_team.user_id}>
                                            <span className="circle-symbol symbol-label bg-primary text-inverse-primary">{section_team.first_name.slice(0, 1) + section_team.last_name.slice(0, 1)}</span>
                                            {section_team.first_name + " " + section_team.last_name}
                                            <span
                                                className="cross-button"
                                                onClick={() => {
                                                    this.props.RemoveSectionTeam(bid_section_selected, section_team);
                                                }}
                                            >
                                                <i className="fa fa-times" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    );
                                })}
                        </div>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row mb-6">
                                <div className="col-lg-12 fv-row">
                                    <AsyncTypeahead
                                        multiple
                                        id="section_teams"
                                        labelKey="name"
                                        isLoading={false}
                                        className="react-bootstrap-typeahead"
                                        onSearch={(query) => {}}
                                        options={section_team_form.section_teams.options}
                                        selected={section_team_form.section_teams.value}
                                        onChange={(selected) => {
                                            const update_section_team_form = { ...this.state.section_team_form };
                                            const form_element = { ...update_section_team_form["section_teams"] };
                                            form_element.value = selected;
                                            update_section_team_form["section_teams"] = form_element;
                                            this.setState({
                                                section_team_form: update_section_team_form,
                                            });
                                        }}
                                    />
                                    {!section_team_form.section_teams.valid && section_team_form.section_teams.onBlur_out ? <div className="error field-error">{section_team_form.section_teams.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn cancel-butn rounded-50 w-150 bid_section_selected_handle_false"
                                    onClick={() => {
                                        this.props.section_team_modal_action(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary remove-user rounded-50 w-150">
                                    Save
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddSectionTeamModal;