import React from "react";
class LibraryRightSideFilePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parent_id: "",
            library_rightsidebar_filepreview: false,
            comment_text: "",
            AddRenameContent: false,
            LibraryRemoveFile: false,
        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="wrap-right-info right-comment bid_rightsidebar_sectioncomment">
                    <div
                        className="comment-close-button"
                        onClick={() => {
                            this.props.library_rightsidebar_filepreview(false);
                        }}
                    >
                        <i className="icon-cross-1" aria-hidden="true"></i>
                    </div>
                    <div className="card-toolbar m-0">
                        <div className="card-header border-0 pt-5">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card ">
                                        <div className="title-header-top border-bottom p-5">
                                            <h4 className="text-dark fw-bold-700 fs-3 m-0">
                                                <span className="icon-File"></span> File preview
                                            </h4>
                                        </div>

                                        <div className="p-5">
                                            <h6 className="text-gray fs-9 mb-2">CONTENT NAME</h6>
                                            <span className="fs-6 d-block fw-bold-700">Lorem ipsum dolor sit amet, consectetu.</span>
                                        </div>

                                        <div className="p-5">
                                            <h6 className="text-gray fs-9 mb-2">LAST MODIFIED</h6>
                                            <span className="fs-6 d-block text-gray-400 ">DD/MM/YY</span>
                                        </div>
                                        <div className="p-5">
                                            <h6 className="text-gray fs-9 mb-2">MODIFIED BY</h6>
                                            <div className="symbol symbol-25px symbol-circle" key="1" data-bs-toggle="tooltip" data-bs-original-title="D K">
                                                <span className="symbol-label bg-primary text-inverse-primary">DK</span>
                                                <span className="symbol-label bg-primary text-inverse-primary">AK</span>
                                            </div>
                                            <span className="fs-6 d-block text-gray-400 ">DD/MM/YY</span>
                                        </div>
                                        <div className="p-5">
                                            <h6 className="text-gray fs-9 mb-2">LAST USED IN BID</h6>
                                            <span className="fs-6 d-block text-gray-400 ">DD/MM/YY</span>
                                        </div>
                                        <div className="p-5">
                                            <h6 className="text-gray fs-9 mb-2">OVERALL TIMES USED</h6>
                                            <span className="fs-6  d-block text-gray-400 ">XX</span>
                                        </div>
                                        <div className="p-5">
                                            <h6 className="text-gray fs-9 mb-2">DATE CREATED</h6>
                                            <span className="fs-6 d-block text-gray-400 ">DD/MM/YY</span>
                                        </div>

                                        <div className="p-5">
                                            <h6 className="text-gray fs-9 mb-2">OWNER</h6>
                                            <div className="symbol symbol-25px symbol-circle" key="1" data-bs-toggle="tooltip" data-bs-original-title="D K">
                                                <span className="symbol-label bg-primary text-inverse-primary">DK</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="right-sidebar-footer border-top p-5">
                                        <ul className=" nav  ">
                                            <li className="nav-item curser_pointer">
                                                <span className="nav-link justify-content-center text-gray-400 " onClick={() => this.props.AddRenameContent(true)}>
                                                    <i className="icon-edit"></i>Rename
                                                </span>
                                            </li>
                                            <li className="nav-item curser_pointer">
                                                <span className="nav-link justify-content-center text-gray-400">
                                                    <i className="icon-copy"></i>Make a copy
                                                </span>
                                            </li>
                                            <li className="nav-item curser_pointer">
                                                <span className="nav-link justify-content-center text-gray-400" onClick={() => this.props.LibraryRemoveFile(true)}>
                                                    <i className="icon-delete"></i> Delete
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default LibraryRightSideFilePreview;
