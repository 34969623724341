import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import * as userActions from "../../actions/index";

class logout extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        localStorage.removeItem("bidhq_authuser");
        localStorage.removeItem("bidhq_authtoken");
        this.setState({ loader: true });
        this.props.onLogout();
        this.setState({ loader: false, isAuthenticated: true });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Redirect to="/signin" /> : null}
                loading ...
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => userActions.logout(),
    };
};
export default connect(null, mapDispatchToProps)(logout);