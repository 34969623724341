import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import FroalaEditor from "react-froala-wysiwyg";

const WysiwygEditorComment = (props) => {
    return (
        <FroalaEditor
            tag="textarea"
            config={{
                placeholderText: props.placeholder ? props.placeholder : "Something write",
            }}
            model={props.value}
            onModelChange={(event) => props.onChange(event)}
        />
    );
};

export default WysiwygEditorComment;