import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import App from "./App";
import AuthReducer from "./reducers/Auth";
import SettingsReducer from "./reducers/Settings";
import BidReducer from "./reducers/Bid";
import ClientReducer from "./reducers/Client";
import LibraryReducer from "./reducers/Library";

import * as userActions from "./actions/index";
import * as serviceWorker from "./serviceWorker";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    Auth: AuthReducer,
    Settings: SettingsReducer,
    Bid: BidReducer,
    Client: ClientReducer,
    Library: LibraryReducer,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));
store.dispatch(userActions.CheckPermissions());

const routing = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(routing, document.getElementById("root"));
serviceWorker.unregister();