import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** UpdatePersonalAvatar Action ***/
export const UpdatePersonalAvatarStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdatePersonalAvatar_START,
    };
};
export const UpdatePersonalAvatarSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdatePersonalAvatar_SUCCESS,
    };
};
export const UpdatePersonalAvatarFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdatePersonalAvatar_FAIL,
    };
};
export const UpdatePersonalAvatar = (avatar) => {
    return (dispatch) => {
        dispatch(UpdatePersonalAvatarStart());

        let formdata = new FormData();
        formdata.append("avatar", avatar);

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdatePersonalAvatar`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdatePersonalAvatarSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdatePersonalAvatarFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdatePersonal Action ***/
export const UpdatePersonalStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdatePersonal_START,
    };
};
export const UpdatePersonalSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdatePersonal_SUCCESS,
    };
};
export const UpdatePersonalFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdatePersonal_FAIL,
    };
};
export const UpdatePersonal = (form_data) => {
    return (dispatch) => {
        dispatch(UpdatePersonalStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdatePersonal`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdatePersonalSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdatePersonalFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveProfileImage Action ***/
export const RemoveProfileImageStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.RemoveProfileImage_START,
    };
};
export const RemoveProfileImageSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveProfileImage_SUCCESS,
    };
};
export const RemoveProfileImageFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveProfileImage_FAIL,
    };
};
export const RemoveProfileImage = () => {
    return (dispatch) => {
        dispatch(RemoveProfileImageStart());

        return axios({
            method: "post",
            url: `${actionType.environment_url}/api/RemoveProfileImage`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(RemoveProfileImageSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveProfileImageFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdatePassword Action ***/
export const UpdatePasswordStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdatePassword_START,
    };
};
export const UpdatePasswordSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdatePassword_SUCCESS,
    };
};
export const UpdatePasswordFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdatePassword_FAIL,
    };
};
export const UpdatePassword = (form_data) => {
    return (dispatch) => {
        dispatch(UpdatePasswordStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdatePassword`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdatePasswordSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdatePasswordFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CompanyUpdateAvatar Action ***/
export const CompanyUpdateAvatarStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CompanyUpdateAvatar_START,
    };
};
export const CompanyUpdateAvatarSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CompanyUpdateAvatar_SUCCESS,
    };
};
export const CompanyUpdateAvatarFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CompanyUpdateAvatar_FAIL,
    };
};
export const CompanyUpdateAvatar = (avatar) => {
    return (dispatch) => {
        dispatch(CompanyUpdateAvatarStart());

        let formdata = new FormData();
        formdata.append("avatar", avatar);

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CompanyUpdateAvatar`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CompanyUpdateAvatarSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CompanyUpdateAvatarFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CompanyUpdate Action ***/
export const CompanyUpdateStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CompanyUpdate_START,
    };
};
export const CompanyUpdateSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CompanyUpdate_SUCCESS,
    };
};
export const CompanyUpdateFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CompanyUpdate_FAIL,
    };
};
export const CompanyUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(CompanyUpdateStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CompanyUpdate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CompanyUpdateSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CompanyUpdateFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveCompanyLogo Action ***/
export const RemoveCompanyLogoStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.RemoveCompanyLogo_START,
    };
};
export const RemoveCompanyLogoSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveCompanyLogo_SUCCESS,
    };
};
export const RemoveCompanyLogoFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveCompanyLogo_FAIL,
    };
};
export const RemoveCompanyLogo = () => {
    return (dispatch) => {
        dispatch(RemoveCompanyLogoStart());

        return axios({
            method: "post",
            url: `${actionType.environment_url}/api/RemoveCompanyLogo`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(RemoveCompanyLogoSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveCompanyLogoFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetInviteUsers Action ***/
export const GetInviteUsersStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetInviteUsers_START,
    };
};
export const GetInviteUsersSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetInviteUsers_SUCCESS,
    };
};
export const GetInviteUsersFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetInviteUsers_FAIL,
    };
};
export const GetInviteUsers = (search) => {
    return (dispatch) => {
        dispatch(GetInviteUsersStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetInviteUsers`,
            params: { search: search },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetInviteUsersSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetInviteUsersFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** InviteUser Action ***/
export const InviteUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.InviteUser_START,
    };
};
export const InviteUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.InviteUser_SUCCESS,
    };
};
export const InviteUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.InviteUser_FAIL,
    };
};
export const InviteUser = (form_data) => {
    return (dispatch) => {
        dispatch(InviteUserStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/InviteUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(InviteUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(InviteUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateInviteUser Action ***/
export const UpdateInviteUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateInviteUser_START,
    };
};
export const UpdateInviteUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateInviteUser_SUCCESS,
    };
};
export const UpdateInviteUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateInviteUser_FAIL,
    };
};
export const UpdateInviteUser = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateInviteUserStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateInviteUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateInviteUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateInviteUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** ReInviteUser Action ***/
export const ReInviteUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.ReInviteUser_START,
    };
};
export const ReInviteUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.ReInviteUser_SUCCESS,
    };
};
export const ReInviteUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.ReInviteUser_FAIL,
    };
};
export const ReInviteUser = (form_data) => {
    return (dispatch) => {
        dispatch(ReInviteUserStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/ReInviteUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(ReInviteUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ReInviteUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveInviteUser Action ***/
export const RemoveInviteUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.RemoveInviteUser_START,
    };
};
export const RemoveInviteUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveInviteUser_SUCCESS,
    };
};
export const RemoveInviteUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveInviteUser_FAIL,
    };
};
export const RemoveInviteUser = (form_data) => {
    return (dispatch) => {
        dispatch(RemoveInviteUserStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/RemoveInviteUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(RemoveInviteUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveInviteUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetCompanyUsers Action ***/
export const GetCompanyUsersStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetCompanyUsers_START,
    };
};
export const GetCompanyUsersSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetCompanyUsers_SUCCESS,
    };
};
export const GetCompanyUsersFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetCompanyUsers_FAIL,
    };
};
export const GetCompanyUsers = (search) => {
    return (dispatch) => {
        dispatch(GetCompanyUsersStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetCompanyUsers`,
            params: { search: search },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetCompanyUsersSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCompanyUsersFail("Something went wrong, Please try again."));
                return error;
            });
    };
};