import React from "react";
import Notifications from "react-notify-toast";

import Account from "../../components/Settings/Account.js";
import Company from "../../components/Settings/Company.js";
import Billing from "../../components/Settings/Billing.js";
import UsersList from "../../components/Settings/Users/UsersList";
import SettingsAccount from "../../components/Settings/SettingsAccount.js";
class Settings extends React.Component {
    render() {
        const renderview = () => {
            if (this.props.match.path === "/settings-account") {
                return <Account />;
            }
            if (this.props.match.path === "/settings-company") {
                return <Company />;
            }
            if (this.props.match.path === "/settings-billing") {
                return <Billing />;
            }
            if (this.props.match.path === "/settings-users") {
                return <UsersList />;
            }
			if (this.props.match.path === "/account-settings") {
                return <SettingsAccount />;
            }
        };
        return (
            <React.Fragment>
                <Notifications options={{zIndex: 99999}} />
                {renderview()}
            </React.Fragment>
        );
    }
}
export default Settings;