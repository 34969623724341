import React from "react";
import { Modal } from "react-bootstrap";
import Validations from "../../components/Utility/Validations";

class AddContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addcontent_form: {
                folder_name: {
                    label: "Folder this content will be save under",
                    type: "select",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                    options: [{ opk: "role_opk_0", name: "Folder name", value: "" }],
                },
                folder_section: {
                    label: "Give this section a unique name so your team can easily identify search",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
            },
            addfolder_modal: false,
        };
    }

    inputChangeHandler(event, identifier) {
        const update_addcontent_form = { ...this.state.addcontent_form };
        const form_element = { ...update_addcontent_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_addcontent_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_addcontent_form) {
            is_form_valid = update_addcontent_form[identifier].valid && is_form_valid;
        }

        this.setState({
            addcontent_form: update_addcontent_form,
            is_form_valid: is_form_valid,
        });
    }

    render() {
        const { addcontent_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="addcontent_modal"
                    show={this.props.addcontent_modal}
                    onHide={() => {
                        this.props.addcontent_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Create new content</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100">
                            <div className="row">
                                <div className="col-lg-12 fv-row mb-4">
                                    <label className="form-label fs-6">{addcontent_form.folder_name.label}</label>
                                    <select className="form-select form-select-solid" value={addcontent_form.folder_name.value} onChange={(event) => this.inputChangeHandler(event, "folder_name")}>
                                        {addcontent_form.folder_name.options.map((option) => (
                                            <option value={option.value} key={option.opk}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg-12 fv-row mb-4">
                                    <label className="form-label fs-6">{addcontent_form.folder_section.label}</label>
                                    <input
                                        type={addcontent_form.folder_section.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={addcontent_form.folder_section.placeholder}
                                        value={addcontent_form.folder_section.value ? addcontent_form.folder_section.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "folder_section")}
                                        onBlur={(event) => {
                                            addcontent_form.folder_section.onBlur_out = true;
                                            this.setState({
                                                addcontent_form: addcontent_form,
                                            });
                                        }}
                                    />
                                    {!addcontent_form.folder_section.valid && addcontent_form.folder_section.onBlur_out ? <div className="error field-error">{addcontent_form.folder_section.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="d-flex align-items-center my-2 justify-content-end">
                                <button type="submit" className="btn btn-danger rounded-50 w-200">
                                    Continue
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddContent;