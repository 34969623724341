import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** GetClients Action ***/
export const GetClientsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetClients_START,
    };
};
export const GetClientsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetClients_SUCCESS,
    };
};
export const GetClientsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetClients_FAIL,
    };
};
export const GetClients = (search) => {
    return (dispatch) => {
        dispatch(GetClientsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetClients`,
            // params: { search: search },
            // paramsSerializer: (params) => {
            // return qs.stringify(params);
            // },
        })
            .then(function (response) {
                dispatch(GetClientsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetClientsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateClient Action ***/
export const CreateClientStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CreateClient_START,
    };
};
export const CreateClientSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CreateClient_SUCCESS,
    };
};
export const CreateClientFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CreateClient_FAIL,
    };
};
export const CreateClient = (form_data) => {
    return (dispatch) => {
        dispatch(CreateClientStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CreateClient`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateClientSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateClientFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteClient Action ***/
export const DeleteClientStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.DeleteClient_START,
    };
};
export const DeleteClientSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DeleteClient_SUCCESS,
    };
};
export const DeleteClientFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DeleteClient_FAIL,
    };
};
export const DeleteClient = (form_data) => {
    return (dispatch) => {
        dispatch(DeleteClientStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/DeleteClient`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(DeleteClientSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteClientFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetClientById Action ***/
export const GetClientByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetClientById_START,
    };
};
export const GetClientByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetClientById_SUCCESS,
    };
};
export const GetClientByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetClientById_FAIL,
    };
};
export const GetClientById = (client_id) => {
    return (dispatch) => {
        dispatch(GetClientByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetClientById`,
            params: {
                client_id: client_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetClientByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetClientByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateClient Action ***/
export const UpdateClientStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateClient_START,
    };
};
export const UpdateClientSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateClient_SUCCESS,
    };
};
export const UpdateClientFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateClient_FAIL,
    };
};
export const UpdateClient = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateClientStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateClient`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateClientSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateClientFail("Something went wrong, Please try again."));
                return error;
            });
    };
};