import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as actions from "./actions";

const ProtectedRouters = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (actions.isAuthorize()) {
                    const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                    if (user_info.company === null) {
                        return <Redirect to="/signup-company" />;
                    }
                    return <Component {...props} />;
                } else {
                    return <Redirect to="/signin" />;
                }
            }}
        />
    );
};
export default ProtectedRouters;