import React from "react";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Link, Redirect } from "react-router-dom";

import AddUsers from "./AddUsers";
import UserDetails from "./UserDetails";
import EditInviteUser from "./EditInviteUser";
import RemoveInviteUser from "./RemoveInviteUser";

import * as userActions from "../../../actions/index";
import Loader from "../../../components/Utility/Loader";
import LoginFooter from "../../../components/LoginFooter";
import LoginHeader from "../../../components/LoginHeader";
import LoginSidebar from "../../../components/LoginSidebar";
import * as actionType from "../../../constants/actionTypes";

class UsersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,

            activePage: 1,
            result_per_page: 5,
            redirect_url: "",
            isAuthenticated: false,

            invited_users: [],
            user_roles: null,

            edit_invited_user: null,
            edit_invited_modal: false,

            remove_invited_user: null,
            remove_invited_modal: false,
        };
    }

    componentDidMount() {
        userActions.GetRoles().then((response) => {
            if (response.success === true) {
                this.setState({ user_roles: response.data });
            } else {
                notify.show(response.message, "error");
            }
        });

        this.props.onGetInviteUsers().then((response) => {
            if (response.success === true) {
                this.setState({
                    invited_users: response.data,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    InviteUserSubmit(form_data) {
        this.props.onInviteUser(form_data).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                window.location.reload();
                this.AddUsersSuccessEvent.AddUsersReload();
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    EditInviteUser(invited_user) {
        this.setState({
            edit_invited_modal: true,
            edit_invited_user: invited_user,
        });
    }

    ConfirmEditInviteUser(form_data) {
        this.props.onUpdateInviteUser(form_data).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                this.setState({ edit_invited_modal: false, edit_invited_user: null });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    ReInviteUser(invited_user) {
        const form_data = {};
        form_data["site_url"] = actionType.siteurl;
        form_data["invite_id"] = invited_user.invite_id;
        this.props.onReInviteUser(form_data).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    RemoveInviteUser(invited_user) {
        this.setState({
            edit_invited_modal: false,
            edit_invited_user: null,
            remove_invited_modal: true,
            remove_invited_user: invited_user,
        });
    }

    ConfirmRemoveInviteUser(form_data) {
        this.props.onRemoveInviteUser(form_data).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                this.setState({ remove_invited_modal: false, remove_invited_user: null });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    render() {
        const { activePage, result_per_page } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.props.isAuthenticated === false ? <Redirect to="/logout" /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                {this.state.edit_invited_modal && (
                    <EditInviteUser
                        user_roles={this.state.user_roles}
                        invited_users={this.state.invited_users}
                        edit_invited_user={this.state.edit_invited_user}
                        edit_invited_modal={this.state.edit_invited_modal}
                        ReInviteUser={(event) => this.ReInviteUser(event)}
                        ConfirmEditInviteUser={(event) => this.ConfirmEditInviteUser(event)}
                        RemoveInviteUser={(event) => this.RemoveInviteUser(event)}
                        edit_invited_modal_action={(modal_action) => {
                            this.setState({
                                edit_invited_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.remove_invited_modal && (
                    <RemoveInviteUser
                        invited_users={this.state.invited_users}
                        remove_invited_user={this.state.remove_invited_user}
                        remove_invited_modal={this.state.remove_invited_modal}
                        ConfirmRemoveInviteUser={(event) => this.ConfirmRemoveInviteUser(event)}
                        remove_invited_modal_action={(modal_action) => {
                            this.setState({
                                remove_invited_modal: modal_action,
                            });
                        }}
                    />
                )}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Settings" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/settings-account" className="nav-link text-active-primary me-6">
                                                        Account settings
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-company" className="nav-link text-active-primary me-6">
                                                        Company details
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-billing" className="nav-link text-active-primary me-6">
                                                        Billing
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-users" className="nav-link text-active-primary me-6 active">
                                                        Users
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card mb-5 mb-xl-8">
                                                    <div className="card-header border-0 pt-5 mb-10">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-2x mb-1">All users</span>{" "}
                                                            <span className="text-muted mt-1 fw-normal fs-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Placerat mauris tristiq.</span>
                                                        </h3>
                                                        <div className="card-toolbar">
                                                            <AddUsers user_roles={this.state.user_roles} ref={(ref) => (this.AddUsersSuccessEvent = ref)} InviteUserSubmit={(event) => this.InviteUserSubmit(event)} />
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-0">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-bordered table-row-gray-300 align-middle gs-0 gy-4">
                                                                <thead>
                                                                    <tr className="fw-bolder text-muted">
                                                                        <th>Name</th>
                                                                        <th className="min-w-150px">Role</th>
                                                                        <th className="min-w-200px">Last active</th>
                                                                        <th className="min-w-150px">Access</th>
                                                                        <th className="min-w-150px">Status</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.invited_users !== null
                                                                        ? this.state.invited_users.map((invited_user, indx) => (
                                                                              <UserDetails
                                                                                  key={invited_user.invite_id}
                                                                                  invited_user={invited_user}
                                                                                  user_roles={this.state.user_roles}
                                                                                  sn={++indx + (activePage - 1) * result_per_page}
                                                                                  EditInviteUser={(event) => this.EditInviteUser(event)}
                                                                                  RemoveInviteUser={(event) => this.RemoveInviteUser(event)}
                                                                              />
                                                                          ))
                                                                        : null}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetInviteUsers: userActions.GetInviteUsers,
    onInviteUser: userActions.InviteUser,
    onReInviteUser: userActions.ReInviteUser,
    onUpdateInviteUser: userActions.UpdateInviteUser,
    onRemoveInviteUser: userActions.RemoveInviteUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersList);