import React from "react";

import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

const TaskList = (props) => {
    return (
        <React.Fragment>
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <LoginHeader page_title="My Task" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card mb-5 mb-xl-8 bg-transparent">
                                                <div className="card-header border-0 p-0 mb-10">
                                                    <h3 className="card-title align-items-start flex-column">
                                                        <span className="card-label fw-bolder fs-2x mb-1">All Task</span>{" "}
                                                        <span className="mt-1 fw-normal text-dark-muted fs-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Placerat mauris tristiq.</span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default TaskList;