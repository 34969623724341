export const SignIn_START = "SignIn_START";
export const SignIn_SUCCESS = "SignIn_SUCCESS";
export const SignIn_FAIL = "SignIn_FAIL";

export const SignInVerify_START = "SignInVerify_START";
export const SignInVerify_SUCCESS = "SignInVerify_SUCCESS";
export const SignInVerify_FAIL = "SignInVerify_FAIL";

export const ResendToken_START = "ResendToken_START";
export const ResendToken_SUCCESS = "ResendToken_SUCCESS";
export const ResendToken_FAIL = "ResendToken_FAIL";

export const getAuthUser_START = "getAuthUser_START";
export const getAuthUser_SUCCESS = "getAuthUser_SUCCESS";
export const getAuthUser_FAIL = "getAuthUser_FAIL";

export const SignUp_START = "SignUp_START";
export const SignUp_SUCCESS = "SignUp_SUCCESS";
export const SignUp_FAIL = "SignUp_FAIL";

export const SignUpCompany_START = "SignUpCompany_START";
export const SignUpCompany_SUCCESS = "SignUpCompany_SUCCESS";
export const SignUpCompany_FAIL = "SignUpCompany_FAIL";

export const SocialSignUp_START = "SocialSignUp_START";
export const SocialSignUp_SUCCESS = "SocialSignUp_SUCCESS";
export const SocialSignUp_FAIL = "SocialSignUp_FAIL";

export const SocialSignIn_START = "SocialSignIn_START";
export const SocialSignIn_SUCCESS = "SocialSignIn_SUCCESS";
export const SocialSignIn_FAIL = "SocialSignIn_FAIL";

export const ForgotPassword_START = "ForgotPassword_START";
export const ForgotPassword_SUCCESS = "ForgotPassword_SUCCESS";
export const ForgotPassword_FAIL = "ForgotPassword_FAIL";

export const ResetPassword_START = "ResetPassword_START";
export const ResetPassword_SUCCESS = "ResetPassword_SUCCESS";
export const ResetPassword_FAIL = "ResetPassword_FAIL";

export const ResentForgotOTP_START = "ResentForgotOTP_START";
export const ResentForgotOTP_SUCCESS = "ResentForgotOTP_SUCCESS";
export const ResentForgotOTP_FAIL = "ResentForgotOTP_FAIL";

export const siteurl = process.env.REACT_APP_API_URL;
export const environment_url = process.env.REACT_APP_ENVIRONMENT_URL;
export const google_client_secret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
export const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
