import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

class Billing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect_url: "",
            isAuthenticated: false,
        };
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title = "Settings"/>
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/settings-account" className="nav-link text-active-primary me-6">
                                                        Account settings
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-company" className="nav-link text-active-primary me-6">
                                                        Company details
                                                    </Link>
                                                </li>
												<li className="nav-item">
                                                    <Link to="/account-settings" className="nav-link text-active-primary me-6">
                                                        Account
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-billing" className="nav-link text-active-primary me-6 active">
                                                        Billing
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/settings-users" className="nav-link text-active-primary me-6">
                                                        Users
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10">
                                                <div className="card mb-5 mb-xl-10">
                                                    <div className="card-header border-0 pt-5 mb-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label text-dark fw-bolder fs-2x mb-1">Subscription</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body pt-0 pb-5">
                                                        <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-5 plan-pro">
                                                            <div className="me-5">
                                                                <div className="symbol-100px symbol-fixed position-relative">
                                                                    <i className="text-dark-blue icon-subscription fs-2tx"></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div className="d-flex justify-content-between align-items-start flex-wrap">
                                                                    <div className="d-flex flex-column">
                                                                        <h4 className="text-dark-blue mb-1 fs-7">Pro Plan</h4>
                                                                        <p className="text-gray-new mb-0 fs-9">$199 AUD + $20 per user</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="edit-btn">
                                                                <Link to="#" className="btn btn-sm btn-secondary rounded-50">
                                                                    Edit
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-5 plan-pro">
                                                            <div className="me-5">
                                                                <div className="symbol-100px symbol-fixed position-relative">
                                                                    <i className="icon-subscription text-pink fs-2tx"></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div className="d-flex justify-content-between align-items-start flex-wrap">
                                                                    <div className="d-flex flex-column">
                                                                        <h4 className="text-pink mb-1 fs-7">Basic Plan</h4>
                                                                        <p className="text-gray-new mb-0 fs-9">$120 AUD + $10 per user</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="edit-btn">
                                                                <Link to="#" className="btn btn-sm btn-secondary rounded-50">
                                                                    Edit
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card mb-5 mb-xl-10">
                                                    <div className="card-header border-0 pt-5 mb-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label text-dark fw-bolder fs-2x mb-1">Payment information</span> <span className="text-gray-new mt-1 fw-normal fs-7">Manage the payment methods that are connected to your BidHQ account.</span>
                                                        </h3>
                                                        <div className="card-toolbar add-code-btn">
                                                            <button type="button" className="btn btn-sm btn-dark p-0 fs-7">
                                                                Add card{" "}
                                                                <span>
                                                                    {" "}
                                                                    <i className="icon-add"></i>{" "}
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-0 pb-5">
                                                      <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-5 plan-pro">
                                                            <div className="me-5">
                                                                <div className="symbol-100px symbol-fixed position-relative">
                                                           <img src="assets/images/icons/master-card.svg" alt="card" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div className="d-flex justify-content-between align-items-start flex-wrap">
                                                                    <div className="d-flex flex-column">
                                                                        <h4 className="text-gray-new mb-1 fs-7">Credit or debit card</h4>
                                                                        <p className="text-gray-new mb-0 fs-6">•••• •••• •••• 4562 (Expires 05/23)</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           <div className="edit-btn d-flex align-items-center">
                                                                <h5 className="text-dark-blue-light mb-0 fs-7">Primary</h5>
                                                               <span className="btn-icon"><i className="icon-delete"></i></span>
                                                            </div>
                                                        </div>
														
													
														
														<div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-5 plan-pro">
                                                            <div className="me-5">
                                                                <div className="symbol-100px symbol-fixed position-relative">
                                                           <img src="assets/images/icons/visa-card.svg" alt="card" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div className="d-flex justify-content-between align-items-start flex-wrap">
                                                                    <div className="d-flex flex-column">
                                                                        <h4 className="text-gray-new mb-1 fs-7">Credit or debit card</h4>
                                                                        <p className="text-gray-new mb-0 fs-6">•••• •••• •••• 4562 (Expires 05/23)</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           <div className="edit-btn d-flex align-items-center">
                                                                <h5 className="text-gray-new mb-0 fs-7 fw-normal">Make primary</h5>
                                                                <span className="btn-icon"><i className="icon-delete"></i></span>
                                                            </div>
                                                        </div>
														
														
														
                                                    </div>
                                                </div>
												
												
												
												
												
												 <div className="card mb-5 mb-xl-10">
                                                    <div className="card-header border-0 pt-5 mb-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label text-dark fw-bolder fs-2x mb-1">Payment history</span> 
                                                        </h3>
                                                        <div className="card-toolbar add-code-btn">
                                                            <button type="button" className="btn btn-sm btn-dark p-0 fs-7">
                                                                Download all invoices{" "}
                                                                <span>
                                                                    {" "}
                                                                    <i className="icon-download"></i>{" "}
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-0 pb-5 table-responsive">
                                                      
														
													<table className="table billing-table">
												<thead>
													<tr>
													  <th className="text-gray-new">Date</th>
													  <th className="text-gray-new">Amount</th>
													  <th className="text-gray-new">Description</th>
													  <th className="text-gray-new">Card</th>
													  <th className="text-gray-new">Status</th>
													  <th className="text-gray-new">Invoice</th>
													</tr>
												  </thead>
												  <tbody>
													<tr>
													  <td>30/08/2021</td>
													  <td>$99.00</td>
													  <td>Monthly subscription</td>
													  <td>8383</td>
													  <td>Pending</td>
													  <td className="text-dark-blue-light">123456789 <i className="color1 icon-download"></i></td>
													</tr>
													<tr>
													  <td>30/08/2021</td>
													  <td>$99.00</td>
													  <td>Monthly subscription</td>
													  <td>8383</td>
													  <td className="success-txt">Success</td>
													  <td className="text-dark-blue-light">123456789 <i className="color1 icon-download"></i></td>
													</tr>
													<tr>
													  <td>30/08/2021</td>
													  <td >$99.00</td>
													  <td>Monthly subscription</td>
													  <td>8383</td>
													  <td className="success-txt">Success</td>
													  <td className="text-dark-blue-light">123456789 <i className="color1 icon-download"></i></td>
													</tr>
													<tr>
													  <td>30/08/2021</td>
													  <td >$99.00</td>
													  <td>Monthly subscription</td>
													  <td>8383</td>
													  <td className="success-txt">Success</td>
													  <td className="text-dark-blue-light">123456789 <i className="color1 icon-download"></i></td>
													</tr>
												  </tbody>
												</table>
													
													
													
													
														
														
														
                                                    </div>
                                                </div>
												
												
												
												
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    // onGetBoard: userActions.GetBoard,
};
export default connect(mapStateToProps, mapDispatchToProps)(Billing);